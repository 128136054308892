import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { Calendra } from "../elements/DatePicker";
import Select from 'react-select';
function PersonalProfile({ userData, changeHandler, countriesArr }) {
    return (<>
        <ul className="row info_box">
            <li>
                <small htmlFor="firstName" className="required">First Name</small>
                <input type="text" name="firstName"
                    value={userData.firstName}
                    onChange={(e) => changeHandler('firstName', e.target.value)}
                    className="form-control"
                    id="firstName" />
            </li>
            <li>
                <small htmlFor="lastName">Last Name</small>
                <input type="text" name="lastName"
                    value={userData.lastName}
                    onChange={(e) => changeHandler('lastName', e.target.value)}
                    className="form-control" id="lastName" />
            </li>
            <li>
                <small htmlFor="email" className="required">Email</small>
                <input type="text" name="email" value={userData.email}
                    onChange={(e) => changeHandler('email', e.target.value)}
                    className="form-control" id="email" />
            </li>
            <li>
                <small htmlFor="mobile" className="required">Mobile</small>
                <div className="clearfix"></div>
                <IntlTelInput
                    value={userData.mobile}
                    containerClassName="intl-tel-input"
                    inputClassName="form-control number"
                    preferredCountries={[userData.iso2 || 'in']}
                    onSelectFlag={(num, country) => {
                        // console.log('onSelectFlag', country);
                    }}
                    onPhoneNumberChange={(status, value, countryData) => changeHandler('mobile', {
                        'mobile': value,
                        'dialCode': countryData.dialCode,
                        'iso2': countryData.iso2
                    })}
                />
            </li>
            <li>
                <small htmlFor="dob">DOB</small>
                <Calendra
                    className="form-control"
                    value={userData.dob ? new Date(userData.dob) : ""}
                    name="dob"
                    required="required"
                    onChange={(e) => changeHandler('dob', e)}
                />
            </li>
            <li className="form-group col-sm-8">
                <small htmlFor="address">Address</small>
                <input type="text" name="postalAddress"
                    value={userData.postalAddress}
                    onChange={(e) => changeHandler('postalAddress', e.target.value)}
                    className="form-control" id="postalAddress" />
            </li>
            <li>
                <small htmlFor="country">Country</small>
                <Select
                    name="ciso2"
                    value={countriesArr.filter(obj => userData['ciso2'] == (obj.iso_code_2))}
                    onChange={(e) => changeHandler('ciso2', e)}
                    options={countriesArr}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.iso_code_2}
                    isClearable
                />
            </li>
            <li>
                <small htmlFor="state">State</small>
                <input type="text" name="state"
                    value={userData.state}
                    onChange={(e) => changeHandler('state', e.target.value)}
                    className="form-control" id="state" />
            </li>
            <li>
                <small htmlFor="city">City</small>
                <input type="text" name="city"
                    value={userData.city}
                    onChange={(e) => changeHandler('city', e.target.value)}
                    className="form-control" id="city" />
            </li>
            <li>
                <small htmlFor="postcode">Postcode</small>
                <input type="text" name="postcode"
                    value={userData.postcode}
                    onChange={(e) => changeHandler('postcode', e.target.value)}
                    className="form-control" id="postcode" />
            </li>
        </ul>
        <div className="646464 mb-3">About<br />
            <small>
                <textarea className="bg-r form-control"
                    name="vendorDescription"
                    value={userData.vendorDescription}
                    onChange={(e) => changeHandler('vendorDescription', e.target.value)}
                >
                </textarea>
            </small>
        </div>
    </>
    )
}

export default PersonalProfile;