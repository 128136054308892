import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { productSearch } from "../../store/actions/allAction"

function HeaderSearch(props) {
    let { search: searchParams } = useLocation();
    const query = new URLSearchParams(searchParams);
    const paramField = query.get('search');
    const history = useHistory();
    const [search, setSearch] = useState(paramField || '');
    const disptch = useDispatch();
    const onSearch = (e) => {
        if (history) {
            let hPath = (history.location.pathname).split('/');
            if (hPath[1] == 'products', hPath[2] == 'list') {
                disptch(productSearch(search));
            }
            else {
                history.push('/products/list?search=' + search);
            }
        }
    }
    return (
        <div className="search-box">
            <select disabled>
                <option>{props.title}</option>
            </select>
            <div className="search-input">
                <input type="text"
                    value={search || ''}
                    onKeyPress={(e) => { if (e.key === "Enter") { onSearch(e) } }}
                    onInput={(e) => setSearch(e.target.value)}
                    placeholder="Search by keyword"
                />
                <button type="button" onClick={onSearch} className="search-btn"><img src={require("../../webroot/images/search.png").default} alt="Search" /></button>
            </div>
        </div>
    );
}

export default HeaderSearch;