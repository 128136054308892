import { toast } from "react-toastify";
export default {
    show(message, status = true) {
        if (status) {
            toast.success(message);
        }
        else {
            let messages = message.split(',');
            if (Array.isArray(messages)) {
                for (let ck = 0; ck < messages.length; ck++) {
                    let msg = messages[ck];
                    toast.error(msg);
                }
            }
        }
    }
}