import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import secureStorage from "../../config/encrypt";
import { API_URL } from "../../config/constant";
import { toast } from "react-toastify";
function Logout() {
    useEffect(() => {
        getUserLogout();
    }, []);
    let history = useHistory();
    const getUserLogout = () => {
        let user = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY);
        if (user) {
            secureStorage.removeItem(API_URL.COMMON_LOGIN_STORE_KEY);
            toast.success("Logout successfully")
        }
        if (history) history.push('/');
    }
    return (<></>)
}
export default Logout;