import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Loader from "../common/Loader";
import InnerHtml from "../elements/InnerHtml";
import { useLocation } from 'react-router-dom';
import { Mobile } from "../elements/Mobile";
const contactForm = {
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    dialCode: "",
    mobile: "",
    message: ""
}
function ContactUs() {
    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState("");
    const [contactus, setContactus] = useState(contactForm);
    const location = useLocation();
    let currentPath = (location.pathname).replace('/', '')
    useEffect(() => {
        getContent();
    }, []);
    const getContent = async () => {
        UserServices.contentDetail({ type: 'contactus' }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setContent(data);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsLoading(false);
        })
    }
    const initCap = (string) => {
        string = string.split('-').join(" ")
        string = string.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
        return string;
    }
    const changeHandler = (e, name) => {
        if (name == 'mobile') {
            setContactus((pre) => ({
                ...pre,
                mobile: e.mobile,
                dialCode: e.dialCode
            }))
        }
        else {
            setContactus((pre) => ({
                ...pre,
                [e.target.name]: e.target.value
            }))
        }
    }
    const isValid = () => {
        let valid = true;
        if (contactus.companyName == '') {
            valid = false;
        }
        if (contactus.firstName == '') {
            valid = false;
        }
        if (contactus.email == '') {
            valid = false;
        }
        if (contactus.message == '') {
            valid = false;
        }
        if (contactus.mobile == '') {
            valid = false;
        }
        return valid;
    }
    const submitContactus = (e) => {
        setIsLoading(true);
        if (isValid()) {
            UserServices.contactADD(contactus).then((responce) => {
                let { data, message, statusCode } = responce;
                if (statusCode == 200) {
                    toast.success(message);
                    setContactus(contactForm);
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            }).catch((error) => {
                toast.error(error);
                setIsLoading(false);
            })
        }
    }
    return (
        <>
            <Header metaTitle={content.metaTitle} metaKewords={content.metaKewords} metaDescription={content.metaDescription} />
            <Loader isLoading={isLoading} />
            <div className="in_banenr" >
                <img src={content.banner ? content.Image : require("../../webroot/images/about-banner.png").default} className="w-100" alt="Banner" />
                <div className="banner_content">
                    <div className="container">
                        <h1>{initCap(currentPath)}</h1>
                        <p>{content.title}</p>
                    </div>
                </div>
            </div>
            <div className="container">
                <InnerHtml rawHTML={content.description} />
                <div className="row">
                    <div className="col-lg-6">
                        <form className="join_us_right">
                            <div className="form-group">
                                <label className="required">Company Name</label>
                                <input type="text"
                                    name="companyName"
                                    value={contactus.companyName}
                                    onChange={changeHandler}
                                    placeholder="Enter company name"
                                    required
                                    className="form-control2" />
                            </div>
                            <div className="form-group">
                                <label className="required">First Name</label>
                                <input type="text" name="firstName"
                                    value={contactus.firstName}
                                    onChange={changeHandler}
                                    placeholder="Enter first name"
                                    required
                                    className="form-control2" />
                            </div>
                            <div className="form-group">
                                <label className="">Last Name</label>
                                <input type="text" name="lastName"
                                    value={contactus.lastName}
                                    placeholder="Enter last  name"
                                    onChange={changeHandler}
                                    required
                                    className="form-control2" />
                            </div>
                            <div className="form-group">
                                <label className="required">Email</label>
                                <input type="email" name="email"
                                    value={contactus.email}
                                    placeholder="Enter email address"
                                    onChange={changeHandler}
                                    required
                                    className="form-control2" />
                            </div>
                            <div className="form-group">
                                <label className="required">Mobile</label>
                                <Mobile
                                    value={contactus.mobile}
                                    onChange={changeHandler}
                                    name="mobile"
                                    preferredCountry={'in'}
                                />
                            </div>
                            <div className="form-group">
                                <label className="required">Message</label>
                                <textarea rows="4" name="message"
                                    placeholder="Enter your query"
                                    value={contactus.message}
                                    onChange={changeHandler}
                                    required
                                    className="form-control" ></textarea>
                            </div>
                            <button type="button" onClick={submitContactus} className="btn btn-primary w100">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default ContactUs;