import { Link } from "react-router-dom";
import { displayDate } from "../../utils"
function AboutSeller({ userProf, showAbout = false }) {
    return (
        <>
            {/* <h5 className="mt-3 mb-md-3">About The Seller</h5> */}
            <div className="row">
                <div className="col-lg-12">
                    <div className="about_seller mb-2">
                        <div className="seller_ime tc">
                            <img src={userProf && userProf.avatar ? userProf.avatar : require("../../webroot/images/seller.png").default} alt="seller" className="img" />
                            <Link to={"/seller-profile/" + userProf._id} className="co1 d_block mt-3">{userProf.firstName || ""} {userProf.lastName || ""}</Link>
                        </div>
                        <div className="text_seller pl-md-4">
                            <span className="d_block seller_name">{userProf.shop && userProf.shop.companyName || "NA"}</span>
                            <small className="d_block">{userProf.shop && userProf.shop.slogan || "NA"}</small>
                            <div className="star_revi ccc pt-2">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <span className="co1">{userProf.averageRating || 0}</span>({userProf.totalRating || 0} )
                            </div>
                        </div>
                        {showAbout ? <>
                            <div className="col-lg-2 col-md-4">
                                <span className="co1 d_block">From</span> {userProf.address ? userProf.address.country : "NA"}
                            </div>
                            <div className="col-lg-2 col-md-4">
                                <span className="co1 d_block">Member sice</span> {displayDate(userProf.createdAt)}
                            </div>
                            <div className="col-lg-2 col-md-4">
                                <span className="co1 d_block">Last Delivery</span> Just Now
                            </div></> : null
                        }
                    </div>
                </div>
            </div>
            {showAbout ? <>
                <p className="p-0 mt-md-4">HI,</p>
                <p className="p-0">{userProf.firstName || ""} {userProf.lastName || ""} here,</p>
                <p className="p-0">{userProf.vendorDescription || ""}</p>
            </> : null}
        </>
    )
}
export default AboutSeller;