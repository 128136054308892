import OwlCarousel from 'react-owl-carousel';
import Footer from "../common/Footer"
import Header from "../common/Header";
import Loader from "../common/Loader";
import ExclusiveFeaturesComponent from "./ExclusiveFeaturesComponent";
import HomeCarouselComponent from "./HomeCarouselComponent";
import NewsletterFormController from "../common/NewsletterFormController";
import { useState } from "react";
import { useEffect } from "react";
import { UserServices } from "../../services";
import { toast } from "react-toastify";
import { twodArr } from "../../utils/index"
import ProductCard from '../product/ProductCard';
import { Link, NavLink } from 'react-router-dom';
import ServiceCard from '../Service/ServiceCard';
import Banner from '../common/Banner';
import { options, wnoptions } from "../../config/constant";
import { ShimmerPostItem } from "react-shimmer-effects";
function HomeComponent() {
    const [products, setProducts] = useState([{}, {}, {}]);
    const [homeSlider, setHomeSlider] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [isProductLoading, setIsProductLoading] = useState(true);
    const [content, setContent] = useState("");
    const [pramotionBanner, setPramotionBanner] = useState({});
    const [isServiceLoading, setIsServiceLoading] = useState(true);
    const [serviceArr, setServiceArr] = useState([{}, {}, {}]);

    useEffect(async () => {
        await getHomeSilder();
        await getContent();
        await getProducts();
        await getService();
        await getAllPramotionBanner();
    }, []);
    const getProducts = async () => {
        setIsProductLoading(true);
        setProducts([{}, {}, {}])
        UserServices.feturesProductAllList({ limit: 10 }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setProducts((data.list));///twodArr
            }
            else {
                toast.error(message);
            }
            setIsProductLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsProductLoading(false);
        })
    }
    const getService = async () => {
        setIsServiceLoading(true);
        setServiceArr([{}, {}, {}])
        UserServices.serviceAllList({}).then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setServiceArr((data.list));
            }
            setIsServiceLoading(false)
        })
    }

    const getAllPramotionBanner = async () => {
        UserServices.pramotionalBanner({ displayAt: 'home' }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setPramotionBanner(data);
            }
            else {
                toast.error(message);
            }
        }).catch((error) => {
            toast.error(error);
        })
    }
    const getHomeSilder = async () => {
        UserServices.bannerList('home').then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setHomeSlider(data.banners);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsLoading(false);
        })
    }
    const getContent = async () => {
        UserServices.contentDetail({ type: 'home' }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setContent(data);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsLoading(false);
        })
    }
    return (
        <>
            <Header enableSearch={'Product'} metaTitle={content.metaTitle} metaKewords={content.metaKewords} metaDescription={content.metaDescription} />
            {/* <Loader isLoading={isLoading} /> */}
            <HomeCarouselComponent slider={homeSlider} isLoading={isLoading} />
            <div className="middle-section">
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-4 mb-3 mb-md-0">
                                <div className="card bg-light custom-card">
                                    <div className="card-left"><img src={require("../../webroot/images/shop.png").default} alt="Shop" /></div>
                                    <div className="card-right">
                                        <h2>Shop</h2>
                                        <p>Shop your favorites <br />products from ICT Kart </p>
                                    </div>
                                    <NavLink to={"/products"} className="shop-btn"><img src={require("../../webroot/images/right.png").default} alt="Arrow" /></NavLink>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 mb-3 mb-md-0">
                                <div className="card bg-light custom-card">
                                    <div className="card-left"><img src={require("../../webroot/images/services.png").default} alt="Shop" /></div>
                                    <div className="card-right">
                                        <h2>Services</h2>
                                        <p>A whole world of Professional <br />talent at your finger tips</p>
                                    </div>
                                    <NavLink to={"/services"} className="shop-btn"><img src={require("../../webroot/images/right.png").default} alt="Arrow" /></NavLink>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 mb-3 mb-md-0">
                                <div className="card bg-light custom-card">
                                    <div className="card-left"><img src={require("../../webroot/images/solutions.png").default} alt="Shop" /></div>
                                    <div className="card-right">
                                        <h2>Solutions</h2>
                                        <p>Get work done in over 1800 <br />different categories</p>
                                    </div>
                                    <NavLink to={"/solutions"} className="shop-btn"><img src={require("../../webroot/images/right.png").default} alt="Arrow" /></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Second */}
                {pramotionBanner && pramotionBanner.first && pramotionBanner.first.image ?
                    <section className="pt-4 pb-65">
                        <Banner img={pramotionBanner.first.image} />
                    </section> : null
                }
                {/* SHOP */}
                <section className=" pb-50">
                    <div className="container">
                        <div className="heading-title ">
                            <h2 className="h_ttu">Shop</h2>
                            <p>Shop your favorites products from ICT Kart</p>
                        </div>
                        <div className="row align-items-end pt-35">
                            <div className="col-lg-6">
                                <img src={require("../../webroot/images/shop_ima.png").default} alt="Shop" />
                            </div>
                            <div className="col-lg-6">
                                <div className="pt-4 pt-lg-0 shop-heading pb-2">
                                    <h3>Featured Products</h3>
                                    <Link to={"/products/list"} className="btn-link">View All</Link>
                                </div>
                                <OwlCarousel className="owl-carousel viewnav items-carousel pt-xl-3" {...options}>
                                    {
                                        products && products.map((mObj, index) => {
                                            return (
                                                <div key={'parent' + index} className="item">{
                                                    ('_id' in mObj) ? <ProductCard productData={mObj} key={'child' + index} /> :
                                                        <ShimmerPostItem card title cta imageHeight={80} contentCenter />
                                                }</div>
                                            )
                                        })
                                    }
                                </OwlCarousel>
                                <OwlCarousel className="owl-carousel viewnav items-carousel pt-xl-3" {...wnoptions}>
                                    {
                                        products && products.reverse().map((mObj, index) => {
                                            return (
                                                <div key={'parent' + index} className="item">{
                                                    ('_id' in mObj) ? <ProductCard productData={mObj} key={'child' + index} /> :
                                                        <ShimmerPostItem card title cta imageHeight={80} contentCenter />
                                                }</div>
                                            )
                                        })
                                    }
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Banner 2 */}
                {pramotionBanner && pramotionBanner.second && pramotionBanner.second.image ?
                    <section className="pt-4 pb-65">
                        <Banner img={pramotionBanner.second.image} />
                    </section> : null
                }
                {/* Services */}
                <section className="pt-65 pb-50">
                    <div className="container">
                        <div className="heading-title">
                            <h2 className="h_ttu">Services</h2>
                            <p>A whole world of Professional talent at your finger tips </p>
                        </div>
                        <div className="row align-items-end pt-35">
                            <div className="col-lg-6">
                                <div className="shop-heading pb-2">
                                    <h3>Top Professional Service</h3>
                                    <Link to={'/services'} className="btn-link">View All</Link>
                                </div>
                                <OwlCarousel className="owl-carousel viewnav items-carousel pt-xl-3" {...options}>
                                    {
                                        serviceArr && serviceArr.map((mObj, index) => {
                                            return (
                                                <div key={'parents' + index} className="">{
                                                    ('_id' in mObj) ? <ServiceCard service={mObj} key={'ck_' + index} /> :
                                                        <ShimmerPostItem card title cta imageHeight={80} contentCenter />
                                                }</div>
                                            )
                                        })
                                    }
                                </OwlCarousel>
                                <OwlCarousel className="owl-carousel viewnav items-carousel pt-xl-3" {...wnoptions}>
                                    {
                                        serviceArr && serviceArr.map((mObj, index) => {
                                            return (
                                                <div key={'parents' + index} className="">{
                                                    ('_id' in mObj) ? <ServiceCard service={mObj} key={'ck_' + index} /> :
                                                        <ShimmerPostItem card title cta imageHeight={80} contentCenter />
                                                }</div>
                                            )
                                        })
                                    }
                                </OwlCarousel>
                            </div>
                            <div className="col-lg-6">
                                <img src={require("../../webroot/images/service_ima.png").default} alt="Services" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* Banner3 */}
                {pramotionBanner && pramotionBanner.third && pramotionBanner.third.image ?
                    <section className="pt-4 pb-65">
                        <Banner img={pramotionBanner.third.image} />
                    </section> : null
                }
                {/* Solutions */}
                <section className="pt-65 pb-50">
                    <div className="container">
                        <div className="heading-title">
                            <h2 className="h_ttu">Solutions</h2>
                            <p>Get work done in over 1800 different categories</p>
                        </div>
                        <div className="row align-items-end pt-2 pt-md-4">
                            <div className="col-lg-6">
                                <img src={require("../../webroot/images/solution_ima.png").default} alt="Solutions" />
                            </div>
                            <div className="col-lg-6">
                                <div className="shop-heading pb-4">
                                    <h3>Need something done?</h3>
                                </div>
                                <div className="pt-3 row">
                                    <div className="col-md-6">
                                        <div className="solution-card">
                                            <div className="sluton-header">
                                                <span><img src={require("../../webroot/images/post_job.png").default} alt="Post a job" /></span>
                                                <h4>Post a job</h4>
                                            </div>
                                            <p>It’s free and easy to post a job. Simply fill in a title, description and budget and competitive
                                                bids come within minutes.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="solution-card">
                                            <div className="sluton-header">
                                                <span><img src={require("../../webroot/images/providers.png").default} alt="Choose Providres" /></span>
                                                <h4>Choose Providres</h4>
                                            </div>
                                            <p>No job is too big or too small. We've got experts for jobs of any size or budget, across 1800+
                                                skills.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="solution-card">
                                            <div className="sluton-header">
                                                <span><img src={require("../../webroot/images/safe-pay.png").default} alt="Pay safely" /></span>
                                                <h4>Pay safely</h4>
                                            </div>
                                            <p>Only pay for work when it has been completed and you're 100% satisfied with the quality using our
                                                milestone payment system</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="solution-card">
                                            <div className="sluton-header">
                                                <span><img src={require("../../webroot/images/conversation.png").default} alt="We’re here to help" /></span>
                                                <h4>We’re here to help</h4>
                                            </div>
                                            <p>It’s free and easy to post a job. Simply fill in a title, description and budget and competitive
                                                bids come within minutes.</p>
                                        </div>
                                    </div>


                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                        <a className="post_a_job" href="#">
                                            <h5 className="pb-0">Post a Job and Hire a Pro</h5>
                                            <span className="m-0 color_man p-0">Talent Market Place</span>
                                        </a>
                                    </div>
                                    <div className="col-sm-6">
                                        <a className="post_a_job2" href="#">
                                            <h5 className="pb-0">Lets us Help you find<br />the right talent</h5>
                                            <span className="m-0  p-0">Talent Scout</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Banner 4 */}
                {pramotionBanner && pramotionBanner.fourth && pramotionBanner.fourth.image ?
                    <section className="">
                        <Banner img={pramotionBanner.fourth.image} />
                    </section> : null
                }
            </div>
            <ExclusiveFeaturesComponent />
            <NewsletterFormController />
            <Footer />
        </>
    );
}

export default HomeComponent;