import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { UserServices } from "../../services";
import Loader from "../common/Loader";
import secureStorage from "../../config/encrypt";
import { API_URL } from "../../config/constant";
import { displayDate, userPermission, verifyVendorAccount } from "../../utils"
const filePATH = API_URL.COMMON_GATEWAY_API + 'invoice/download/';
function VenderMyProduct({ userId, isSmall = false }) {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        let user = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY);
        user = (user && "user" in user) ? user.user : user;
        if (user) {
            getUserProfile(user.vendor);
        }
    }, [])
    const getUserProfile = async (userId) => {
        UserServices.myProduct({ userId }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setProducts(data.list);
            }
            else {
                // toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            // toast.error(error);
            setIsLoading(false);
        })
    }
    return (
        <div className={isSmall ? "col-lg-12" : "col-lg-12"}>
            <Loader isLoading={isLoading} />
            <div className="d_boxs pb-1 mb-2 mb-md-4">
                <div className="row">
                    <div className=" col-md-5 ">
                        <h5 className="box_hadding">My Added Products </h5>
                    </div>
                    <div className=" col-md-7 ">
                        <div className="d_flex search_input mb-3 mb-md-4 mla">
                            {userPermission('product', 'add') || userPermission('product', 'edit') ?
                                (verifyVendorAccount() ? <Link to={'/my/manage-product'} className="btn btn-primary btn-small m-auto mr-3">Add New Items</Link> : null) : null
                            }
                            <span className="pt-2 w90"> Sort by: </span>
                            <select className=" form-control" type="text">
                                <option>Most Recent</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="table_responsive_scrool">
                    <table className="table_vender text-center">
                        <thead>
                            <tr>
                                <th>Sr.No.</th>
                                <th>Product</th>
                                <th>Category</th>
                                <th>Brand</th>
                                <th>Quantity</th>
                                <th>Release Date</th>
                                <th>Expiry Date</th>
                                <th>Original Price</th>
                                <th>Selling Price</th>
                                <th>Created On</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isLoading && products.map((item, indx) => {
                                return (
                                    <tr>
                                        <td>{indx + 1}</td>
                                        <td>
                                            <div className="prof-container">
                                                <img className="prof-img" src={item.thumbnailImage} />
                                                <div className="prof-content">
                                                    <h5 className="title">{item.title}</h5>
                                                    <a className="sub-title">{item.modelNumber}</a>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{item.categories ? item.categories.title : ""}</td>
                                        <td>{item.brand ? item.brand.title : ""}</td>
                                        <td>{item.totalUnits}</td>
                                        <td>{displayDate(item.releaseDate)}</td>
                                        <td>{displayDate(item.expiryDate)}</td>
                                        <td>{item.currency + ' ' + item.originalPrice}</td>
                                        <td>{item.currency + ' ' + item.sellingPrice}</td>
                                        <td>{displayDate(item.createdAt)}</td>
                                        <td>
                                            <Link to={"/my/update-product/" + item._id}>View</Link> 
                                            <a className="btn btn-primary btn-small" href={filePATH + item._id} download><i class="mdi mdi-cloud-download"></i>Invoice</a>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* {
                        !isLoading && products.map((prod, index) => {
                            return (
                                <div key={index} className="col-lg-3 col-md-3 col-sm-2">
                                    <div className="shop-item mb-3">
                                        <img className="mx-auto item-img" src={prod.thumbnail} alt="Star" />
                                        <Link to={`/my/update-product/${prod._id}`} className="edit-cart"><img src={require("../../webroot/img/edit.png").default} alt="Cart" /></Link>
                                        <div className="item-body">
                                            <h4><Link to={`/product/${(prod.brandData ? prod.brandData.slug : 'ict')}/${prod._id}`}>{prod.title}</Link></h4>
                                            <div className="shop-footer">
                                                <span className="price">{prod.currency} <strong>{prod.originalPrice}</strong><del>{prod.currency} {prod.sellingPrice}</del></span>
                                                <span className="rating-box"><span><img src={require("../../webroot/images/star.png").default} alt="Star" /></span> {prod.ratings}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    } */}
        </div>
    )
}
export default VenderMyProduct;