function TopRiben({ profile }) {
    return (
        <>
            <div className="dashbord_welcome">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="color1 pb-0 fw400"><b>Hi, {profile && profile.firstName || "" + ' ' + profile && profile.lastName || ""}.</b> Welcome back</h2>
                            <h5 className="color_man fw400">Account settings</h5>
                        </div>
                        <div className="col-md-4 tr pt-md-4">
                            {/* <a className="b color_man " href="#">Logout</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TopRiben;