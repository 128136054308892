import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Loader from "../common/Loader";
import InnerHtml from "../elements/InnerHtml";
import { Accordion } from "react-bootstrap"
import { useLocation } from 'react-router-dom';
function FaqsComponent() {
    const [isLoading, setIsLoading] = useState(true);
    const [faqs, setFaqs] = useState("");
    const location = useLocation();
    let currentPath = (location.pathname).replace('/', '')
    useEffect(() => {
        getContent();
    }, []);
    const getContent = async () => {
        UserServices.faqsList().then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setFaqs(data.faqs);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsLoading(false);
        })
    }
    const initCap = (string) => {
        string = string.split('-').join(" ")
        string = string.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
        return string;
    }
    return (
        <>
            <Header showMenu={1} />
            <Loader isLoading={isLoading} />
            <div className="in_banenr" >
                <img src={faqs.banner ? faqs.Image : require("../../webroot/images/about-banner.png").default} className="w-100" alt="Banner" />
                <div className="banner_content">
                    <div className="container">
                        <h1>{initCap(currentPath)}</h1>
                    </div>
                </div>
            </div>
            <section className="join_us_page">
                <div className="container faqs">
                    <h2>FAQ's</h2>
                    Get the latest insights and trends on selling online.
                    <Accordion defaultActiveKey="0">
                        {
                            faqs && faqs.map((faq, index) => {
                                return (
                                    <Accordion.Item eventKey={index} key={index}>
                                        <Accordion.Header>{faq.question}</Accordion.Header>
                                        <Accordion.Body>
                                            <InnerHtml rawHTML={faq.answer} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })
                        }
                    </Accordion>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default FaqsComponent;