import { Link } from "react-router-dom";

function Notification() {
    return (
        <>
            <div className=" pt-lg-0 dashbord-heading pb-2">
                <h5 className="box_hadding">Notifications <sup className="notifications_span">8</sup></h5>
                <Link to={"/my/notification"} className="btn-link">View All</Link>
            </div>
            <div className="notifications_boxs">
                <img src={require("../../webroot/images/user.png").default} alt="Cart" />
                <span>Featured plan payment done
                    VTsuccessfully for
                    <span className="co8">ICTKart</span>
                    <small className="date_time">12-10-2020 04: 45 PM</small>
                </span>
            </div>
        </>
    )
}
export default Notification;