import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { UserServices } from "../../services";
import { productSearch } from "../../store/actions/allAction"

function ProductSerach({ type }) {
    const [brandsArr, setBrandsArr] = useState([]);
    const [categoriesArr, setCategoriesArr] = useState([]);
    const [maxPriceArr, setMaxPriceArr] = useState([250, 500, 1000, 1500, 2000, 5000, 10000, 100000]);

    const [searchData, setSearchData] = useState({
        minPrice: 0,
        maxPrice: 100000
    });

    const disptch = useDispatch()
    useEffect(() => {
        getAllBrands();
        getAllCategories();
    }, [])
    const getAllBrands = () => {
        UserServices.brandDropdownList('brand').then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setBrandsArr(data);
            }
        })
    }
    const getAllCategories = () => {
        let typeps = type == 'servicelist' ? 'service' : 'product';
        UserServices.categoriesDropDownList(typeps).then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setCategoriesArr(data.list);
            }
        })
    }
    const changeHandler = (n, e) => {
        switch (n) {
            case 'sameDay':
            case 'onlyStock':
                setSearchData((prev) => ({ ...prev, [n]: e.target.checked }));
                break;
            case 'minPrice':
                setSearchData((prev) => ({ ...prev, [n]: e.target.value, maxPrice: 5 * e.target.value }));
                let arr = Array.from([2, 3, 4, 5, 6, 7, 8, 9, 10], x => x * e.target.value)
                setMaxPriceArr([...arr]);
                break;
            case 'maxPrice':
                setSearchData((prev) => ({ ...prev, [n]: e.target.value }));
                break;
            case 'search':
                setSearchData((prev) => ({ ...prev, [n]: e.target.value }));
                break;
            case 'brand':
                if (e.target.checked) {
                    if (searchData.brand) {
                        setSearchData((prev) => ({ ...prev, [n]: [...prev.brand, e.target.value] }));
                    }
                    else {
                        setSearchData((prev) => ({ ...prev, [n]: [e.target.value] }));
                    }
                }
                else {
                    let i = searchData.brand.findIndex((element) => element == e.target.value);
                    searchData.brand.splice(i, 1)
                    setSearchData({ ...searchData })
                }
                break;
            case 'category':
                if (e.target.checked) {
                    if (searchData.category) {
                        setSearchData((prev) => ({ ...prev, [n]: [...prev.category, e.target.value] }));
                    }
                    else {
                        setSearchData((prev) => ({ ...prev, [n]: [e.target.value] }));
                    }
                }
                else {
                    let i = searchData.category.findIndex((element) => element == e.target.value);
                    searchData.category.splice(i, 1)
                    setSearchData({ ...searchData })
                }
                break;
            default:
                break;
        }
    }
    const onSearch = (e) => {
        disptch(productSearch(searchData));
    }
    return (
        <>
            {(() => {
                switch (type) {
                    case 'prodetail':
                        return <div className="filter p-0">
                            <div className="container">
                                <ul className="scrool_tab">
                                    <li> <a href="#Overview" data-scroll-nav="1" className="active">Overview</a></li>
                                    <li> <a href="#Description" data-scroll-nav="2">Description</a></li>
                                    <li> <a href="#Reviews" data-scroll-nav="8">Related Services</a></li>
                                </ul>
                            </div>
                        </div>;
                    case 'servicedetail':
                        return <div className="filter p-0">
                            <div className="container">
                                <ul className="scrool_tab">
                                    <li> <a href="#Overview" data-scroll-nav="1" className="active">Overview</a></li>
                                    <li> <a href="#Description" data-scroll-nav="2">Description</a></li>
                                    <li> <a href="#Reviews" data-scroll-nav="3">Recently Viewed Service</a></li>
                                </ul>
                            </div>
                        </div>;
                    case 'vprofile':
                        return <div className="filter p-0">
                            <div className="container">
                                <ul className="scrool_tab">
                                    <li> <a href="#VendorProfile" data-scroll-nav="1" className="active">About The Seller</a></li>
                                    <li> <a href="#RatingReviews" data-scroll-nav="3">Reviews</a></li>
                                </ul>
                            </div>
                        </div>;
                    case 'prolist':
                    case 'servicelist':
                        return <div className="filter">
                            <div className="container">
                                <div className="searching-dropdown">
                                    <nav>
                                        <ul className="nav">
                                            <li><a href="#"><div className="menu_title form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input"
                                                        type="checkbox"
                                                        onChange={(e) => changeHandler('sameDay', e)}
                                                        name="sameDay"
                                                        value="today"
                                                    /> Same-day pickup
                                                </label>
                                            </div></a></li>
                                            <li><a href="#">Price</a>
                                                <ul className="sub-nav">
                                                    <li>
                                                        <a href="#" className="no-pad">
                                                            <div className="drop_collapse collapse show">
                                                                <div className="p-2 p-md-4">
                                                                    <div className="row">
                                                                        <div className="col-5 form-group  pr-0">
                                                                            <label> Min.</label>
                                                                            <select name="minPrice"
                                                                                onChange={(e) => changeHandler('minPrice', e)}
                                                                                className="min form-control"
                                                                                value={searchData.minPrice}
                                                                            >
                                                                                <option value="0">Min</option>
                                                                                {[10, 50, 100, 500, 1000, 10000, 100000].map((v, i) => {
                                                                                    return (
                                                                                        <option key={i} value={v}>{v}</option>
                                                                                    )
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-2 form-group pt-4 mt-3">
                                                                            To
                                                                        </div>
                                                                        <div className="col-5 form-group pl-0">
                                                                            <label> Max.</label>
                                                                            <select
                                                                                onChange={(e) => changeHandler('maxPrice', e)}
                                                                                name="maxPrice"
                                                                                value={searchData.maxPrice}
                                                                                className="min form-control"
                                                                            >
                                                                                {maxPriceArr.map((v, i) => {
                                                                                    return (
                                                                                        <option key={i} value={v}>{v}</option>
                                                                                    )
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            {type == 'servicelist' ? null :
                                                <li><a href="#">Brand</a>
                                                    <ul>
                                                        {brandsArr && brandsArr.map((v, i) => {
                                                            return (<li key={i} className="form-check">
                                                                <a href="#">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            value={v.value}
                                                                            onChange={(e) => changeHandler('brand', e)}
                                                                            name="brand"
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={searchData && searchData.brand && searchData.brand.includes(v.value) ? 'checked' : null}
                                                                        /> {v.label}
                                                                    </label>
                                                                </a>
                                                            </li>)
                                                        })}
                                                    </ul>
                                                </li>
                                            }
                                            <li><a href="#">Category</a>
                                                <ul className="sub-nav">
                                                    {categoriesArr && categoriesArr.map((v, i) => {
                                                        return (
                                                            <li key={i + 'cat'}>
                                                                <Link to={'/products/list?cate=' + v.value}>{v.label}</Link>
                                                                {v.subcategories && v.subcategories.length ?
                                                                    <ul>
                                                                        {v.subcategories && v.subcategories.map((v, i) => {
                                                                            return (
                                                                                <li key={i + "_" + i}>
                                                                                    <a className="form-check">
                                                                                        <label className="form-check-label">
                                                                                            <input className="form-check-input"
                                                                                                checked={searchData && searchData.category && searchData.category.includes(v.value) ? 'checked' : null}
                                                                                                name="category"
                                                                                                onChange={(e) => changeHandler('category', e)} value={v.value}
                                                                                                type="checkbox" />{v.label}
                                                                                        </label>
                                                                                    </a>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul> : null}
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </li>
                                            {/* <li>
                                                <a href="#">
                                                    <div className="menu_title">
                                                        <input className="form-control"
                                                            type="text"
                                                            onChange={(e) => changeHandler('search', e)}
                                                            name="search"
                                                            placeholder="Search by product name or model number"
                                                        />
                                                    </div>
                                                </a>
                                            </li> */}
                                            <li><a onClick={onSearch} className="btn apply-search btn-primary btn-small">Apply</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>;
                }
            })()}
        </>
    )
}
export default ProductSerach;