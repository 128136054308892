import Footer from "../common/Footer"
import Header from "../common/Header";
import Loader from "../common/Loader"
import { UserServices, NotificationService } from "../../services"
import { Component, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { withRouter } from 'react-router-dom'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import secureStorage from "../../config/encrypt";
import InnerHtml from "../elements/InnerHtml";
import { API_URL } from "../../config/constant";
class SignupComponent extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.acceptTermCondition = this.acceptTermCondition.bind(this);
        this.showTermCondition = this.showTermCondition.bind(this);
        this.state = {
            isLoading: false,
            userType: this.props.userType || 'user',
            userTypes: [{ key: 'user', label: 'User' }, { key: 'vendor', label: 'Vendor/Seller' }],
            isOkTermAndCondition: false,
            content: "",
            userForm: {
                mobile: "",
                role: "user",
                country: "India",
                iso2: "in",
                hearAboutICT: "Friend",
                firstName: "",
                lastName: "",
                email: "",
                password: ""
            }
        }
    }
    componentDidMount = async () => {
        this.getDialCode();
    }
    getDialCode = () => {
        UserServices.countryCode().then((responce) => {
            let { country_code = "IN", country_calling_code, country_name = "India" } = responce;
            let { userForm } = this.state;
            userForm['country'] = country_name;
            userForm['dialCode'] = country_calling_code;
            userForm['iso2'] = country_code.toLowerCase();
            this.setState({ userForm });

        }).catch((error) => {
            toast.error(error);
        })
    }
    getAllCountries = () => {
        UserServices.countryList().then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
            }
            else {
                toast.error(message);
            }
        }).catch((error) => {
            toast.error(error);
        })
    }
    onChangeFlag = (num, country) => {
        let { userForm } = this.state;
        userForm['country'] = country.name;
        userForm['dialCode'] = country.dialCode;
        userForm['iso2'] = country.iso2;
        this.setState({ userForm });
    }
    onChangeMobile(status, value, countryData) {
        let { userForm } = this.state;
        userForm['mobile'] = value;
        userForm['dialCode'] = countryData.dialCode;
        userForm['iso2'] = countryData.iso2;
        this.setState({ userForm });
    }
    handleChange(event) {
        let { userForm } = this.state;
        userForm[event.target.name] = event.target.value
        this.setState({ userForm });
    }
    handleSubmit(e) {
        let { isOkTermAndCondition, userForm, userType } = this.state;
        userForm['role'] = userType;
        if (isOkTermAndCondition && userForm.email != "") {
            this.setState((pre) => ({
                ...pre,
                isLoading: true
            }))
            UserServices.addUser(userForm).then((responce) => {
                let { data, message, statusCode } = responce;
                if (statusCode == 200) {
                    toast.success(message);
                    const { history } = this.props;
                    if (userType == 'vendor') {
                        secureStorage.setItem("EMAIL", userForm.email);
                        secureStorage.setItem(API_URL.COMMON_LOGIN_STORE_KEY, { user: data })
                        if (history) history.push('/user/thank-you');
                    }
                    else {
                        if (history) history.push('/');
                    }
                }
                else {
                    NotificationService.show(message, false);
                }
                this.setState((pre) => ({
                    ...pre,
                    isLoading: false
                }))
            })
        }
        else {
            toast.error('Please fill all the fields and select term and condition')
        }
    }
    acceptTermCondition(evt) {
        this.setState({ isOkTermAndCondition: evt.target.checked })
    }
    showTermCondition(evt) {
        let { userType } = this.state;
        let type = userType == 'vendor' ? 'vendor-terms-and-conditions' : 'user-terms-and-conditions';
        UserServices.contentDetail({ type: type }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                this.setState({ content: data })
            }
            else {
                toast.error(message);
            }
        }).catch((error) => {
            toast.error(error);
        })
    }
    render() {
        let { userType, isLoading, userForm, content } = this.state;
        return (
            <>
                <Header showMenu={1} />
                <Loader isLoading={isLoading} />
                <section className=" bg-blue-light join_us_page">
                    <div className="container">
                        <div className="row  pt-2 pt-md-4">
                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <p className="m-0 p-0">Join world best market place for</p>
                                <h3><span className="color1">Products</span>, <span className="color_man">Service</span> and <span className="color3">Solutions</span></h3>
                                <h2 className="color1">Join Now!</h2>
                                {
                                    userType == 'user' ?
                                        <img src={require("../../webroot/images/buyer_signup.png").default} alt="signup" />
                                        : <img src={require("../../webroot/images/signup.png").default} alt="signup" />
                                }
                            </div>
                            <div className="col-lg-6 pl-lg-5">
                                <div className="join_us_right">
                                    {
                                        userType == 'user' ?
                                            <>
                                                <div className="form-group">
                                                    <label className="required" >First Name</label>
                                                    <input type="text" name="firstName"
                                                        value={userForm.firstName}
                                                        onChange={this.handleChange}
                                                        className="form-control2" />
                                                </div>
                                                <div className="form-group">
                                                    <label className="" >Last Name</label>
                                                    <input type="text" name="lastName"
                                                        value={userForm.lastName}
                                                        onChange={this.handleChange}
                                                        className="form-control2" />
                                                </div>
                                            </>
                                            :
                                            <div className="form-group">
                                                <label className="required" >Company Name</label>
                                                <input type="text" name="companyName"
                                                    value={userForm.companyName}
                                                    onChange={this.handleChange}
                                                    className="form-control2" />
                                            </div>
                                    }
                                    <div className="form-group">
                                        <label className="required" >Mobile</label>
                                        <IntlTelInput
                                            value={userForm.mobile}
                                            containerClassName="intl-tel-input"
                                            inputClassName="form-control number"
                                            preferredCountries={['in']}
                                            onSelectFlag={this.onChangeFlag.bind(this)}
                                            onPhoneNumberChange={this.onChangeMobile.bind(this)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="required" >Country</label>
                                        <input type="text"
                                            name="country"
                                            value={userForm.country}
                                            className="form-control2"
                                            readOnly
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="required" >Email Address</label>
                                        <input type="email"
                                            name="email"
                                            autoFocus={true}
                                            autoComplete="nope"
                                            value={userForm.email}
                                            onChange={this.handleChange}
                                            className="form-control2" />
                                    </div>

                                    <div className="form-group">
                                        <label className="required" >Password</label>
                                        <input type="password"
                                            name="password"
                                            autoFocus={true}
                                            autoComplete="nope"
                                            value={userForm.password}
                                            onChange={this.handleChange}
                                            className="form-control2" />
                                    </div>
                                    <div className="form-group">
                                        <label className="required" >How Did you hear about ICT?</label>
                                        <select className="form-control2" name="hearAboutICT"
                                            value={userForm.hearAboutICT}
                                            onChange={this.handleChange}>
                                            <option value="Friend">Friend</option>
                                            <option value="Website">Website</option>
                                        </select>
                                    </div>
                                    <div className="form-group form-check color">
                                        <input type="checkbox" className="form-check-input" id="termAndCondition"
                                            onChange={this.acceptTermCondition} />
                                        <label className="form-check-label" htmlFor="termAndCondition">I have read and agree with the </label><a className="color1 cursor-pointer" onClick={this.showTermCondition} data-toggle="modal" data-target="#termAndConditions"> terms & conditions.</a>
                                    </div>
                                    <button type="submit" onClick={this.handleSubmit} className="btn btn-primary w100">Continue</button>
                                    {/* <div className="tc pt-3">
                                        Company Name <a href="#" className="color_man"> Sign In</a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <div className={`modal fade`} id="termAndConditions" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-body p-md-4">
                                <h3>{content.title || ''}</h3>
                                <InnerHtml rawHTML={content.description} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(SignupComponent);