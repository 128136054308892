import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { UserServices } from "../../services";

function NewsletterFormController() {
    const btnRefs = useRef();
    const [inputField, setInputField] = useState({
        email: ""
    });
    const inputHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value })
    }
    const subscribeNewsletter = async () => {
        btnRefs.current.setAttribute("disabled", "disabled");
        UserServices.newsletterAddSubscriber(inputField).then((responce) => {
            // let { message, status } = responce.data;
            if (responce.status === true  || responce.statusCode) {
                toast.success("subscribed successfully");
            }
            else {
                toast.error(responce.message);
                console.log(responce);
            }
        })
        setInputField({email: ""})
        // console.log(inputField);
    }
    return (
        <>
            <section className="newsletter-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h3>Sign up with our Newsletter</h3>
                            <p>Stay updated and get our latest news right into your inbox. No spam.</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="newsletter-box">
                                <input type="text" name="email"
                                    value={inputField.email}
                                    onChange={inputHandler}
                                    className="form-control" placeholder="Enter your email address" />
                                <button type="button"
                                    onClick={subscribeNewsletter}
                                    ref={btnRefs}
                                    className="btn btn-primary">Subscribe</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default NewsletterFormController;