import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
const Mobile = ({ value, onChange, name, preferredCountry = "in" }) => {
    const onChangeFlag = (num, country) => {
        let mobObj = {
            country: country.name,
            dialCode: country.dialCode,
            iso2: country.iso2,
            mobile: ""
        }
        onChange(mobObj, name)
    }
    const onChangeMobile = (status, value, countryData) => {
        let mobObj = {
            country: countryData.name,
            mobile: value,
            dialCode: countryData.dialCode,
            iso2: countryData.iso2
        }
        onChange(mobObj, name)
    }
    return (
        <IntlTelInput
            value={value}
            containerClassName="intl-tel-input"
            inputClassName="form-control number"
            preferredCountries={[preferredCountry]}
            onSelectFlag={onChangeFlag}
            onPhoneNumberChange={onChangeMobile}
        />
    );
}
export { Mobile }