import ActionTypes from "../actions/action"
const initialState = {
}
const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ADD_TO_CART:
            return {
                ...state,
                cart: action.payload
            }
        case ActionTypes.PRODUCT_SEARCH:
            return {
                ...state,
                search: action.payload
            }
        default: return state;
    }
}

export default productReducer;