import Footer from "../common/Footer"
import Header from "../common/Header";
import { UserServices } from "../../services"
import { Component, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { withRouter } from 'react-router-dom'
import secureStorage from "../../config/encrypt";
import { API_URL } from "../../config/constant";

class ActivateUser extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            userTypes: [{ key: 'user', label: 'User' }, { key: 'vendor', label: 'Vendor/Seller' }],
            userForm: {
                companyName: "",
                country: "india",
                name: "",
                mobile: ""
            }
        }
    }
    componentDidMount = async () => {
        this.getUserDetails();
    }
    getUserDetails() {
        UserServices.userDetail(this.props.match.params.userId).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                let { user } = data;
                if(user.isVerify){
                    toast.success('Your account already verified');
                    this.props.history.push('/');
                }
                let { userForm } = this.state;
                userForm.companyName= user.companyName;
                this.setState({userForm });
            }
            else {
                toast.error(message);
                this.props.history.push('/');
            }
        })
    }
    handleChange(event) {
        let { userForm } = this.state;
        userForm[event.target.name] = event.target.value
        this.setState({ userForm });
    }
    validForm() {
        let { userForm } = this.state;
        let isValid = false;
        if (userForm.email) {
            isValid = true;
        }
        if (userForm.mobile) {
            isValid = true;
        }
        if (userForm.name) {
            isValid = true;
        }
        return isValid;
    }
    handleSubmit(e) {
        let { userForm } = this.state;
        if (this.validForm()) {
            userForm.userId = this.props.match.params.userId;
            UserServices.activateUser(userForm).then((responce) => {
                let { data, message, statusCode } = responce;
                if (statusCode == 200) {
                    secureStorage.setItem(API_URL.COMMON_LOGIN_STORE_KEY, data)
                    toast.success(message);
                    // this.props.history.push('/user/dashboard');
                }
                else {
                    toast.error(message);
                }
            })
        }
        else {
            toast.error('Fill all the input fields');
        }
    }
    render() {
        let { userForm } = this.state;
        return (
            <>
                <Header showMenu={1} />
                <section className=" bg-blue-light join_us_page">
                    <div className="container">

                        <div className="row  pt-2 pt-md-4">
                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <p className="m-0 p-0">Join world best market place for</p>
                                <h3><span className="color1">Products</span>, <span className="color_man">Service</span> and <span className="color3">Solutions</span></h3>
                                <h2 className="color1">Join Now!</h2>
                                <img src={require("../../webroot/images/signup.png").default} alt="signup" />
                            </div>
                            <div className="col-lg-6 pl-lg-5">
                                <div className="join_us_right">
                                    <div className="form-group">
                                        <label className="" >Company Name</label>
                                        <input type="text" name="companyName"
                                            value={userForm.companyName}
                                            onChange={this.handleChange}
                                            className="form-control2" />
                                    </div>
                                    <div className="form-group">
                                        <label className="" >Country</label>
                                        <select className="form-control2"
                                            name="country"
                                            value={userForm.country}
                                            onChange={this.handleChange}>
                                            <option value="india">India</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="" >Your Name</label>
                                        <input type="name"
                                            name="name"
                                            value={userForm.name}
                                            onChange={this.handleChange}
                                            className="form-control2" />
                                    </div>

                                    <div className="form-group">
                                        <label className="" >Mobile</label>
                                        <input type="tesxt"
                                            name="mobile"
                                            value={userForm.mobile}
                                            onChange={this.handleChange}
                                            className="form-control2" />
                                    </div>
                                    <button type="submit" onClick={this.handleSubmit} className="btn btn-primary w100">Activate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

export default withRouter(ActivateUser);