function ServiceCompaire() {
    return (<>
        <h5 className="mt-3 mb-md-3">Compare</h5>
        <div className="row">
            <div className="col-lg-9 ">
                <div className="table-responsive">
                    <table className="table table-striped table-bordered tc">
                        <thead>
                            <tr>
                                <td className="tl">Package</td>
                                <td className="tl">
                                    <h5>AED <b>788</b></h5>
                                    <h5>Basic</h5>
                                    <h6 className="mb-3">Basic Package - POPular</h6>
                                    3 Awesome Logo + Free Revisions + DJPG and Transpart PNG + Vector es- No Mascot design
                                </td>
                                <td className="tl">
                                    <h5>AED <b>2.364</b></h5>
                                    <h5>Standard</h5>
                                    <h6 className="mb-3">Basic Package - POPular</h6>
                                    5 Awesome Logo + Free Revisions + DJPG and Transpart PNG + Vector es- No Mascot design
                                </td>
                                <td className="tl">
                                    <h5>AED <b>3.364</b></h5>
                                    <h5>Premium</h5>
                                    <h6 className="mb-3">Basic Package - POPular</h6>
                                    8 Awesome Logo + Free Revisions + DJPG and Transpart PNG + Vector es- No Mascot design
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="tl">Source File</td>
                                <td><i className="fa fa-check"></i></td>
                                <td><i className="fa fa-check"></i></td>
                                <td><i className="fa fa-check"></i></td>
                            </tr>
                            <tr>
                                <td className="tl">Logo Transparency</td>
                                <td><i className="fa fa-check"></i></td>
                                <td><i className="fa fa-check"></i></td>
                                <td><i className="fa fa-check"></i></td>
                            </tr>

                            <tr>
                                <td className="tl">Hight Resolutions</td>
                                <td><i className="fa fa-check"></i></td>
                                <td><i className="fa fa-check"></i></td>
                                <td><i className="fa fa-check"></i></td>
                            </tr>
                            <tr>
                                <td className="tl">3D Mockup</td>
                                <td><i className="fa fa-check"></i></td>
                                <td><i className="fa fa-check"></i></td>
                                <td><i className="fa fa-check"></i></td>
                            </tr>
                            <tr>
                                <td className="tl">Stationey Designs</td>
                                <td><i className="fa fa-check"></i></td>
                                <td><i className="fa fa-check"></i></td>
                                <td><i className="fa fa-check"></i></td>
                            </tr>

                            <tr>
                                <td className="tl">Social Media Kit</td>
                                <td><i className="fa fa-check"></i></td>
                                <td><i className="fa fa-check"></i></td>
                                <td><i className="fa fa-check"></i></td>
                            </tr>
                            <tr>
                                <td className="tl">Vector File</td>
                                <td><i className="fa fa-check"></i></td>
                                <td><i className="fa fa-check"></i></td>
                                <td><i className="fa fa-check"></i></td>
                            </tr>

                            <tr>
                                <td className="tl"># of Initial Concepts Included</td>
                                <td><i className="fa fa-check"></i></td>
                                <td><i className="fa fa-check"></i></td>
                                <td><i className="fa fa-check"></i></td>
                            </tr>
                            <tr>
                                <td className="tl">Revisions</td>
                                <td>Unlimited</td>
                                <td>Unlimited</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td className="tl ">Delivery Time</td>
                                <td>2 days</td>
                                <td>2 days</td>
                                <td>2 days</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>AED <b>788</b><br />
                                    <span className="c2">SELECT</span>
                                </td>
                                <td>AED <b>788</b><br />
                                    <span className="c2">SELECT</span>
                                </td>
                                <td>AED <b>788</b><br />
                                    <span className="c2">SELECT</span>
                                </td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>

        </div>
    </>)
}
export default ServiceCompaire;