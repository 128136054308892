import { UserServices } from "../../services"
import { Component, useEffect, useState } from "react";
import { toast } from "react-toastify";
import secureStorage from "../../config/encrypt";
import { API_URL } from "../../config/constant";
import { Link } from "react-router-dom";
import Select from 'react-select';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import Loader from '../common/Loader';
import BrandPartner from "./BrandPartner";
import RegionalPresence from "./RegionalPresence";
class UpdateProfileCompCateBrand extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.companyHandleChange = this.companyHandleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeMobile = this.onChangeMobile.bind(this);
        this.state = {
            categoriesArr: [],
            currencyArr: [],
            countriesArr: [],
            brandsArr: [],
            userId: "",
            activeTab: 'myProfile',
            userForm: {
                firstName: "",
                lastName: "",
                dialCode: "",
                iso2: "",
                mobile: "",
                avatar: ""
            },
            companyForm: {
                companyName: "",
                postalAddress: "",
                country: "",
                state: "",
                city: "",
                postcode: "",
            },
            categories: []
        }
    }
    componentDidMount = async () => {
        let { userForm, companyForm } = this.state;
        let userDetail = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY);
        if (userDetail && userDetail.user) {
            userForm = userDetail.user;
            this.setState({ userId: userDetail.user._id })
            this.setState({ userForm });
            companyForm.companyName = userDetail.user ? userDetail.user.companyName : "";
            this.setState({ ...companyForm });
        }
        else {
            // this.props.history.push('/');
        }
        this.getDialCode();
        this.getAllBrands();
        this.getAllCategories();
        this.getCountryList();
        this.getCurrencyList();
    }
    getDialCode = () => {
        UserServices.countryCode().then((responce) => {
            let { country_code = "IN", country_calling_code, country_name = "India" } = responce;
            let { companyForm } = this.state;
            companyForm['companyName'] = country_name;
            this.setState({ companyForm });

        }).catch((error) => {
            toast.error(error);
        })
    }
    changeTabs(tabName) {
        this.setState({ activeTab: tabName })
    }
    getAllBrands() {
        UserServices.brandDropdownList().then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                this.setState({ brandsArr: data });
            }
        })
    }
    getAllCategories() {
        UserServices.categoriesDropDownList().then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                this.setState({ categoriesArr: data.list });
            }
        })
    }
    getCountryList() {
        UserServices.countryList().then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                this.setState({ countriesArr: data });
            }
        })
    }
    getCurrencyList() {
        UserServices.currencyList().then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                this.setState({ currencyArr: data });
            }
        })
    }
    handleChange(event) {
        let { userForm } = this.state;
        if (event.target.files) {
            userForm['avatar'] = event.target.files[0];
        }
        else {
            userForm[event.target.name] = event.target.value
        }
        this.setState({ userForm });
    }
    onChangeMobile(status, value, countryData) {
        let { userForm } = this.state;
        userForm['mobile'] = value;
        userForm['dialCode'] = countryData.dialCode;
        userForm['iso2'] = countryData.iso2;
        this.setState({ userForm });
    }
    validForm() {
        let { userForm } = this.state;
        let isValid = false;
        if (userForm.firstName) isValid = true;
        if (userForm.lastName) isValid = true;
        if (userForm.mobile) isValid = true;
        return isValid;
    }
    handleSubmit(e) {
        let { userForm, userId } = this.state;
        if (this.validForm()) {
            const formData = new FormData();
            formData.append('firstName', userForm.firstName);
            formData.append('lastName', userForm.lastName);
            formData.append('dialCode', userForm.dialCode);
            formData.append('iso2', userForm.iso2);
            formData.append('mobile', userForm.mobile);
            formData.append('avatar', userForm.avatar);
            formData.append('id', userId);
            this.userSUBMI(formData, "companyInfo");
        }
        else {
            toast.error('Please fill all the input fields');
        }
    }
    userSUBMI(formData, tabName = "") {
        UserServices.updateUser(formData).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                toast.success(message);
            }
            else {
                toast.error(message);
            }
            this.changeTabs(tabName);
        }).catch(err => {
            console.log(err);
            toast.error(err.message);
        })
    }
    /** company */
    companyHandleChange(event, name = "") {
        let { companyForm } = this.state;
        if (name == 'country') {
            companyForm['country'] = event.name;
        }
        else if (name == 'currency') {
            companyForm['currency'] = event.value;
        }
        else {
            companyForm[event.target.name] = event.target.value
        }
        this.setState({ companyForm });
    }
    companyHandleSubmit(e) {
        let { companyForm, userId } = this.state;
        companyForm.vendorId = userId;
        UserServices.updateCompanyinfo(companyForm).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                toast.success(message);
            }
            else {
                toast.error(message);
            }
            this.changeTabs('categories');
        }).catch(err => {
            console.log(err);
            toast.error(err.message);
        })
    }
    /***cateHandleChange */
    cateHandleChange(e) {
        let newcategories = e.map((obj) => obj.value)
        this.setState({ categories: newcategories })
    }
    cateHandleSubmit(e) {
        let { categories, userId } = this.state;
        let cateObj = {
            id: userId,
            categories: JSON.stringify(categories)
        }
        this.userSUBMI(cateObj, "categories");
    }
    render() {
        let { userForm, companyForm, countriesArr, categoriesArr, categories,
            brandPartenships, brandsArr, userId, activeTab, currencyArr } = this.state;
        return (
            <>
                <header>
                    <div className="container">
                        <nav className="navbar navbar2 navbar-expand-lg navbar-light">
                            <a className="up-nav-prof">
                                <img src={require("../../webroot/images/logo.png").default} alt="Logo" />
                            </a>
                            <h3 className="up-nav-prof">Your profile & company information</h3>
                            <Link to={"/dashboard"} className="skip-header up-nav-prof btn">
                                Skip & Go to Dashboard
                            </Link>
                        </nav>
                    </div>
                </header>
                <div className="clearfix"></div>
                <div className="container-update-prof">
                    <div className="content-update-prof">
                        {/* <div className="ict-profile-screen-right-triangle"></div>
                        <div className="ict-profile-screen-left-triangle"></div> */}
                        <ul className="nav update-prof_tab">
                            <li className="nav-item" onClick={this.changeTabs.bind(this, 'myProfile')}>
                                <a className={activeTab == 'myProfile' ? "active" : ''} data-toggle="tab" href="#myProfile">  My Profile </a>
                            </li>
                            <li className="nav-item" onClick={this.changeTabs.bind(this, 'companyInfo')}>
                                <a className={activeTab == 'companyInfo' ? "active" : ''} data-toggle="tab" href="#companyInfo">Company Info </a>
                            </li>
                            <li className="nav-item" onClick={this.changeTabs.bind(this, 'categories')}>
                                <a className={activeTab == 'categories' ? "active" : ''} data-toggle="tab" href="#categories"> Categories</a>
                            </li>
                            <li className="nav-item" onClick={this.changeTabs.bind(this, 'brandProducts')}>
                                <a className={activeTab == 'brandProducts' ? "active" : ''} data-toggle="tab" href="#brandProducts"> Brands & Products</a>
                            </li>
                            <li className="nav-item" onClick={this.changeTabs.bind(this, 'regionalPresence')}>
                                <a className={activeTab == 'regionalPresence' ? "active" : ''} data-toggle="tab" href="#regionalPresence"> Regional Presence</a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className={'tab-pane ' + (activeTab == 'myProfile' ? "active" : '')} id="myProfile">
                                <div className="row">
                                    <div className="col-lg-12 form-group">
                                        <div className="profile-pic-wrapper">
                                            <div className="pic-holder">
                                                <img id="profilePic" className="pic" src={require("../../webroot/images/seller.png").default} />
                                                <label htmlFor="newProfilePhoto" className="upload-file-block">
                                                    <div className="text-center">
                                                        <div className="mb-2">
                                                            <i className="fa fa-camera fa-2x"></i>
                                                        </div>
                                                        <div className="text-uppercase">
                                                            Update <br /> Profile Photo
                                                        </div>
                                                    </div>
                                                </label>
                                                <input className="uploadProfileInput" onChange={this.handleChange}
                                                    type="file" name="profile_pic" id="newProfilePhoto" accept="image/*" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 form-group">
                                        <label className="" >First Name</label>
                                        <input type="text"
                                            name="firstName"
                                            value={userForm.firstName}
                                            onChange={this.handleChange}
                                            className="form-control2" />
                                    </div>
                                    <div className="col-lg-12 form-group">
                                        <label className="" >Last Name</label>
                                        <input type="text"
                                            name="lastName"
                                            value={userForm.lastName}
                                            onChange={this.handleChange}
                                            className="form-control2" />
                                    </div>
                                    <div className="col-lg-12 form-group">
                                        <label className="" >Mobile</label>
                                        <IntlTelInput
                                            value={userForm.mobile}
                                            containerClassName="intl-tel-input"
                                            inputClassName="form-control number"
                                            preferredCountries={['in']}
                                            onSelectFlag={(num, country) => {
                                                // console.log('onSelectFlag', country);
                                            }}
                                            onPhoneNumberChange={this.onChangeMobile}
                                        />
                                    </div>
                                    <div className="up-prof-footer">
                                        <div></div>
                                        <Link type="button" to={"/dashboard"} className="btn btn-gray btn-sm">Skip & Go to Dashboard</Link>
                                        <button type="button" onClick={this.handleSubmit} className="btn btn-primary btn-sm"><i className="fa fa-arrow-right" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className={'tab-pane ' + (activeTab == 'companyInfo' ? "active" : '')} id="companyInfo">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h3>Company Information</h3>
                                    </div>
                                    <div className="col-lg-12 form-group">
                                        <label className="" >Company Name</label>
                                        <input type="text"
                                            name="companyName"
                                            value={companyForm.companyName}
                                            onChange={this.companyHandleChange}
                                            className="form-control2" />
                                    </div>
                                    <div className="col-lg-12 form-group">
                                        <label className="" >Country</label>
                                        <Select
                                            name="country"
                                            value={countriesArr.filter(obj => companyForm['country'] == (obj.name))}
                                            onChange={(e) => this.companyHandleChange(e, 'country')}
                                            options={countriesArr}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.name}
                                            isClearable
                                        />
                                    </div>
                                    <div className="col-lg-12 form-group">
                                        <label className="" >Currency</label>
                                        <Select
                                            name="currency"
                                            value={currencyArr.filter(obj => companyForm['currency'] == (obj.value))}
                                            onChange={(e) => this.companyHandleChange(e, 'currency')}
                                            options={currencyArr}
                                            getOptionLabel={option => option.value + '-' + option.name + '(' + option.symbol + ')'}
                                            getOptionValue={option => option.value}
                                            isClearable
                                            isClearable
                                        />
                                    </div>
                                    <div className="col-lg-12 form-group">
                                        <label className="" >Address</label>
                                        <input type="tesxt"
                                            name="postalAddress"
                                            value={companyForm.postalAddress}
                                            onChange={this.companyHandleChange}
                                            className="form-control2" />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <label className="" >State</label>
                                        <input type="tesxt"
                                            name="state"
                                            value={companyForm.state}
                                            onChange={this.companyHandleChange}
                                            className="form-control2" />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <label className="" >City</label>
                                        <input type="tesxt"
                                            name="city"
                                            value={companyForm.city}
                                            onChange={this.companyHandleChange}
                                            className="form-control2" />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <label className="" >Postcode</label>
                                        <input type="tesxt"
                                            name="postcode"
                                            value={companyForm.postcode}
                                            onChange={this.companyHandleChange}
                                            className="form-control2" />
                                    </div>
                                    <div className="up-prof-footer">
                                        <button type="button" onClick={this.changeTabs.bind(this, 'myProfile')} className="btn btn-primary btn-sm"><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                                        <div></div>
                                        <button type="button" onClick={this.companyHandleSubmit.bind(this)} className="btn btn-primary btn-sm"><i className="fa fa-arrow-right" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className={'tab-pane ' + (activeTab == 'categories' ? "active" : '')} id="categories">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h3>Interested Categories</h3>
                                    </div>
                                    <div className="col-lg-12 form-group">
                                        <Select
                                            value={categoriesArr.filter(obj => categories.includes(obj.value))}
                                            onChange={this.cateHandleChange.bind(this)}
                                            options={categoriesArr}
                                            isMulti
                                        />
                                    </div>
                                    <div className="up-prof-footer">
                                        <button type="button" onClick={this.changeTabs.bind(this, 'companyInfo')} className="btn btn-primary btn-sm"><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                                        <div></div>
                                        <button type="button" onClick={this.cateHandleSubmit.bind(this)} className="btn btn-primary btn-sm"><i className="fa fa-arrow-right" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className={'tab-pane ' + (activeTab == 'brandProducts' ? "active" : '')} id="brandProducts">
                                <BrandPartner userId={userId} brandsArr={brandsArr} />
                                <div className="up-prof-footer">
                                    <button type="button" onClick={this.changeTabs.bind(this, 'categories')} className="btn btn-primary btn-sm"><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                                    <div></div>
                                    <button type="button" onClick={this.changeTabs.bind(this, 'regionalPresence')} className="btn btn-primary btn-sm"><i className="fa fa-arrow-right" aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <div className={'tab-pane ' + (activeTab == 'regionalPresence' ? "active" : '')} id="regionalPresence">
                                <RegionalPresence
                                    categoriesArr={categoriesArr}
                                    userId={userId}
                                    brandsArr={brandsArr}
                                    countriesArr={countriesArr}
                                    onClick={this.changeTabs.bind(this, 'brandProducts')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default UpdateProfileCompCateBrand;