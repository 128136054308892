import { Link, NavLink } from "react-router-dom";
import { userPermission, verifyVendorAccount } from "../../utils"

function LeftMenu({ menuFor }) {
    return (
        <>
            <div className="nav" id="navbar">
                <div className="header__toggle">
                    <i className='fa fa-bars' id="header-toggle"></i>
                </div>
                <nav className="nav__container">
                    <div>
                        <div className="nav__list">
                            {menuFor == 'vendor' ?
                                <div className="nav__items">
                                    <Link to={'/'} className="nav__link active">
                                        <i className='fa fa-home nav__icon'></i>
                                        <span className="nav__name">Home</span>
                                    </Link>
                                    <Link to={'/my/profile'} className="nav__link ">
                                        <i className='fa fa-th-large nav__icon'></i>
                                        <span className="nav__name">Profile</span>
                                    </Link>
                                    {userPermission('order', 'list') ?
                                        <div className="nav__dropdown">
                                            <a className="nav__link">
                                                <i className='fa fa-list-alt nav__icon'></i>
                                                <span className="nav__name">My Order</span>
                                                <i className='fa fa-plus nav__icon nav__dropdown-icon'></i>
                                            </a>
                                            <div className="nav__dropdown-collapse">
                                                <div className="nav__dropdown-content">
                                                    <Link to={'/my/orders'} className="nav__dropdown-item">Delivered</Link>
                                                    <Link to={'/my/order/pending'} className="nav__dropdown-item">Pending</Link>
                                                    <Link to={'/my/order/returned'} className="nav__dropdown-item">Returned</Link>
                                                    <Link to={'/my/order/cancel'} className="nav__dropdown-item">Cancel</Link>
                                                </div>
                                            </div>
                                        </div> : null
                                    }
                                    <div className="nav__dropdown">
                                        <a className="nav__link">
                                            <i className='fa fa-desktop nav__icon'></i>
                                            <span className="nav__name">My Products</span>
                                            <i className='fa fa-plus nav__icon nav__dropdown-icon'></i>
                                        </a>
                                        <div className="nav__dropdown-collapse">
                                            <div className="nav__dropdown-content">
                                                {userPermission('product', 'list') ?
                                                    <Link to={'/my/products'} className="nav__dropdown-item">My Products</Link> : null
                                                }
                                                {userPermission('product', 'add') && verifyVendorAccount() ?
                                                    <Link to={'/my/manage-product'} className="nav__dropdown-item">Manage Product</Link> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nav__dropdown">
                                        <a className="nav__link">
                                            <i className='fa fa-briefcase nav__icon'></i>
                                            <span className="nav__name">My Services</span>
                                            <i className='fa fa-plus nav__icon nav__dropdown-icon'></i>
                                        </a>
                                        <div className="nav__dropdown-collapse">
                                            <div className="nav__dropdown-content">
                                                {userPermission('product', 'list') ?
                                                    <Link to={'/my/services'} className="nav__dropdown-item">My Services</Link> : null
                                                }
                                                {userPermission('product', 'add') ?
                                                    <Link to={'/my/manage-service'} className="nav__dropdown-item">Manage Service</Link> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nav__dropdown">
                                        <a className="nav__link">
                                            <i className='fa fa-lightbulb-o nav__icon'></i>
                                            <span className="nav__name">Solutions</span>
                                            <i className='fa fa-plus nav__icon nav__dropdown-icon'></i>
                                        </a>
                                        <div className="nav__dropdown-collapse">
                                            <div className="nav__dropdown-content">
                                                <Link to={'/my/solutions'} className="nav__dropdown-item">My Solutions</Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="nav__dropdown">
                                        <a className="nav__link">
                                            <i className='fa fa-book nav__icon'></i>
                                            <span className="nav__name">Team & Role</span>
                                            <i className='fa fa-plus nav__icon nav__dropdown-icon'></i>
                                        </a>
                                        <div className="nav__dropdown-collapse">
                                            {userPermission('role', 'list') ?
                                                <div className="nav__dropdown-content">
                                                    <Link to={'/my/team-role'} className="nav__dropdown-item">Roles</Link>
                                                </div> : null
                                            }
                                            {userPermission('user', 'list') ?
                                                <div className="nav__dropdown-content">
                                                    <Link to={'/my/teams'} className="nav__dropdown-item">Teams</Link>
                                                </div> : null
                                            }
                                        </div>
                                    </div>
                                    {/* <a to={'/dashboard'} className="nav__link">
                                        <i className='fa fa-exchange nav__icon'></i>
                                        <span className="nav__name">Ledger Management</span>
                                    </a> */}
                                    {/* <Link to={'/my/notifications'} className="nav__link active">
                                        <i className='fa fa-home nav__icon'></i>
                                        <span className="nav__name">Notifications<sup>4</sup></span>
                                    </Link> */}
                                </div>
                                :
                                <div className="nav__items">
                                    <Link to={'/dashboard'} className="nav__link active">
                                        <i className='fa fa-home nav__icon'></i>
                                        <span className="nav__name">Account</span>
                                    </Link>
                                    <Link to={'/my/profile'} className="nav__link active">
                                        <i className='fa fa-home nav__icon'></i>
                                        <span className="nav__name">My Profile</span>
                                    </Link>
                                    <Link to={'/my/orders'} className="nav__link active">
                                        <i className='fa fa-home nav__icon'></i>
                                        <span className="nav__name">My Order</span>
                                    </Link>
                                    <Link to={'/my/services'} className="nav__link active">
                                        <i className='fa fa-home nav__icon'></i>
                                        <span className="nav__name">My Services</span>
                                    </Link>
                                    <Link to={'/my/favourites'} className="nav__link active">
                                        <i className='fa fa-home nav__icon'></i>
                                        <span className="nav__name">Favorites</span>
                                    </Link>
                                    <Link to={'/my/recentaly-view'} className="nav__link active">
                                        <i className='fa fa-home nav__icon'></i>
                                        <span className="nav__name">Recently Viewed</span>
                                    </Link>
                                    {/* <Link to={'/my/support'} className="nav__link active">
                                        <i className='fa fa-home nav__icon'></i>
                                        <span className="nav__name">Support</span>
                                    </Link> */}
                                    {/* <Link to={'/my/chat'} className="nav__link active">
                                        <i className='fa fa-home nav__icon'></i>
                                        <span className="nav__name">Chat <sup>2</sup></span>
                                    </Link> */}
                                    {/* <Link to={'/my/notifications'} className="nav__link active">
                                        <i className='fa fa-home nav__icon'></i>
                                        <span className="nav__name">Notifications<sup>4</sup></span>
                                    </Link> */}
                                    {/* <Link to={'/my/settings'} className="nav__link active">
                                        <i className='fa fa-home nav__icon'></i>
                                        <span className="nav__name">Settings</span>
                                    </Link> */}
                                </div>
                            }
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}
export default LeftMenu;