import ProductCard from "../product/ProductCard";
function UserUsedServices() {
    return (
        <>
            <div className="col-lg-9">
                <div className="d_boxs pb-1 mb-2 mb-md-4">
                    <div className=" pt-lg-0 dashbord-heading pb-2">
                        <h5 className="box_hadding">My Favourites
                        </h5>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-2">
                        <ProductCard />
                    </div>
                </div>
            </div>
        </>
    )
}
export default UserUsedServices;