import Header from "../common/Header";
import Footer from "../common/Footer";
import NewsletterFormController from "../common/NewsletterFormController";
// import ServiceFilter from "./ServiceFilter";
import Banner from "../common/Banner";
import { Link, NavLink, useHistory } from "react-router-dom";
import QuestionAnswer from "./QuestionAnswer";
import RatingReviews from "./RatingReviews";
import AboutSeller from "../vender/AboutSeller";
import ProductCompaire from "./ProductCompaire";
import ServiceCompaire from "../Service/ServiceCompaire";
import ProductCard from "./ProductCard";
import OwlCarousel from 'react-owl-carousel';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { UserServices } from "../../services";
import { toast } from "react-toastify";
import Loader from "../common/Loader";
import ImageGallery from 'react-image-gallery';
import { useDispatch } from "react-redux";
import { addProductInCard, userLoginPopup } from "../../store/actions/allAction";
import { loginUserId, displayDate } from "../../utils/index";
import secureStorage from "../../config/encrypt";
import { API_URL } from "../../config/constant";
import UserRecentalyView from "../user/UserRecentalyView";
import Rating from "../elements/Rating";
const images = [];
let initWishlist = { isView: false, isFavourite: false, isCart: false };
function ProduactDetails() {
    const disptch = useDispatch();
    const history = useHistory();
    const [productData, setProductData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [inCrat, setInCart] = useState(0);
    const { itemId } = useParams();
    const [userData, setUserData] = useState({});
    const [wishlistCartData, setWishlistCartData] = useState(initWishlist);
    useEffect(() => {
        if (itemId) {
            setProductData({});
            setWishlistCartData(initWishlist);
            getProductDetail();
        }
    }, [itemId])
    const addToViewList = (type) => {
        if (loginUserId()) {
            let obj = {
                userId: loginUserId(),
                type: type,
                productId: itemId
            }
            UserServices.addWishlist(obj).then((responce) => {
                let { message, data, status } = responce;
                if (status == 200) {
                    if (type == 'wishlist') setWishlistCartData((prev) => ({ ...prev, isFavourite: data.status }))
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            })
        }
        else if (type == 'wishlist') {
            disptch(userLoginPopup(true));
        }
    }
    const getProductDetail = () => {
        setIsLoading(true);
        UserServices.productDetail(itemId, loginUserId()).then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setProductData(data.product);
                console.log(data.product);
                setUserData(data.user);
                data.product && data.product.images && (data.product.images).map((proimg, index) => {
                    images.push({
                        original: proimg,
                        thumbnail: proimg
                    })
                });
                if (data && data.product) {
                    setWishlistCartData({ isView: data.product.isView, isFavourite: data.product.isFavourite, isCart: data.product.isCart })
                    if (!data.product.isView) addToViewList('view');
                }
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    const addToCard = (e) => {
        if (loginUserId()) {
            let obj = {
                userId: loginUserId(),
                carts: {
                    product: itemId,
                    units: 1
                }
            }
            UserServices.addIncart(obj).then((responce) => {
                let { message, data, status } = responce;
                if (status == 200) {
                    setInCart(1)
                    secureStorage.setItem(API_URL.COMMON_CART_STORE_KEY, data.carts);
                    setWishlistCartData((prev) => ({ ...prev, isCart: true }))
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            })
        }
        else {
            disptch(userLoginPopup(true));
        }
    }
    const buyNow = () => {
        if (loginUserId()) {
            if (history) history.push("/check-out/" + itemId)
        }
        else {
            disptch(userLoginPopup(true));
        }
    }
    return (
        <>
            <Header showFilterProduct={'prodetail'} fakeLogin={true} cart={inCrat} showMenu={2} header={'header2'} spaceType={'product'} />
            <Loader isLoading={isLoading} />
            <div className="">
                <div className="container">
                    <ul className="breadcrumb">
                        <li><a className="b" href="#">Back tobResults</a></li>
                        {/* <li>Computers & Tablets</li> */}
                        <li><Link to={'/products/list?cate=' + productData.categoryData}> {productData.categoryData || ''}</Link> </li>
                        <li><Link to={'/products/list?search=' + productData.brandData}>{productData.brandData || ''}</Link></li>
                    </ul>
                </div>
            </div>
            <section className="pb-65">
                <div className="container">
                    <div id="Overview" data-scroll-index="1">
                        <div className="row">
                            <div className="col-lg-6 pr-md-5">
                                <h4 className="mt-2">{productData.title || ""}</h4>
                                <div className="star_revi ccc pb-3">
                                    <Rating averageRating={productData.averageRating} />
                                    {productData.averageRating || 0}({productData.totalRating || 0} Reviews)
                                </div>
                                <div className="demo">
                                    <div className="item">
                                        <div className="clearfix">
                                            <ul id="image-gallery-e" className="gallery-list-unstyled#cS-hidden">
                                                <ImageGallery
                                                    thumbnailPosition={'bottom'}
                                                    showPlayButton={false}
                                                    showFullscreenButton={false}
                                                    showNav={false}
                                                    items={images}
                                                />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 ">
                                <>
                                    <div className="tr lcp_icon">
                                        <a onClick={(e) => addToViewList('wishlist')} className={wishlistCartData.isFavourite ? "" : "inactive"} href="#"><i className="fa fa-heart"></i></a>
                                        <a href="javascript:void(0)"><i className="fa fa-share-alt"></i></a>
                                        <a href="javascript:void(0)"><i className="fa fa-print"></i></a>
                                    </div>
                                    <h6>Price Match Guarantee</h6>
                                    <h3>{productData.currency} <b>{productData.originalPrice || ""}</b> <strike className="color3">{productData.currency} {productData.sellingPrice || ""}</strike></h3>
                                    {/* <div className="delivery_box">
                                        <img src={require("../../webroot/images/delivery.png").default} />
                                        <div className="delivery_right ml-0 ml-md-4">
                                            <h4>EXTENDED HOLIDAY RETURN PERIOD</h4>
                                            When purchased now through Jan 2, you can return this item anytime until Jan 16.
                                        </div>
                                    </div> */}
                                    {/* <a href=""> <span className="color3 mr-3">Hot offer</span> Microsoft 365 offer with device</a> */}
                                    <h4 className="mt-md-4">Get it in 13 days</h4>
                                    <div className="pickup_box">
                                        <img src={require("../../webroot/images/deliver.png").default} />
                                        <div className="pickup_right ml-2 ml-md-4">
                                            <div><b>Pickup: </b> Price Match Guarantee Price</div>
                                            <a>Price Match Guarantee Price Match Guarantee</a>
                                        </div>
                                    </div>
                                    <div className="pickup_box">
                                        <img src={require("../../webroot/images/shipped.png").default} />
                                        <div className="pickup_right ml-2 ml-md-4">
                                            <div><b>Shiping: </b> Price Match Guarantee Price</div>
                                            <a>Price Match Guarantee Price Match Guarantee</a>
                                        </div>
                                    </div>
                                    <div className="pt-3 row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            {wishlistCartData.isCart ?
                                                <Link className="btn btn-primary w100 ttu lh32" to={'/view-cart'}>
                                                    <img src={require("../../webroot/images/cart.png").default} alt="Banner" /> GO TO CART
                                                </Link> :
                                                <a onClick={(e) => addToCard(e)} className="btn btn-primary w100 ttu lh32" href="#">
                                                    <img src={require("../../webroot/images/cart.png").default} alt="Banner" /> ADD TO CART
                                                </a>
                                            }
                                        </div>
                                        <div className="col-sm-6">
                                            <button className="btn btn_man w100 ttu lh32" type="button" onClick={buyNow}>
                                                Buy Now
                                            </button>
                                        </div>
                                    </div>
                                    <div className="pt-md-5 row">
                                        <AboutSeller userProf={userData} />
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                    <div id="Description" data-scroll-index="2">
                        <h4 className="pt-3 mt-md-5">Description</h4>
                        <p>{productData.description || ""}</p>
                    </div>
                    <div id="Specifications" data-scroll-index="3">
                        <h5 className="mt-3">Specifications</h5>
                        <ul className="row specifications_list">
                            {productData && productData.specifications && productData.specifications.map((spc, ind) => {
                                return (
                                    <li key={spc.label}><span className="co1 d_block">{spc.label}</span> {spc.value}</li>
                                )
                            })}
                        </ul>
                        <h6 className="mt-3">General</h6>
                        <ul className="row specifications_list">
                            <li><span className="co1 d_block">Product Name</span>{productData.title || ""}</li>
                            <li><span className="co1 d_block">Brand</span> {productData.brandData || ""}</li>
                            <li><span className="co1 d_block">Casing Material</span> {productData.casingMaterial || "NA"}</li>
                            <li><span className="co1 d_block">Model Number</span> {productData.modelNumber || ""}</li>
                            <li><span className="co1 d_block">Year of Release</span> {displayDate((productData.releaseDate || ""), 'yyyy')}</li>
                            <li><span className="co1 d_block">Color Category</span>{productData.colorCategory || "NA"} </li>
                        </ul>
                    </div>
                </div>
            </section>
            {productData && productData.services && productData.services.length ?
                <section className="">
                    <div className="container">
                        <div className="row  align-items-end">
                            <div className="col-md-9">
                                <div className="heading-title tl">
                                    <h2>Related Services</h2>
                                    <p>Related services which may help you.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-2 pt-md-4">
                            {productData.services.map((ser, inx) => {
                                return (
                                    <div className="col-md-3 col-sm-6" key={'i_i_' + inx}>
                                        <Link to={`/services-detail/${ser.category ? ser.category.title : ''}/${ser._id}`} className="text-center popular_servicese">
                                            <img src={ser.thumbnailImage} alt="logo-design" />
                                            <h5>{ser.title || ''}</h5>
                                            <p>{ser.description ? ser.description.slice(0, 50) : ""}</p>
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section> : null
            }
            {productData.totalViewed > 0 ?
                <section className="">
                    <div className="container">
                        <div className="row  align-items-end">
                            <div className="col-md-9">
                                <div className="heading-title tl">
                                    <h2>Latest Viewed Items</h2>
                                    <p>Investigate the best in class items, across the board place.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className=" pt-lg-0 shop-heading pb-2">
                                    <a href="#" className="btn-link">{productData.totalViewed - 1 || 0} Items</a>
                                </div>
                            </div>
                        </div>
                        <UserRecentalyView product={itemId} formDashboard={true} />
                    </div>
                </section> : null}
            {/* <Banner img={""} /> */}
            <NewsletterFormController />
            <Footer />
        </>
    )
}
export default ProduactDetails;