import { Link } from "react-router-dom";
import { loginUserId } from "../../utils/index";
import { UserServices } from "../../services";
import { toast } from "react-toastify";
import { useState } from "react";
import { Image, Shimmer } from 'react-shimmer'
import { useDispatch } from "react-redux";
import { userLoginPopup } from "../../store/actions/allAction";

function ProductCard({ productData = {} }) {
    const disptch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const addToFavourites = (pro) => {
        if (loginUserId()) {
            let obj = {
                userId: loginUserId(),
                type: 'wishlist',
                productId: pro._id
            }
            UserServices.addWishlist(obj).then((responce) => {
                let { message, data, status } = responce;
                if (status == 200) {
                    productData.isFavourite = data.status;
                    toast.success(data.status ? 'Product added your wishlist' : 'Product removed from your wishlist');
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            })
        }
        else {
            disptch(userLoginPopup(true));
        }
    }
    return (
        <div className="shop-item mb-3">
            {/* <Image className="mx-auto item-img"
                src={productData.thumbnail}
                fallback={<Shimmer width={800} height={600} />}
            /> */}
            <Link to={`/product/${(productData.brandData ? productData.brandData.slug : 'ict')}/${productData._id}`} className=" img-height">
                <img className="mx-auto item-img" src={productData.thumbnail} alt="Star" />
            </Link>
            {/* <Link to={`/product/${(productData.brandData ? productData.brandData.slug : 'ict')}/${productData._id}`} className="add-cart"><img src={require("../../webroot/images/shopping-cart.png").default} alt="Cart" /></Link> */}
            <button onClick={(e) => addToFavourites(productData)} className={"add-cart bg-grey " + (productData.isFavourite ? 'favourites' : '')}><i className="fa fa-heart"></i></button>
            <div className="item-body">
                <h4><Link to={`/product/${(productData.brandData ? productData.brandData.slug : 'ict')}/${productData._id}`}>{productData.title || ""}</Link></h4>
                <div className="shop-footer">
                    <span className="price">{productData.currency || ""} <strong>{productData.sellingPrice || ""}</strong><del>{productData.currency || ""} {productData.originalPrice || ""}</del></span>
                    <span className="rating-box"><span>
                        <img src={require("../../webroot/images/star.png").default} alt="Star" />
                    </span> {productData.averageRating || 0}</span>
                </div>
            </div>
        </div>
    )
}
export default ProductCard;