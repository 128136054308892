import Header from "../common/Header";
import Footer from "../common/Footer";
import Loader from "../common/Loader";
import UserSellerNotification from "../common/UserSellerNotification";
import { userRole } from "../../utils";
import VenderDashboardComponent from "../dashboard/VenderDashboardComponent";
import UserDashboardComponent from "../dashboard/UserDashboardComponent";
import { useEffect, useState } from 'react';
import { UserServices } from "../../services";
import secureStorage from "../../config/encrypt";
import { API_URL } from "../../config/constant";
import { toast } from "react-toastify";
import TopRiben from "./TopRiben";
import LeftMenu from "./LeftMenu";
import { Switch, Route, useLocation, useParams } from "react-router-dom";
import VenderTopRebben from "../vender/VenderTopRebben";
import AccountSetting from "./AccountSetting";
import Notification from "./Notification";
import VenderProfile from "../vender/VenderProfile";
import ProfileCard from "../vender/ProfileCard";
import UserMyOrders from "../user/UserMyOrders"
import UserProfile from "../user/UserProfile";

import UserFavourites from "../user/UserFavourites";
import UserRecentalyView from "../user/UserRecentalyView";
import UserUsedServices from "../user/UserUsedServices";
import ManageProduct from "../product/ManageProduct";
import VenderMyProduct from "../vender/VenderMyProduct";
import VendorMyServices from "../vender/VendorMyServices";
import VendorMySolutions from "../vender/VendorMySolutions";
import VendorMyOrders from "../vender/VendorMyOrders";
import ManageService from "../Service/ManageService";
import VenderRole from "../vender/RoleMember/VenderRole";
import VenderTeamMembers from "../vender/RoleMember/VenderTeamMembers";
import ProductOrderDetails from "../product/ProductOrderDetails";

function Dashboard() {
    const [userData, setUserData] = useState({});
    const [userAnalysis, setUserAnalysis] = useState({});
    const [userID, setUserID] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const { page_id } = useParams();
    useEffect(() => {
        let user = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY);
        user = (user && "user" in user) ? user.user : user;
        if (user) {
            getUserProfile(user._id, user.role);
            setUserID(user._id);
        }
    }, [])
    const getUserProfile = async (userId, role) => {
        UserServices.userDetail(userId, role).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setUserData(data.user);
                setUserAnalysis(data.analysis);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsLoading(false);
        })
    }
    const updateProfileAvatar = (e) => {
        const formData = new FormData();
        formData.append('avatar', e.target.files[0]);
        formData.append('id', userID);
        UserServices.updateUser(formData).then((responce) => {
            let { message, statusCode, data } = responce;
            if (statusCode == 200) {
                secureStorage.setItem(API_URL.COMMON_LOGIN_STORE_KEY, data);
                toast.success(message);
            }
            else {
                toast.error(message);
            }
        }).catch((error) => {
            toast.error(error);
        })
    }
    return (
        <>
            <Header header={'header2'} spaceType={'dashbord'} />
            <Loader isLoading={isLoading} />
            <TopRiben profile={userData} />
            <section className="dashbord_in dashbord_in_man">
                <LeftMenu menuFor={userRole()} />
                <div className="dashbord_right">
                    {(userRole() == 'vendor' && (location.pathname).replace('/', '') == 'dashboard') ? <VenderTopRebben currency={userData.companyInfo && userData.companyInfo.currency || ""} analysis={userAnalysis} /> : null}
                    <div className="row">
                        <div className="col-lg-3">
                            {['orders-detail', 'notification', 'notifications', 'favourites', 'recentaly-view', 'profile', 'team-role', 'team-roles', 'teams', 'team'].includes(page_id) ?
                                <div className="d_boxs mb-2 mb-md-4 pl-md-4 pr-md-4">
                                    <ProfileCard profile={userData} onChange={updateProfileAvatar} />
                                </div> : null
                            }
                            {/* {!['favourites', 'orders-detail', 'manage-product', 'notification', 'notifications', 'orders', 'products', 'solutions', 'services', 'order', 'update-product', 'manage-service', 'update-service'].includes(page_id) ?
                                <div className="d_boxs mb-2 mb-md-4">
                                    <Notification />
                                </div> 
                                : null
                            } */}
                            {/* <div className="d_boxs mb-2 mb-md-4">
                                <AccountSetting profile={userData} />
                            </div> */}
                            {/* <div className="d_boxs mb-2 mb-md-4">
                                <div className=" pt-lg-0 dashbord-heading pb-2">
                                    <h5 className="box_hadding">Quick Links </h5>
                                </div>
                                <div className="quick_links">
                                    <ul>
                                        <li><a href="#" className="">Cancellation</a></li>
                                        <li><a href="#" className=""> Plans & Subscriptions</a></li>
                                        <li><a href="#" className=""> Recently Viewed</a></li>
                                        <li><a href="#" className=""> Help and support</a></li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                        <Switch>
                            <Route path={'/dashboard'}>
                                {userRole() == 'vendor' ?
                                    <VenderMyProduct isSmall={true} userData={userData} userId={userID} /> :
                                    <UserDashboardComponent userData={userData} />
                                }
                            </Route>
                            <Route path={'/my/profile'}>
                                <VenderProfile userData={userData} userId={userID} />
                            </Route>
                            <Route path={['/my/team-roles', '/my/team-role']}>
                                {userRole() == 'vendor' ?
                                    <VenderRole userData={userData} userId={userID} /> :
                                    <UserDashboardComponent userData={userData} />
                                }
                            </Route>
                            <Route path={['/my/teams', '/my/team']}>
                                {userRole() == 'vendor' ?
                                    <VenderTeamMembers userData={userData} userId={userID} /> :
                                    <UserDashboardComponent userData={userData} />
                                }
                            </Route>
                            <Route path={'/my/orders'}>
                                {userRole() == 'vendor' ?
                                    <VendorMyOrders userData={userData} orderStatus={'delivered'} /> :
                                    <UserMyOrders title={'Orders'} userData={userData} />
                                }
                            </Route>
                            <Route path={'/my/recentaly-view'}>
                                {userRole() == 'vendor' ?
                                    <VenderDashboardComponent userData={userData} /> :
                                    <UserRecentalyView userData={userData} />
                                }
                            </Route>
                            <Route path={'/my/favourites'}>
                                {userRole() == 'vendor' ?
                                    <VenderDashboardComponent userData={userData} /> :
                                    <UserFavourites userData={userData} />
                                }
                            </Route>
                            <Route path={'/my/order/pending'}>
                                {userRole() == 'vendor' ?
                                    <VendorMyOrders userData={userData} orderStatus={'ordered'} /> :
                                    <UserMyOrders userData={userData} />
                                }
                            </Route>
                            <Route path={'/my/order/returned'}>
                                {userRole() == 'vendor' ?
                                    <VendorMyOrders userData={userData} orderStatus={'return'} /> :
                                    <UserMyOrders userData={userData} />
                                }
                            </Route>
                            <Route path={'/my/order/cancel'}>
                                {userRole() == 'vendor' ?
                                    <VendorMyOrders userData={userData} orderStatus={'cancel'} /> :
                                    <UserMyOrders userData={userData} />
                                }
                            </Route>
                            <Route path={'/my/products'}>
                                {userRole() == 'vendor' ?
                                    <VenderMyProduct userData={userData} userId={userID} /> :
                                    <UserMyOrders userData={userData} />
                                }
                            </Route>
                            <Route path={'/my/manage-product'}>
                                {userRole() == 'vendor' ?
                                    <ManageProduct productType={'add'} userData={userData} userId={userID} /> :
                                    <UserMyOrders userData={userData} />
                                }
                            </Route>
                            <Route path={'/my/update-product/:productId'}>
                                {userRole() == 'vendor' ?
                                    <ManageProduct productType={'edit'} userData={userData} userId={userID} /> :
                                    <UserMyOrders userData={userData} />
                                }
                            </Route>
                            <Route path={'/my/services'}>
                                {userRole() == 'vendor' ?
                                    <VendorMyServices userData={userData} /> :
                                    <UserMyOrders title={'Services'} userData={userData} />
                                }
                            </Route>
                            <Route path={'/my/update-service/:productId'}>
                                {userRole() == 'vendor' ?
                                    <ManageService productType={'edit'} userData={userData} userId={userID} /> :
                                    <UserMyOrders userData={userData} />
                                }
                            </Route>
                            <Route path={'/my/solutions'}>
                                {userRole() == 'vendor' ?
                                    <VendorMySolutions userData={userData} /> :
                                    <UserMyOrders userData={userData} />
                                }
                            </Route>
                            <Route path={'/my/manage-service'}>
                                {userRole() == 'vendor' ?
                                    <ManageService productType={'add'} userData={userData} userId={userID} /> :
                                    <UserMyOrders userData={userData} />
                                }
                            </Route>
                            <Route path={'/my/store'}>
                                {userRole() == 'vendor' ?
                                    <VenderDashboardComponent userData={userData} /> :
                                    <UserMyOrders userData={userData} />
                                }
                            </Route>
                            <Route path={['/my/notifications', '/my/notification']}>
                                <div className="col-lg-9 d_boxs mb-2 mb-md-4">
                                    <Notification />
                                </div>
                            </Route>
                            <Route path={'/my/orders-detail'}>
                                <ProductOrderDetails />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default Dashboard;