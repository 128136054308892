import { useEffect, useRef, useState } from "react";
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import { UserServices } from "../../../services";
import Loader from "../../common/Loader";
import { loginUserId } from "../../../utils/index";

const ManageRole = (props) => {
    const { register, handleSubmit, errors, control, reset } = useForm(props.formObj);
    const crossRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        reset(props.formObj);
    }, [props.formObj])
    const closeModalBox = () => {
        crossRef.current.click();
    }
    const onSubmit = (data) => {
        setIsLoading(true);
        let dataObj = {
            roleId: data.roleId,
            vendor: loginUserId(),
            name: data.name,
            description: data.description,
            permissions: JSON.stringify({ user: data.user, order: data.order, product: data.product })
        }
        let addUserRole = 'addUserRole';
        if (['', null, 'null', undefined, 'undefined'].includes(dataObj.roleId)) {
            delete dataObj.roleId;
            addUserRole = 'addUserRole';
        }
        else {
            addUserRole = 'updateUserRole';
        }
        UserServices[addUserRole](dataObj).then((responce) => {
            let { message, statusCode } = responce;
            if (statusCode == 200) {
                props.onSuccess(true);
                closeModalBox();
                toast.success(message);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    };

    return (
        <div className={`modal fade ${props.show ? 'show' : ''}`} id="roleMODAL" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <div><h4><i className="fa fa-th-large system-color"></i> Role & Permission </h4></div>
                        <button type="button" onClick={closeModalBox} className="close" ref={crossRef} data-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body">
                        <Loader isLoading={isLoading} />
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input type="hidden" {...register('roleId')} className="form-control2" />
                            <div className="row">
                                <div className="form-group col-lg-12">
                                    <label className="required">Name</label>
                                    <input type="text" {...register('name', { required: true })} className="form-control2" />
                                </div>
                                <div className="form-group col-lg-12">
                                    <label className="required">Description</label>
                                    <input {...register('description', { required: true })} className="form-control2" />
                                </div>
                            </div>
                            <h4>Permission</h4>
                            <div className="row">
                                <div className="col-sm-3 form-group">
                                    <label className="">User</label>
                                    <div className="col-form-label-ict">
                                        <div className="form-check">
                                            <input className="form-check-input" {...register('user.list')} id="user.list" type="checkbox" />
                                            <label className="form-check-label" htmlFor="user.list"> List </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" {...register('user.add')} id="user.add" type="checkbox" />
                                            <label className="form-check-label" htmlFor="user.add"> Add </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" {...register('user.update')} id="user.update" type="checkbox" />
                                            <label className="form-check-label" htmlFor="user.update"> Update </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" {...register('user.delete')} id="user.delete" type="checkbox" />
                                            <label className="form-check-label" htmlFor="user.delete"> Delete </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 form-group">
                                    <label className="">Order</label>
                                    <div className="col-form-label-ict">
                                        <div className="form-check">
                                            <input className="form-check-input" {...register('order.list')} id="order.list" type="checkbox" />
                                            <label className="form-check-label" htmlFor="order.list"> List </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" {...register('order.update')} id="order.update" type="checkbox" />
                                            <label className="form-check-label" htmlFor="order.update"> Update </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" {...register('order.delete')} id="order.delete" type="checkbox" />
                                            <label className="form-check-label" htmlFor="order.delete"> Reject </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 form-group">
                                    <label className="">Product</label>
                                    <div className="col-form-label-ict">
                                        <div className="form-check">
                                            <input className="form-check-input" {...register('product.list')} id="product.list" type="checkbox" />
                                            <label className="form-check-label" htmlFor="product.list"> List </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" {...register('product.add')} id="product.add" type="checkbox" />
                                            <label className="form-check-label" htmlFor="product.add"> Add </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" {...register('product.update')} id="product.update" type="checkbox" />
                                            <label className="form-check-label" htmlFor="product.update"> Update </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" {...register('product.delete')} id="product.delete" type="checkbox" />
                                            <label className="form-check-label" htmlFor="product.delete"> Delete </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 form-group">
                                    <label className="">Service</label>
                                    <div className="col-form-label-ict">
                                        <div className="form-check">
                                            <input className="form-check-input" {...register('service.list')} id="service.list" type="checkbox" />
                                            <label className="form-check-label" htmlFor="service.list"> List </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" {...register('service.add')} id="service.add" type="checkbox" />
                                            <label className="form-check-label" htmlFor="service.add"> Add </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" {...register('service.update')} id="service.update" type="checkbox" />
                                            <label className="form-check-label" htmlFor="service.update"> Update </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" {...register('service.delete')} id="service.delete" type="checkbox" />
                                            <label className="form-check-label" htmlFor="service.delete"> Delete </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary w100">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ManageRole;