import HomeComponent from "../view/Home/HomeComponent";
import Logout from "../view/Home/Logout";
import SignupComponent from "../view/Home/SignupComponent";
import ProductListComponent from "../view/product/ProductListComponent";
import ProduactDetails from "../view/product/ProduactDetails";
import ProductAllListComponent from "../view/product/ProductAllListComponent"
import ServicesComponent from "../view/Service/ServicesComponent";
import ServiceListingComponent from "../view/Service/ServiceListingComponent";
import ServiceDetailsComponent from "../view/Service/ServiceDetailsComponent";
import SolutionComponent from "../view/Service/SolutionComponent";
import VenderRgistrationComponent from "../view/vender/VenderRgistrationComponent";
import ActivateUser from "../view/vender/ActivateUser";
import ThankYou from "../view/vender/ThankYou";
import UpdateProfileCompCateBrand from "../view/vender/UpdateProfileCompCateBrand";

// import Chat from "../view/centralized/Chat";
// import MyFavourites from "../view/centralized/MyFavourites";
// import MyOrders from "../view/centralized/MyOrders";
// import MyServices from "../view/centralized/MyServices";
// import MyProfile from "../view/centralized/MyProfile";
// import Notifications from "../view/centralized/Notifications";
// import RecentlyViewed from "../view/centralized/RecentlyViewed";
// import Supports from "../view/centralized/Supports";
import Dashboard from "../view/dashboard/Dashboard";
// import Settings from "../view/centralized/Settings";

import ContentComponent from "../view/our-company/ContentComponent";
import FaqsComponent from "../view/Home/FaqsComponent";
import ContactUs from "../view/Home/ContactUs";
import { logout } from "../utils";
import ViewCart from "../view/product/ViewCart";
import ProductVendorProfile from "../view/vender/ProductVendorProfile";
import ViewCartCheckOutService from "../view/Service/ViewCartCheckOutService";
// import UserProfile from "../view/user/UserProfile";
// import HowItWorks from "../view/our-company/HowItWorks";
// import BuyingonICT from "../view/our-company/BuyingonICT";
// import FindPartner from "../view/our-company/FindPartner";
// import HelpAndSupport from "../view/our-company/HelpAndSupport";
// import Investor from "../view/our-company/Investor";
// import InvestorRelations from "../view/our-company/InvestorRelations";
// import PrivacyPolicy from "../view/our-company/PrivacyPolicy";
// import Security from "../view/our-company/Security";
// import SellingonICT from "../view/our-company/SellingonICT";
// import ServiceProviders from "../view/our-company/ServiceProviders";
// import TrustAndSafety from "../view/our-company/TrustAndSafety";
// import TermsOfService from "../view/our-company/TermsOfService";

const routes = [
  {
    path: '/',
    exact: true,
    auth: false,
    component: HomeComponent
  },
  {
    path: '/home',
    exact: true,
    auth: false,
    component: HomeComponent
  },
  {
    path: '/dashboard',
    exact: true,
    auth: true,
    component: Dashboard
  },
  {
    path: '/my/:page_id',
    exact: true,
    auth: true,
    component: Dashboard
  },
  {
    path: '/my/:page_id/:query',
    exact: true,
    auth: true,
    component: Dashboard
  },
  {
    path: '/product/:itemName/:itemId',
    exact: true,
    auth: false,
    component: ProduactDetails
  },
  {
    path: '/signup',
    exact: true,
    auth: false,
    component: SignupComponent
  },
  {
    path: '/products',
    exact: true,
    auth: false,
    component: ProductListComponent
  },
  {
    path: '/view-cart',
    exact: true,
    auth: true,
    component: ViewCart
  },
  {
    path: '/check-out/:productId',
    exact: true,
    auth: true,
    component: ViewCart
  },
  {
    path: '/products/list',
    exact: true,
    auth: false,
    component: ProductAllListComponent
  },
  {
    path: '/services',
    exact: true,
    auth: false,
    component: ServicesComponent
  },
  {
    path: '/services-listing',
    exact: true,
    auth: false,
    component: ServiceListingComponent
  },
  {
    path: '/services-detail/:category/:serviceId',
    exact: true,
    auth: false,
    component: ServiceDetailsComponent
  },
  {
    path: '/services-provider-listing',
    exact: true,
    auth: false,
    component: ServicesComponent
  },
  {
    path: '/services-provider-details',
    exact: true,
    auth: false,
    component: ServicesComponent
  },
  {
    path: '/solutions',
    exact: true,
    auth: false,
    component: SolutionComponent
  },
  {
    path: '/become-partner',
    exact: true,
    auth: false,
    component: VenderRgistrationComponent
  },
  {
    path: '/user/activate/:userId',
    exact: true,
    auth: false,
    component: ActivateUser
  },
  {
    path: '/user/thank-you',
    exact: true,
    auth: false,
    component: ThankYou
  },
  {
    path: '/user/update-profile',
    exact: true,
    auth: false,
    component: UpdateProfileCompCateBrand
  },
  {
    path: "/aboutus",
    exact: true,
    auth: false,
    component: ContentComponent
  },
  {
    path: "/how-it-works",
    exact: true,
    auth: false,
    component: ContentComponent
  },

  {
    path: "/security",
    exact: true,
    auth: false,
    component: ContentComponent
  },
  {
    path: "/investor",
    exact: true,
    auth: false,
    component: ContentComponent
  },
  {
    path: "/privacy-policy",
    exact: true,
    auth: false,
    component: ContentComponent
  },
  {
    path: "/terms-service",
    exact: true,
    auth: false,
    component: ContentComponent
  },
  {
    path: "/help-support",
    exact: true,
    auth: false,
    component: ContentComponent
  },
  {
    path: "/trust-safety",
    exact: true,
    auth: false,
    component: ContentComponent
  },
  {
    path: "/sellingonict",
    exact: true,
    auth: false,
    component: ContentComponent
  },
  {
    path: "/buyingonict",
    exact: true,
    auth: false,
    component: ContentComponent
  },
  {
    path: "/findpartner",
    exact: true,
    auth: false,
    component: ContentComponent
  },
  {
    path: "/service-provider",
    exact: true,
    auth: false,
    component: ContentComponent
  },
  {
    path: "/investor-relations",
    exact: true,
    auth: false,
    component: ContentComponent
  },
  {
    path: "/faqs",
    exact: true,
    auth: false,
    component: FaqsComponent
  },
  {
    path: "/contact-us",
    exact: true,
    auth: false,
    component: ContactUs
  },
  {
    path: "/logout",
    exact: true,
    auth: true,
    component: Logout
  },
  {
    path: "/seller-profile/:vendorId",
    exact: true,
    auth: false,
    component: ProductVendorProfile
  },
  {
    path: '/service-check-out/:productId',
    exact: true,
    auth: true,
    component: ViewCartCheckOutService
  },
];
export default routes;