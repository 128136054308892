import InterceptorService from './InterceptorService'
export default {
    ...InterceptorService,
    countryCode() {
        return this.externalGet(`https://ipapi.co/json/`);
    },
    addUser(body) {
        return this.post(`user/add`, body);
    },
    businessList() {
        return this.get(`business/list`);
    },
    bannerList(displayAt) {
        return this.get(`banner/list?displayAt=${displayAt}&status=true`);
    },
    countryList() {
        return this.get(`user/country`);
    },
    currencyList() {
        return this.get(`user/currency`);
    },
    loginUser(body) {
        return this.post(`user/login`, body);
    },
    forgotPassword(body) {
        return this.post(`user/forgot/password`, body);
    },
    sellerProfile(body) {
        return this.post(`user/seller/profile`, body);
    },
    userDetail(userId, role = 'user') {
        role = role == 'user' ? "" : "vendor/"
        return this.get(`user/${role}detail?userId=${userId}`);
    },
    activateUser(body) {
        return this.post(`user/verify`, body);
    },
    updateUser(body) {
        return this.post(`user/update`, body);
    },
    changePassword(body) {
        return this.post(`user/update/password`, body);
    },
    contentDetail(body) {
        return this.post(`content/detail`, body);
    },
    updateCompanyinfo(body) {
        return this.post(`user/companyinfo/update`, body);
    },
    verificationDocsUpdate(body) {
        return this.post(`user/verification-docs/update`, body);
    },
    brandDropdownList() {
        return this.get(`brand/dropdown/list`);
    },
    categoriesDropDownList(type) {
        return this.get(`categories/dropdown/list?type=${type}`);
    },
    regionalPresenceUpdate(body) {
        return this.post(`user/regional-presence/update`, body);
    },
    brandPartnershipUpdate(body) {
        return this.post(`user/brand-partnership/update`, body);
    },
    verifyOTP(body) {
        return this.post(`user/verify/otp`, body);
    },
    resendOTP(body) {
        return this.post(`user/otp`, body);
    },
    faqsList() {
        return this.get(`faq/list?status=true`);
    },
    contactADD(body) {
        return this.post(`contact/add`, body);
    },
    manufacturerDropDownList() {
        return this.get(`manufacturer/dropdown/list`);
    },
    addProduct(body) {
        return this.post(`product/add`, body);
    },
    editProduct(body) {
        return this.post(`product/edit`, body);
    },
    updateProduct(body) {
        return this.post(`product/update`, body);
    },
    updateProductImages(body) {
        return this.post(`product/update/images`, body);
    },
    myProduct(body) {
        return this.post(`product/my/list`, body);
    },
    productAllList(body) {
        return this.post(`product/all/list`, body);
    },
    productDetail(productId, userId = null) {
        return this.get(`product/detail?productId=${productId}&userId=${userId}`);
    },
    addIncart(body) {
        return this.post(`user/add/incart`, body);
    },
    removeIncart(body) {
        return this.post(`user/remove/cart`, body);
    },
    getIncart(body) {
        return this.post(`user/get/incart`, body);
    },
    getUserCart(body) {
        return this.post(`user/cart`, body);
    },
    reviewProductList(body) {
        return this.post(`review/product/list`, body);
    },
    buyProduct(body) {
        return this.post(`buyproduct/add`, body);
    },
    buyProductList(body) {
        return this.post(`buyproduct/list`, body);
    },
    addWishlist(body) {
        return this.post(`wishlist/add`, body);
    },
    addShippingAddress(body) {
        return this.post(`address/add`, body);
    },
    shippingAddressList(body) {
        return this.post(`address/list`, body);
    },
    changeStatus(body) {
        return this.post(`buyproduct/change-status`, body);
    },
    importExcelFile(body, lurl) {
        return this.post(`import/${lurl}`, body);
    },
    addService(body) {
        return this.post(`service/add`, body);
    },
    editService(body) {
        return this.post(`service/edit`, body);
    },
    updateService(body) {
        return this.post(`service/update`, body);
    },
    updateServiceImages(body) {
        return this.post(`service/update/images`, body);
    },
    serviceList(body) {
        return this.post(`service/list`, body);
    },
    serviceAllList(body) {
        return this.post(`service/list/all`, body);
    },
    serviceDetails(body) {
        return this.post(`service/detail`, body);
    },
    serviceUserCart(body) {
        return this.post(`service/cart`, body);
    },
    addUserRole(body) {
        return this.post(`user/role/add`, body);
    },
    listUserRole(body) {
        return this.post(`user/role/list`, body);
    },
    updateUserRole(body) {
        return this.post(`user/role/update`, body);
    },
    roleDropdownList(body) {
        return this.post(`user/role/dd/list`, body);
    },
    getAllTeamMembers(body) {
        return this.post(`user/team-members`, body);
    },
    pramotionalBanner(body) {
        return this.post(`banner/pramotion/detail`, body);
    },
    feturesProductAllList(body) {
        return this.post(`product/all/features-list`, body);
    },
    buyProductOrderDetail(id, type) {
        return this.get(`buyproduct/order-details/${id}?type=${type}`);
    },
    buyproductChangeStatus(body) {
        return this.post(`buyproduct/change-status`, body);
    },
    newsletterAddSubscriber(body) {
        return this.post(`newsletter/add-subscriber`, body);
    },
}