import { useEffect, useRef, useState } from "react";
import { UserServices } from "../../../services";
import ManageTeam from "./ManageTeam";
import { loginUserId, userPermission } from "../../../utils/index";
import { toast } from "react-toastify";
let formObj = {
    "userRole": null,
    "firstName": "",
    "lastName": "",
    "email": "",
    "mobile": "",
    "dialCode": "",
    "iso2": "in",
    "dob": "",
    "postalAddress": "",
    "ciso2": "IN",
    "country": "India",
    "state": "",
    "city": "",
    "postcode": "",
    "vendorDescription": ""
}
const VenderTeamMembers = () => {
    const [teamMembers, setTeamMembers] = useState([]);
    const [total, setTotal] = useState(0);
    let inputElement = useRef();
    useEffect(() => {
        getAllTeamMembers();
    }, [])
    const getAllTeamMembers = () => {
        UserServices.getAllTeamMembers({ vendorId: loginUserId() }).then((responce) => {
            let { data, message, status } = responce;
            if (responce.status == 200 || status == 200) {
                setTeamMembers(data.list);
                setTotal(data.count);
            }
            else {
                toast.error(message)
            }
        })
    }
    const onSuccess = (status) => {
        window.location.reload();
    }
    return (
        <div className="col-lg-9">
            <div className="d_boxs  mb-2 mb-md-4">
                <div className=" pt-lg-0 dashbord-heading pb-2 hadding_border">
                    <h3 className="co8 pb-md-0">My Team Members ({total})
                    </h3>
                    {userPermission('user', 'add') || userPermission('user', 'edit') ? <>
                        <div className="d_flex search_input mb-2 mb-md-2 mla">
                            <a ref={input => inputElement = input} data-toggle="modal" data-target="#userTeamMODAL" className="btn btn-primary btn-small"><i className="fa fa-plus-circle mr-2"></i> Add Team Member</a>
                        </div>
                        <ManageTeam formObj={formObj} onSuccess={onSuccess} show={true} />
                    </> : null
                    }
                </div>
                <div className="row row2">
                    {teamMembers && teamMembers.length ?
                        teamMembers.map((t, i) => {
                            return (
                                <div className="col-lg-3 col-md-6 col-sm-6 mb-2 mb-md-4" key={i + '-' + i}>
                                    <div className="team_box shadow">
                                        <img className="team_user" src={t.profileImage} alt="vender" />
                                        <h5 className="p-0">{t.firstName + ' ' + t.lastName}</h5>
                                        <span className="team_post">{t.userRole && t.userRole.name}</span>
                                        {/* <span className="team_admin color_man">Admin</span> */}
                                        <div className="team_contact_d ">
                                            <span className="mb-1"> <i className="fa fa-envelope"></i>{t.email}</span>
                                            <span> <i className="fa fa-phone"></i>{t.dialCode}-{t.mobile}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : null
                    }

                </div>
            </div>
        </div>
    )
}
export default VenderTeamMembers;