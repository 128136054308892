import Header from "../common/Header";
import Footer from "../common/Footer";
import NewsletterFormController from "../common/NewsletterFormController";
import ServiceFilter from "./ServiceFilter";
import Banner from "../common/Banner";
import { Link } from "react-router-dom";
import AboutSeller from "../vender/AboutSeller";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../common/Loader";
import ImageGallery from 'react-image-gallery';
import { useParams } from 'react-router-dom';
import { UserServices } from "../../services";
import RecentalyViewedService from "./RecentalyViewedService";
import { loginUserId, displayDate } from "../../utils/index";
import ServiceCard from "./ServiceCard";
import Rating from "../elements/Rating";

var images = [];
function ServiceDetailsComponent() {
    const [userData, setUserData] = useState({});
    const [serviceData, setServiceData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [totalServices, setTotalServices] = useState(0);
    const { serviceId } = useParams();
    useEffect(() => {
        getServiceDetail();
        getAllServices();
        images = [];
    }, []);
    const getAllServices = () => {
        UserServices.serviceAllList({ type: 'other', userId: loginUserId(), productId: serviceId }).then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setServices(data.list);
                setTotalServices(data.total);
            }
        })
    }
    const addToViewList = (type) => {
        if (loginUserId()) {
            let obj = {
                userId: loginUserId(),
                type: type,
                productId: serviceId,
                fr: 'service'
            }
            UserServices.addWishlist(obj).then((responce) => {
                let { message, data, status } = responce;
                if (status == 200) {
                    // if (type == 'wishlist') setWishlistCartData((prev) => ({ ...prev, isFavourite: data.status }))
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            })
        }
        else if (type == 'wishlist') {
            // disptch(userLoginPopup(true));
        }
    }
    const getServiceDetail = () => {
        setIsLoading(true);
        UserServices.serviceDetails({ serviceId: serviceId, userId: loginUserId() }).then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setServiceData(data.service);
                setUserData(data.user);
                data.service && (data.service.images).map((proimg, index) => {
                    images.push({
                        original: proimg,
                        thumbnail: proimg
                    })
                });
                if (data && data.service) {
                    // setWishlistCartData({ isView: data.product.isView, isFavourite: data.product.isFavourite, isCart: data.product.isCart })
                    if (!data.service.isView) addToViewList('view');
                }
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    return (
        <>
            <Header showFilterProduct={'servicedetail'} fakeLogin={true} cart={0} showMenu={2} header={'header2'} spaceType={'product'} />
            <div className="container">
                <ul className="breadcrumb">
                    <li><a className="b" href="#">Back to Results</a></li>
                    <li><a className="" href="#">{serviceData.category ? serviceData.category.title : ""}</a></li>
                    <li><a className="" href="#">{serviceData.subcategory ? serviceData.subcategory.title : ""}</a></li>
                </ul>
            </div>
            <section className="pb-65">
                <div className="container">
                    <div id="Overview" data-scroll-index="1">
                        <div className="row">
                            <div className="col-lg-6 pr-md-5">
                                <h4 className="mt-2">{serviceData.title}</h4>
                                <div className="star_revi ccc pb-3">
                                    <Rating averageRating={serviceData.averageRating} />
                                    {serviceData.averageRating || 0}({serviceData.totalRating || 0} Reviews)
                                </div>
                                <div className="demo">
                                    <div className="item">
                                        <div className="clearfix">
                                            <ul id="image-gallery-e" className="gallery-list-unstyled#cS-hidden">
                                                <ImageGallery
                                                    thumbnailPosition={'bottom'}
                                                    showPlayButton={false}
                                                    showFullscreenButton={false}
                                                    showNav={false}
                                                    items={images}
                                                />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 ">
                                <div className="border_box p-md-4 h100">
                                    {/* <ul className="nav psp_tab mb-4">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#Basic">Basic</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#Standard">Standard</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#Premlum">Premlum</a>
                                        </li>
                                    </ul> */}
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="Basic">
                                            <div className="delivery_box">
                                                <div className="delivery_right ">
                                                    <h5>BASIC Package - Popular</h5>
                                                    <span className="f13">{serviceData.conditions}</span>
                                                </div>
                                                <div className="premlum_right tr ml-0 ml-md-4">
                                                    {serviceData.basic ? serviceData.basic.currency : ""} <strong>{serviceData.basic ? serviceData.basic.sellingPrice : ""}</strong>
                                                </div>
                                            </div>
                                            <div className="pt-1 row mb-md-2">
                                                <div className="col-md-4 mb-2">
                                                    <i className="fa fa-clock-o mr-1"></i>
                                                    {serviceData.basic ? serviceData.basic.estimate : ""} {serviceData.basic ? serviceData.basic.estimateType : ""} Service
                                                </div>
                                                <div className="col-md-4">
                                                    Unlimited Revisions
                                                </div>
                                            </div>
                                            {/* <div className="panel-group accordion-section ">
                                                <div className="panel panel-default border-0 mb-2">
                                                    <div className="panel-heading  pt-2" role="tab" id="heading0">
                                                        <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion"
                                                            href="#collapse0" aria-expanded="true" aria-controls="collapse0">
                                                            What’s Included
                                                        </a>
                                                    </div>
                                                    <div id="collapse0" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading0">
                                                        <div className="panel-body pt-2 mb-2">
                                                            <p>A:Answer This Ryzen 5 5500u is slightly better than the Intel i5-1135G7 mobile CPU. It has
                                                                6 cores 12 threads compared to the i5 with 4 cores and 8 threads. The i5 is slightly better
                                                                in single thread performance but the Ryzen 5 has a bigger advantage in multi-thread and
                                                                integrated GPU.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="tab-pane" id="Standard"></div>
                                        <div className="tab-pane " id="Premlum"></div>
                                    </div>
                                    <div className="pt-3 mt-md-4 row">
                                        {/* <div className="col-sm-6 mb-3 mb-sm-0">
                                            <a className="btn btn-primary w100 ttu lh32" href="#">
                                                <img src="images/cart.png" alt="Banner" /> ADD TO CART
                                            </a>
                                        </div> */}
                                        <div className="col-sm-6">
                                            <Link className="btn btn_man w100 ttu lh32" to={"/service-check-out/" + serviceId}>
                                                Buy Now
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="mt-3 mt-md-4">
                                        <AboutSeller userProf={userData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="Description" data-scroll-index="2">
                        <h4 className="pt-3 mt-md-5">Description</h4>
                        <p>{serviceData.description}</p>
                    </div>
                </div>
            </section>
            <section className="">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-md-9">
                            <div className="heading-title tl">
                                <h2>Other Services</h2>
                                <p>services provide this provider.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className=" pt-lg-0 shop-heading pb-2">
                                <a href="#" className="btn-link">{totalServices || 0} Items</a>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-md-4 row2">
                        {services && services.map((service, indx) => {
                            return (
                                <div key={indx + '_sss'} className="col-lg-2 col-md-4 col-sm-6">
                                    <ServiceCard service={service} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <section className="">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-md-9">
                            <div className="heading-title tl">
                                <h2>Latest Viewed Items</h2>
                                <p>Investigate the best in class items, across the board place.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className=" pt-lg-0 shop-heading pb-2">
                                <a href="#" className="btn-link">{serviceData.totalViewed-1 || 0} Items</a>
                            </div>
                        </div>
                    </div>
                    <RecentalyViewedService service={serviceId} formDashboard={true} />
                </div>
            </section>
            <Banner />
            <NewsletterFormController />
            <Footer />
        </>
    )
}
export default ServiceDetailsComponent;