function ExclusiveFeaturesComponent() {
    return (
        <>
            <section className="pt-65 pb-50 bg-blue-light">
                <div className="container">
                    <div className="heading-title">
                        <h2 className="h_ttu">Exclusive Features</h2>
                        <p>Get work done in over 1800 different categories</p>
                    </div>
                    <div className="row pt-2 pt-md-4">
                        <div className="col-md-6 col-lg-4">
                            <div className="text-center feature-list">
                                <img src={require("../../webroot/images/list2.svg").default} alt="Explore the Products" />
                                <h3>Explore the Products</h3>
                                <p>Explore with our wide range of products category with your favorite brands.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="text-center feature-list">
                                <img src={require("../../webroot/images/graph.svg").default} alt="Growth of Your Business" />
                                <h3>Growth of Your Business</h3>
                                <p>We find innovative solutions to improve the visibility of your business and promote the growth of your
                                    activities.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="text-center feature-list">
                                <img src={require("../../webroot/images/medal2.svg").default} alt="Professional Services" />
                                <h3>Professional Services</h3>
                                <p>Find high-quality services at every price point.No hourly rates, just project-based pricing.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="text-center feature-list">
                                <img src={require("../../webroot/images/thumbs-up.svg").default} alt="Quality work done quickly" />
                                <h3>Quality work done quickly</h3>
                                <p>Find the right experts to begin working on your project within minutes.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="text-center feature-list">
                                <img src={require("../../webroot/images/checked_f.svg").default} alt="Protected Payments" />
                                <h3>Protected Payments</h3>
                                <p>Always know what you'll pay upfront. Your payment isn't released until you approve the work.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="text-center feature-list">
                                <img src={require("../../webroot/images/medal.svg").default} alt="Business Experience" />
                                <h3>Business Experience</h3>
                                <p>Upgrade to a curated experience packed with tools and benefits, dedicated to businesses</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-90 pb-90">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="download-appbox">
                                <h2>Download</h2>
                                <h5>ICT Kart App</h5>
                                <p>Mobile App Coming Soon on both Android and iPhone!! <br />Till then, Enjoy the platform on Desktop/Laptop
                                    Browser.</p>
                                <div className="app-btn">
                                    <a href="#0"><img src={require("../../webroot/images/android.png").default} alt="Google Play" /></a>
                                    <a href="#0"><img src={require("../../webroot/images/apple.png").default} alt="App Store" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={require("../../webroot/images/download-app.png").default} alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ExclusiveFeaturesComponent;