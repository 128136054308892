import ProductCard from "../product/ProductCard";
import { useEffect, useState } from "react";
import { UserServices } from "../../services";
import { toast } from "react-toastify";
import { loginUserId } from "../../utils/index";
import ServiceCard from "./ServiceCard";
function RecentalyViewedService({ formDashboard = false, service = null }) {
    const [products, setProducts] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        getProducts(service);
    }, [service])
    const getProducts = async (service) => {
        UserServices.serviceAllList({ service: service, type: 'view', userId: loginUserId() }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setProducts(data.list);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsLoading(false);
        })
    }
    return (
        <>
            {formDashboard ?
                <div className="row row2 mt-md-3 mt-2">
                    {products && products.map((mObj, index) => {
                        return (<div className="col-lg-2 col-md-2 col-sm-2" key={index + '_slsls'}>
                            <ServiceCard service={mObj} key={'child' + index} />
                        </div>)
                    })}
                </div> :
                <div className="col-lg-9">
                    <div className="d_boxs pb-1 mb-2 mb-md-4">
                        <div className=" pt-lg-0 dashbord-heading pb-2">
                            <h5 className="box_hadding">My Recentaly View
                                {/* <div className="box_hadding_btn">
                                <a href="">Track</a>
                                <a href=""> Change</a>
                                <a href="">Cancel</a>
                            </div> */}
                            </h5>
                        </div>
                        <div className="row row2 mt-md-3 mt-2">
                            {
                                products && products.map((mObj, index) => {
                                    return (<div className="col-lg-3 col-md-3 col-sm-2">
                                        <ProductCard productData={mObj} key={'child' + index} />
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default RecentalyViewedService;