import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { UserServices } from "../../../services";
import PersonalProfile from "../PersonalProfile";
import Select from 'react-select';
import { loginUserId } from "../../../utils/index";

const ManageTeam = (props) => {
    const [countriesArr, setCountriesArr] = useState([]);
    const [roleArr, setRoleArr] = useState([]);
    const [userData, setUserData] = useState(props.formObj);
    const crossRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setUserData(props.formObj)
        getCountryList();
        getRoleDropDownList();
    }, [])
    const getRoleDropDownList = () => {
        UserServices.roleDropdownList({ vendorId: loginUserId() }).then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setRoleArr(data);
            }
        })
    }
    const getCountryList = () => {
        UserServices.countryList().then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setCountriesArr(data);
            }
        })
    }
    const closeModalBox = () => {
        crossRef.current.click();
    }
    const personalProfileChangeHandler = (n, v) => {
        if (n == 'mobile') {
            setUserData((prev) => ({
                ...prev,
                'mobile': v.mobile,
                'dialCode': v.dialCode,
                'iso2': v.iso2
            }))
        }
        else if (n == 'ciso2') {
            setUserData((prev) => ({
                ...prev,
                [n]: v.iso_code_2,
                'country': v.name
            }))
        }
        else if (n == 'userRole') {
            setUserData((prev) => ({
                ...prev,
                'userRole': v.value
            }))
        }
        else {
            setUserData((prev) => ({
                ...prev,
                [n]: v
            }))
        }
    }
    const validForm = () => {
        let isValid = true;
        let { firstName, mobile, email } = userData;
        let error = {};
        if (firstName) error.firstName = ""; else error.firstName = "error";
        if (mobile) error.mobile = ""; else error.mobile = "error";
        if (email) error.email = ""; else error.email = "error";
        if (error.firstName || error.mobile || error.email) {
            return false;
        }
        return true;
    }
    const submitUserForm = (e) => {
        if (validForm()) {
            userData.password = userData.mobile;
            userData.hearAboutICT = 'Admin';
            userData.role = 'vendor';
            userData.companyName = 'vendor user';
            userData.vendor = loginUserId();
            UserServices.addUser(userData).then((responce) => {
                let { message, status } = responce.data;
                if (responce.status == 200 || status == 200 || responce.statusCode) {
                    toast.success(message);
                    props.onSuccess(true);
                    closeModalBox();
                }
                else {
                    toast.error(message||responce.message);
                }
            })
        }
        else {
            toast.error('Please fill all the required field')
        }
    }
    return (
        <div className={`modal fade ${props.show ? 'show' : ''}`} id="userTeamMODAL" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <div><h4><i className="fa fa-th-large system-color"></i> User Profile </h4></div>
                        <button type="button" onClick={closeModalBox} className="close" ref={crossRef} data-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <label htmlFor="userRole" className="required">Role</label>
                                <Select
                                    name="userRole"
                                    value={roleArr.filter(obj => userData['userRole'] == (obj.value))}
                                    onChange={(e) => personalProfileChangeHandler('userRole', e)}
                                    options={roleArr}
                                    isClearable
                                />
                            </div>
                        </div>
                        <br />
                        <PersonalProfile changeHandler={personalProfileChangeHandler} userData={userData} countriesArr={countriesArr} />
                        <div className="col-lg-3">
                            <button type="button" onClick={submitUserForm} className="btn btn-primary w100">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageTeam;