import ActionTypes from "../actions/action"
const initialState = {
    userDetail: {}
}
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN_USER:
            return {
                ...state,
                userDetail: action.payload
            }
        case ActionTypes.LOGOUT_USER:
            return {
                ...state,
                userDetail: action.payload
            }
        case ActionTypes.USER_LOGIN_POPUP:
            return {
                ...state,
                loginPopup: action.payload
            }
        default: return state;
    }
}

export default userReducer;