import UserFavourites from "../user/UserFavourites";
import UserMyOrders from "../user/UserMyOrders";
import MyOrders from "../vender/VendorMyOrders";
import MyServices from "../vender/VendorMyServices";
import AccountSetting from "./AccountSetting";
import LeftMenu from "./LeftMenu";
import Notification from "./Notification";
import TopRiben from "./TopRiben";
function UserDashboardComponent({ userData }) {
    return (
        <>
            <div className="col-lg-12">
                <div className="d_boxs pb-1 mb-2 mb-md-4">
                    <div className=" pt-lg-0 dashbord-heading pb-2">
                        <h5 className="box_hadding">My Orders
                            {/* <div className="box_hadding_btn">
                                <a href="">Track</a>
                                <a href=""> Change</a>
                                <a href="">Cancel</a>
                            </div> */}
                        </h5>
                        {/* <a href="#" className="btn-link">See all your products</a> */}
                    </div>
                    <UserMyOrders formDashboard={true} />
                </div>
                {/* <div className="d_boxs pb-1 mb-2 mb-md-4">
                    <div className=" pt-lg-0 dashbord-heading pb-2">
                        <h5 className="box_hadding">My Services
                            <div className="box_hadding_btn">
                                <a href="">Awarded Projects</a>
                                <a href=""> Bids</a>
                                <a href="">Cancel</a>
                            </div>
                        </h5>
                        <a href="#" className="btn-link ">See all your products</a>
                    </div>
                    <MyServices />
                </div> */}
                <div className="d_boxs pb-1 mb-2 mb-md-4">
                    <UserFavourites formDashboard={true} />
                </div>
            </div>

        </>
    )
}
export default UserDashboardComponent;