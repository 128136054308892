export const API_URL = {
    // COMMON_GATEWAY_API: 'http://api.ictkart.com/api/',//// LIVE OLD 
    // COMMON_GATEWAY_API: 'https://52.14.163.54:8182/api/',//// LIVE IP
    // COMMON_GATEWAY_API: 'https://api.ictkart.com:8182/api/',//// LIVE NEW
    COMMON_GATEWAY_API: 'https://server.ictkart.com/api/',//// LIVE NEW

    // COMMON_GATEWAY_API: 'http://3.20.89.137:8181/api/',//// STAGE 

    // COMMON_GATEWAY_API: 'https://localhost:8182/api/', //// LOCAL
    // COMMON_GATEWAY_API: 'https://localhost:443/api/', //// LOCAL on 443
    COMMON_LOGIN_STORE_KEY: 'webloginUserInfo',
    COMMON_TOKEN_STORE_KEY: 'webloginUserTOKEN',
    COMMON_CART_STORE_KEY: 'webincart',
    COMMON_ROLE_KEY: 'role'
}
export const CONTENT_TYPES = [
    { key: 'home', label: "Home" },
    { key: 'product', label: "Product" },
    { key: 'aboutus', label: "Aboutus" },
    { key: 'contactus', label: "Contactus" },
    { key: 'privacy-policy', label: "Privacy Policy" },
    { key: 'how-it-works', label: "How it works" },
    { key: 'shipping-policy', label: "Shipping Policy" },
    { key: 'cancellation-policy', label: "Cancellation Policy" },
    { key: 'return-policy', label: "Return Policy" },
    { key: 'refund-policy', label: "Refund Policy" },
    { key: 'help-support', label: "Help and support" },
    { key: 'terms-service', label: "Terms of Service" },
    { key: 'trust-safety', label: "Trust & Safety" },
    { key: 'service', label: "Service" },
    { key: 'service-provider', label: "Service Provider" },
    { key: 'solution', label: "Solution" },
    { key: 'security', label: "Security" },
    { key: 'buyingonict', label: "Buying on ict" },
    { key: 'sellingonict', label: "Selling on ict" },
    { key: 'findpartner', label: "Find Partner" },
    { key: 'investor', label: "Investor" },
    { key: 'investor-relations', label: "Investor Relations" },
]
export const CATEGORY_TYPES = [
    { value: 'product', label: "Product" },
    { value: 'service', label: "Service" },
    { value: 'solution', label: "Solution" }
]
export const REDIRECT_TYPE = [
    { value: '_blank', label: "Blank" },
    { value: '_self', label: "Self" },
]
export const DATE_FORMAT = "dd mmm yyyy, HH:MM";
export const DATE_FORMAT_DMY = "dd mmm yyyy";
export const DATE_FORMAT_INPUT = "yyyy-MM-dd";
export const DATE_FORMAT_YMD = "yyyy-mm-dd";
export const DATE_FORMAT_FILTER = "yyyy-mm-dd";
export const IS_NUMBER_VALIDATION = /^[0-9]*$/;

export const SPECIFICATIONS = [
    { key: 'screen-size', label: "Screen Size" },
    { key: 'screen-resolution', label: "Screen Resolution" },
    { key: 'touch-screen', label: "Touch Screen" },
    { key: 'processor-model', label: "Processor Model" },
    { key: 'processor-speed', label: "Processor Speed (Base)" },
    { key: 'storage-type', label: "Storage Type" },
    { key: 'storage-capacity', label: "Total Storage Capacity" },
    { key: 'solid-state-drive-capacity', label: "Solid State Drive Capacity" },
    { key: 'ram', label: "System Memory (RAM)" },
    { key: 'graphics', label: "Graphics" },
    { key: 'operating-system', label: "Operating System" },
    { key: 'battery-life', label: "Battery Life (up to)" },
    { key: 'battery-type', label: "Battery Type" },
    { key: '2-in-1-design', label: "2-in-1 Design" },
    { key: 'backlit-keyboard"', label: "Backlit Keyboard" },
]
export const ESTIMATED_TIME_TYPES = [
    { value: 'hourly', label: "Hourly" },
    { value: 'days', label: "Days" },
    { value: 'weekly', label: "Weekly" },
    { value: 'monthly', label: "Monthly" }
]
export const options = {
    loop: true,
    margin: 10,
    nav: true,
    responsiveClass: true,
    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 3,
        },
    },
};
export const wnoptions = {
    loop: true,
    margin: 10,
    responsiveClass: true,
    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 3,
        },
    },
};
export const options6 = {
    loop: true,
    margin: 10,
    nav: true,
    responsiveClass: true,
    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 2,
        },
        900: {
            items: 3,
        },
        1000: {
            items: 5,
        },
        1300: {
            items: 6,
        },
    },
};
export const SHORT_BY = [
    { label: 'Popularity', value: 'popularity' },
    { label: 'Newest First', value: 'new' },
    { label: 'Price -- High to Low', value: 'high_to_low' },
    { label: 'Price -- Low to High', value: 'low_to_high' },
]
export const PUBLISHABLE_KEY = 'pk_test_51JiyX3IQlecXJ3PYDKY3WRmx2ZaPer37U4bFLg16BmtaeBsXV5mpgNwTHsZUCz7mtobVV9O52RO7Rfcy97krIBvB002I1HjJYt'
export const SECRET_KEY = 'sk_test_51JiyX3IQlecXJ3PYON5F3EMlEFrQGAqgyy3wdHHc528qyu7owBHeofrz4Ihzrd4yqBiGy2VFotJu2CtAQzu9fM6h00e40shSsq';
export const GOOGLE_CLIENT_ID = "551227723848-ve1u4c4ck0hda6j5su98d8u4mfvollsk.apps.googleusercontent.com";
export const FACEBOOK_APP_ID = "585273759881553";////"1051117699175641";