import { Link } from 'react-router-dom';
function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-6 col-md-3">
                        <h6 className="footer-menutitle">Category</h6>
                        <ul className="footer-menu">
                            <li>
                                <Link to={"/dashboard"}>My Account</Link>
                            </li>
                            <li>
                                <Link to={"/dashboard"}>Order Status</Link>
                            </li>
                            <li>
                                <Link to={"/dashboard"}>My Products</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 col-md-3">
                        <h6 className="footer-menutitle">Our Company</h6>
                        <ul className="footer-menu">
                            <li>
                                <Link to={"aboutus"}>About us</Link>
                            </li>
                            <li>
                                <Link to={"/how-it-works"}>How it Works</Link>
                            </li>

                            <li>
                                <Link to={"/security"}>Security</Link>
                            </li>
                            <li>
                                <Link to={"/investor"}>Investor</Link>
                            </li>
                            <li>
                                <Link to={"/privacy-policy"}>Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to={"/terms-service"}>Terms of Service</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 col-md-3">
                        <h6 className="footer-menutitle">Support</h6>
                        <ul className="footer-menu">
                            <li>
                                <Link to={"/help-support"}>Help &amp; Support</Link>
                            </li>
                            <li>
                                <Link to={"/trust-safety"}>Trust &amp; Safety</Link>
                            </li>
                            <li>
                                <Link to={"/sellingonict"}>Selling on ICT</Link>
                            </li>
                            <li>
                                <Link to={"/buyingonict"}>Buying on ICT</Link>
                            </li>
                            <li>
                                <Link to={"/faqs"}>FAQs</Link>
                            </li>
                            <li>
                                <Link to={"/contact-us"}>Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 col-md-3">
                        <h6 className="footer-menutitle">Our Partners</h6>
                        <ul className="footer-menu">
                            <li>
                                <Link to={"/findpartner"}>Find A Partner</Link>
                            </li>
                            <li>
                                <Link to={"/service-provider"}>Service Providers</Link>
                            </li>
                            <li>
                                <Link to={"/investor-relations"}>Investor Relations</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="copyright">
                                <img src={require("../../webroot/images/ict-logo.png").default} alt="ICT cart" />
                                <span>&copy; ICT kart.com.2021</span>
                            </div>
                        </div>
                        <div className="col-md-8 col-xl-6">
                            <div className="copyright payment-method"><span>Payment methods</span>
                                <img src={require("../../webroot/images/payments.png").default} alt="Payment methods" />
                            </div>
                        </div>
                        <div className="col-auto">
                            <h6 className="social-title">Connect with us</h6>
                            <ul className="social-links">
                                <li>
                                    <a href="https://www.facebook.com"><img src={require("../../webroot/images/facebook.png").default} alt="Facebook" /></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/"><img src={require("../../webroot/images/instagram.png").default} alt="Instagram" /></a>
                                </li>
                                <li>
                                    <a href="https://web.whatsapp.com/"><img src={require("../../webroot/images/whatsapp.png").default} alt="Whatsapp" /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;