import { UserServices } from "../../services";
import { toast } from "react-toastify";
import Loader from "../common/Loader";
import { loginUserId } from "../../utils/index";
import { useEffect, useState } from "react";
function RatingReviews({ rating, itemId, fromReview = "" }) {
    const [productRatings, setProductRatings] = useState({
        ratings: [],
        total: 0
    });
    const [isLoading, setIsLoading] = useState(false);
    const [productId, setProductId] = useState((rating ? rating._id : ""));

    useEffect(() => {
        if (itemId) {
            if (fromReview == "") setProductId(itemId);
            getProductRatings(itemId);
        }
    }, [itemId])
    const getProductRatings = (itemId) => {
        let obj = fromReview == "" ? { productId: itemId } : { vendorId: itemId };
        setIsLoading(true);
        UserServices.reviewProductList(obj).then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                // console.log(data);
                setProductRatings(data);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    const ratePercent = (rate, totalRate) => {
        return totalRate < 1 ? 0 : (rate * 100 / totalRate);
    }
    return (
        <>
            <h5 className=" mb-md-3">Reviews</h5>
            <div className="row mb-md-5  ">

                <div className="col-lg-2 col-md-4 mb-3">
                    <div className="border_box reviews_box h100">
                        <h6 className="fw400">Customer rating</h6>
                        <h2 className="tc">{rating.averageRating || 0}</h2>
                        <div className="reviews_rating tc">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                        <span className="ccc tc d_block">({rating.totalRating || 0} Reviews)</span>
                    </div>
                </div>
                <div className="col-lg-5 col-md-5 mb-2">
                    <div className="border_box reviews_box p-md-3 ">
                        {rating.ratingData && Object.keys(rating.ratingData).map((rat, r) => {
                            return (
                                <div className="star_progress align-items-center">
                                    <div className="form-check">
                                        {/* <input type="checkbox" className="form-check-input" id="exampleCheck1" /> */}
                                        <label className="form-check-label" for="exampleCheck1"></label>
                                    </div>
                                    <span className="ccc"> {rat} </span>
                                    <i className="fa fa-star ml-3 mr-3 yellow"></i>
                                    <div className="progress">
                                        <div className="progress-bar"
                                            role="progressbar"
                                            style={{ width: ratePercent(rating.ratingData[rat], rating.totalRating) + '%' }}
                                            aria-valuenow={ratePercent(rating.ratingData[rat], rating.totalRating)}
                                        ></div>
                                    </div>
                                    <span className="ccc ml-3"> {rating.ratingData[rat]}</span>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
                {/* <div className="col-lg-5 col-md-3  d_flex mb-2">
                    <a href="" className="btn bg11 text-white pr-md-4 pl-md-4 mt-auto ml-auto">Write A Review</a>
                </div> */}
            </div>
            <div className="pt-md-3">
                <p>Showing 1-{productRatings.total} of reviews</p>
            </div>
            {
                (productRatings.ratings).map((rating, r) => {
                    return (
                        <div className="about_seller mb-2 mt-2 mt-md-4">
                            <div className="seller_ime tc">
                                <img src={require("../../webroot/images/seller.png").default} alt="seller" className="img" />
                                <b className="co1 d_block mt-3">{rating.userId ? (rating.userId.firstName + ' ' + rating.userId.lastName) : ""}</b>
                            </div>
                            <div className="text_seller pl-md-5 pl-2">
                                <div className="star_revi pt-2 d_flex">
                                    <i className={"fa fa-star" + (rating.rate > 0 ? '' : 'black-start')}></i>
                                    <i className={"fa fa-star" + (rating.rate > 1 ? '' : 'black-start')}></i>
                                    <i className={"fa fa-star" + (rating.rate > 2 ? '' : 'black-start')}></i>
                                    <i className={"fa fa-star" + (rating.rate > 3 ? '' : 'black-start')}></i>
                                    <i className={"fa fa-star" + (rating.rate > 4 ? '' : 'black-start')}></i>
                                    <h6 className="p-0">{rating.title}</h6>
                                </div>

                                <div className="star_revi  pt-2 mb-3">
                                    <img src={require("../../webroot/images/checked.png").default} />
                                    <b>Verified Purchase</b><span className="ccc"> Posted {rating.createdAt}</span>
                                </div>
                                <p className="pb-1">{rating.comments || ""}</p>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}
export default RatingReviews;