import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import ProductCard from "./ProductCard";
import { options6 } from "../../config/constant";
import { ShimmerPostItem } from "react-shimmer-effects";
import { useEffect, useState } from "react";
import { UserServices } from "../../services";
import { toast } from "react-toastify";
function BestSellingProducts() {
    const [bestSellingProducts, setBestSellingProducts] = useState([]);
    useEffect(() => {
        getBestProducts();
    }, [])
    const getBestProducts = async (search = '') => {
        UserServices.productAllList({ limit: 20 }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setBestSellingProducts(data.list);
            }
        }).catch((error) => {
            toast.error(error);
        })
    }
    return (
        <section className="">
            <div className="container">
                <div className="row pb-50 align-items-end">
                    <div className="col-md-9">
                        <div className="heading-title tl">
                            <h2>Best Selling Items</h2>
                            <p>Investigate the best in class items, across the board place.</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className=" pt-lg-0 shop-heading pb-2">
                            <Link to={'/products/list'} className="btn-link">View All</Link>
                        </div>
                    </div>
                    <OwlCarousel className="owl-carousel items-carousel" {...options6}>
                        {
                            bestSellingProducts && bestSellingProducts.map((relServ, index) => {
                                return (
                                    <div key={index + 'p'} className={"item " + relServ._id}>
                                        {('_id' in relServ) ? <ProductCard productData={relServ} /> :
                                            <ShimmerPostItem card title cta imageHeight={80} contentCenter />
                                        }
                                    </div>
                                )
                            })
                        }
                    </OwlCarousel>

                </div>
            </div>
        </section>

    )
}
export default BestSellingProducts;