import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import secureStorage from "../../config/encrypt";
import { UserServices } from "../../services";
import { API_URL } from "../../config/constant";
import { toast } from "react-toastify";
function ThankYou() {
    const [isVerify, setIsVerify] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const history = useHistory();
    const [otpForm, setOtpForm] = useState({
        otp: "",
        userId: ""
    });
    useEffect(() => {
        let email = secureStorage.getItem("EMAIL");
        let withOTP = secureStorage.getItem("withOTP");
        if (withOTP == true) {

        }
        if (email) {
            setUserEmail(email);
        }
    }, [])
    const verifyWithOTP = (e) => {
        setIsVerify(true);
        secureStorage.setItem("withOTP", true);
    }
    const otpChangeHandler = (e) => {
        setOtpForm((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }
    const otpSubmit = (e) => {
        if (otpForm.otp) {
            setIsVerify(true);
            let obj = {
                otp: otpForm.otp,
                email: userEmail
            }
            UserServices.verifyOTP(obj).then((responce) => {
                let { data, message, statusCode } = responce;
                if (statusCode == 200) {
                    toast.success(message);
                    secureStorage.setItem(API_URL.COMMON_LOGIN_STORE_KEY, data)
                    if (history) history.push('/user/update-profile?step=myprofile');
                }
                else {
                    toast.error(message);
                }
                setIsVerify(false);
            })
        }
        else {
            toast.error('Please input valid otp')
        }
    }
    const resendOTP = (e) => {
        setIsVerify(true);
        let obj = {
            email: userEmail
        }
        UserServices.resendOTP(obj).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                toast.success(message);
            }
            else {
                toast.error(message);
            }
            setIsVerify(false);
        })
    }
    return (
        <div className="thank-you-bg" >
            <div className="thank-u-container" style={{ backgroundImage: `url("https://ictkart-pro.s3.us-east-2.amazonaws.com/bg-banner.jpg")`, backgroundRepeat: 'no-repeat' }}>
                <div className="thank-u-content">
                    <h3>Thanking you for signup</h3>
                    <p>Please verify your email address</p>
                    <input type="text"
                        className="form-control"
                        value={otpForm.otp}
                        name="otp"
                        onChange={otpChangeHandler}
                        placeholder="Enter your OTP"
                        required
                    />
                    <br />
                    <div className="v-otp-c">
                        <button onClick={otpSubmit} className="btn btn-primary btn-sm">Verify OTP</button>
                        {isVerify ? <i class="fa fa-spinner fa-spin"></i> : null}
                        <a className="resend-otp-thank-u" onClick={resendOTP}>Resend OTP</a>
                    </div>
                    {/* <br />
                    <a className="cursor-pointer" onClick={verifyWithOTP}>Verify using OTP</a>
                    <br /> */}
                    {/* <span>OR</span>
                    <br />
                    <Link to={`/user/update-profile?step=myprofile`} className="btn btn-primary">Get Started!</Link>
                    <br /> */}
                </div>
            </div>
        </div>
    );
}

export default ThankYou;