import Header from "../common/Header";
import Footer from "../common/Footer";
import NewsletterFormController from "../common/NewsletterFormController";
// import ServiceFilter from "./ServiceFilter";
import Banner from "../common/Banner";
import { Link, useLocation, useHistory } from "react-router-dom";
import QuestionAnswer from "./QuestionAnswer";
import RatingReviews from "./RatingReviews";
import AboutSeller from "../vender/AboutSeller";
import ProductCompaire from "./ProductCompaire";
import ServiceCompaire from "../Service/ServiceCompaire";
import ProductCard from "./ProductCard";
import { useState, useEffect } from "react";
import { UserServices } from "../../services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import store from "../../store/store"
import Loader from "../common/Loader";
import { SHORT_BY } from "../../config/constant";
import ProductSerach from "./ProductSerach";
import Pagination from "../elements/Pagintion";
function ProductAllListComponent() {
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [totalProducts, setTotalProducts] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [shortby, setShortby] = useState('popularity');
    const [showFilterBox, setShowFilterBox] = useState(false);

    let inState = useSelector(state => {
        return state.product;
    });
    const history = useHistory();
    const search = useLocation().search;
    const cate = new URLSearchParams(search).get('cate');
    const searchKey = new URLSearchParams(search).get('search');
    console.log(searchKey);
    let { product } = store.getState();
    useEffect(() => {

    }, [searchKey]);
    useEffect(() => {
        let searchObj = {};
        if (product && product.search) {
            if (history) history.push('/products/list');
            searchObj = { search: product.search };
        }
        else if (cate) {
            searchObj = { category: [cate] };
        }
        else if (searchKey) {
            searchObj = { search: searchKey };
        }
        getProducts(searchObj, 1);
    }, [product, cate, searchKey]);

    const shortProductList = (e) => {
        setShortby(e.target.value);
        getProducts({ category: [cate] }, e.target.value);
    }
    const getProducts = async (searchData, pageNum = page) => {
        searchData.sort = shortby || 'popularity';
        searchData.page = pageNum;
        searchData.limit = 24;
        UserServices.productAllList(searchData).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setProducts(data.list);
                setTotalProducts(data.total);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsLoading(false);
        })
    }
    const handlePageClick = (n) => { setIsLoading(true); setPage(n); getProducts({}, n); }

    return (
        <>
            <Header enableSearch={'Product'} showFilterProduct={'prolist'} header={'header2'} fakeLogin={true} spaceType={'product'} />
            <Loader isLoading={isLoading} />
            <section className="pb-65">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            {/* <h4 className="">Reseults for “laptop hp” in All Laptops. <small className="btn-link">15 Items</small></h4> */}
                            <a onClick={()=>window.location.reload()} className="co6 cursor-pointer">Search all categories instead.</a>
                        </div>
                        <div className="col-md-4 filter-pin-tip">
                            <div className="tr_drop">
                                <label> Sort By: &nbsp;&nbsp;</label>
                                <select onChange={shortProductList} className="short_by">
                                    {SHORT_BY && SHORT_BY.map((s, i) => {
                                        return (<option value={s.value} key={i + '_ik'}>{s.label}</option>)
                                    })}
                                </select>
                            </div>
                            <div className="filter-pin-c">
                                <div className="filter-pin" onClick={(e) => setShowFilterBox(showFilterBox ? false : true)}>
                                    <i className="fa fa-filter system-color"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-md-4 row2 pro-list">
                        {products && products.map((obj, index) => {
                            return (
                                <div key={'mmm' + index} className="col-lg-2 col-md-4 col-sm-6">
                                    <ProductCard productData={obj} />
                                </div>
                            )
                        })}
                    </div>
                    <Pagination onPaginate={handlePageClick} total={totalProducts} perPage={24} />
                </div>
            </section>
            <div id="snackbar" className={"bottom-search " + (showFilterBox ? 'show' : '')}>
                <div className="search-box-0101">
                    <ProductSerach type={'prolist'} />
                </div>
            </div>
            <NewsletterFormController />
            <Footer />
        </>
    )
}
export default ProductAllListComponent;