import ActionTypes from "./action"
export const userDetail = (data) => {
    return {
        type: ActionTypes.LOGIN_USER,
        payload: data
    }
}
export const addUserDetail = (data) => {
    return {
        type: ActionTypes.ADD_LOGIN_USER,
        payload: data
    }
}
export const addProductInCard = (data) => {
    return {
        type: ActionTypes.ADD_TO_CART,
        payload: data
    }
}
export const productSearch = (data) => {
    return {
        type: ActionTypes.PRODUCT_SEARCH,
        payload: data
    }
}
export const userLoginPopup = (data) => {
    return {
        type: ActionTypes.USER_LOGIN_POPUP,
        payload: data
    }
}