import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Switch, useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import StripeCheckout from 'react-stripe-checkout';
import { UserServices } from "../../services";
import Header from "../common/Header";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { loginUserId } from "../../utils/index";
import secureStorage from "../../config/encrypt";
import { API_URL, PUBLISHABLE_KEY } from "../../config/constant";

const MySwal = withReactContent(Swal);
const intialAmount = {
    items: 0,
    totalPrice: 0,
    totalDiscount: 0,
    deliveryCharges: 0,
    totalAmount: 0
}
let buyProduct = {
    user: "",
    transactionId: "",
    orderId: "",
    paidAmount: 0,
    totalItems: 0,
    totalPrice: 0,
    totalDiscount: 0,
    deliveryCharges: 0,
    totalAmount: 0,
    shippingAddr: "",
    appOrderId: "",
    paymentStatus: false,
    from: "",
    items: [
        { service: "", currency: "", items: "", originalPrice: 0, sellingPrice: 0 }
    ]
}
let defaultUserAddress = {
    long: 0,
    lat: 0,
    postalAddress: "",
    country: "",
    state: "",
    city: "",
    houseNo: "",
    locality: "",
    postcode: "",
    name: "",
    mobile: "",
    temporaryMobile: "",
    addressType: "home",///["home", "work"]
    addressMode: "shipping"///["billing", "shipping"],
}

const ViewCartCheckOutService = () => {
    const [mycarts, setMycarts] = useState([]);
    const [myProductOrder, setMyProductOrder] = useState(buyProduct);
    const [isLoading, setIsLoading] = useState(true);
    const [priceDetails, setPriceDetails] = useState(intialAmount);
    const [userAddress, setUserAddress] = useState(defaultUserAddress);
    let { productId } = useParams();
    let history = useHistory();
    let location = useLocation();
    let fromLocation = location.pathname.split('/').slice(1)[0];
    useEffect(() => {
        getAllCartsProducts();
        // getShippingAddress();
    }, [])
    const getShippingAddress = () => {
        UserServices.shippingAddressList({ userId: loginUserId() }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                // console.log(data);
                let address = data.list.length ? data.list[0] : { address: {} };
                setUserAddress({ ...address, ...address.address })
            }
            else {
                toast.error(message);
            }
        }).catch((error) => {
            toast.error(error);
        })
    }
    const getAllCartsProducts = () => {
        UserServices.serviceUserCart({ userId: loginUserId(), serviceId: productId }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setMycarts(data.list);
                priceCalculation(data.list);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsLoading(false);
        })
    }
    const removeFromCart = (p, i) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: "Are you sure you want to remove this item!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    userId: loginUserId(),
                    product: p._id
                }
                UserServices.removeIncart(obj).then((responce) => {
                    let { message, data, status } = responce;
                    if (status == 200) {
                        secureStorage.setItem(API_URL.COMMON_CART_STORE_KEY, data.carts);
                        getAllCartsProducts();
                    }
                    else {
                        toast.error(message);
                    }
                    setIsLoading(false);
                })
            }
        })
    }
    const addQuantity = (product, index) => {
        toggleIncart(product._id, 1);
        mycarts[index]['quantity'] = mycarts[index]['quantity'] ? mycarts[index]['quantity'] : 0;
        mycarts[index]['quantity'] = mycarts[index]['quantity'] + 1;
        setMycarts([...mycarts]);
        priceCalculation(mycarts);
    }
    const minusQuantity = (product, index) => {
        if (mycarts[index]['quantity'] > 1) {
            toggleIncart(product._id, -1);
            mycarts[index]['quantity'] = mycarts[index]['quantity'] - 1;
            setMycarts([...mycarts]);
            priceCalculation(mycarts);
        }
    }
    const toggleIncart = (itemId, units) => {
        let obj = {
            userId: loginUserId(),
            carts: {
                product: itemId,
                units: units
            }
        }
        UserServices.addIncart(obj).then((responce) => {
            let { message, data, status } = responce;
            if (status == 200) {
                secureStorage.setItem(API_URL.COMMON_CART_STORE_KEY, data.carts);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    const priceCalculation = (productIncarts) => {
        setPriceDetails({ ...intialAmount })
        let productPrice = productIncarts.reduce((a, i) => {
            i.totalPrice = i.originalPrice;
            i.totalDiscount = i.originalPrice - i.sellingPrice;
            i.quantity = i.quantity || 1;
            return {
                totalPrice: a.totalPrice + i.totalPrice * i.quantity,
                totalDiscount: a.totalDiscount + i.totalDiscount
            }
        }, { totalPrice: 0, totalDiscount: 0 })
        let totalAmount = productPrice.totalPrice + priceDetails.deliveryCharges;
        setPriceDetails((prev) => ({ ...prev, items: productIncarts.length, ...productPrice, totalAmount: totalAmount }))
    }
    const buyAllProducts = (token) => {
        let productInfo = {
            user: loginUserId(),
            transactionId: 'SERTR' + Date.now(),
            orderId: 'SEROR' + Date.now(),
            paidAmount: priceDetails.totalAmount - priceDetails.totalDiscount,
            totalItems: priceDetails.items,
            totalPrice: priceDetails.totalPrice,
            totalDiscount: priceDetails.totalDiscount,
            deliveryCharges: priceDetails.deliveryCharges,
            totalAmount: priceDetails.totalAmount,
            shippingAddr: null,
            appOrderId: "SERAPOR" + Date.now(),
            paymentStatus: true,
            from: productId,
            items: mycarts.map((item) => {
                return { currency: item.currency, type: 'service', service: item._id, items: item.quantity, originalPrice: item.originalPrice, sellingPrice: item.sellingPrice, vendor: item.seller ? item.seller._id : null }
            }),
            address: userAddress,
            type: 'service'
        }
        /** API */
        UserServices.buyProduct({ product: productInfo, token: token }).then((responce) => {
            let { message, data, status } = responce;
            if (status == 200) {
                toast.success(message);
                secureStorage.setItem(API_URL.COMMON_CART_STORE_KEY, []);
                if (history) history.push('/my/orders');
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    /** {Payment integration} */
    const onToken = token => {
        console.log(token);
        buyAllProducts(token);
    };
    const onOpened = (e) => {
        console.log(e);
    }
    const onClosed = (e) => {
        console.log(e);
    }
    const changeHandler = (e) => {
        setUserAddress((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }
    const saveAddress = (e) => {
        if (loginUserId()) {
            userAddress.userId = loginUserId();
            userAddress.long = 0;
            userAddress.lat = 0
            UserServices.addShippingAddress(userAddress).then((responce) => {
                let { message, status } = responce;
                if (status == 200) {
                    toast.success(message);
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            })
        }
    }
    return (
        <>
            <Header showMenu={2} header={'header2'} spaceType={'product'} />
            <div className="container">
                {!isLoading && mycarts.length < 1 ?
                    <div className="card">
                        <h1>You have not product in card</h1>
                    </div> :
                    <div className="grid-view-cart">
                        <div className="accordion" id="accordionExample">
                            {/* <div className="card">
                                <div className="card-header card-collapse" id="headingOne">
                                    <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Shipping Address
                                    </button>
                                </div>
                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group col-md-4">
                                                <label className="required"> Name</label>
                                                <input type="text" className="bg-r form-control nospace" onChange={changeHandler} name="name" value={userAddress.name} />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label className="required"> Mobile</label>
                                                <input type="text" className="bg-r form-control nospace" onChange={changeHandler} name="mobile" value={userAddress.mobile} />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label className="">Temporary Mobile</label>
                                                <input type="text" className="bg-r form-control nospace" onChange={changeHandler} name="temporaryMobile" value={userAddress.temporaryMobile} />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label className="required"> Pin Code</label>
                                                <input type="text" className="bg-r form-control nospace" onChange={changeHandler} name="postcode" value={userAddress.postcode} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label className="required"> House Number</label>
                                                <input type="text" className="bg-r form-control nospace" onChange={changeHandler} name="houseNo" value={userAddress.houseNo} />
                                            </div>
                                            <div className="form-group col-md-8">
                                                <label className="required"> Locality</label>
                                                <input type="text" className="bg-r form-control nospace" onChange={changeHandler} name="locality" value={userAddress.locality} />
                                            </div>
                                            <div className="form-group col-md-8">
                                                <label className="required"> City/District/Town</label>
                                                <input type="text" className="bg-r form-control nospace" onChange={changeHandler} name="city" value={userAddress.city} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label className="required"> State</label>
                                                <input type="text" className="bg-r form-control nospace" onChange={changeHandler} name="state" value={userAddress.state} />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label className="required"> Address (Area and Street)</label>
                                                <textarea rows="3" className="bg-r form-control nospace" onChange={changeHandler} name="postalAddress" value={userAddress.postalAddress} ></textarea>
                                            </div>
                                            <p>Address Type</p>
                                            <div className="form-group col-md-12">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" onChange={changeHandler} checked={userAddress.addressType == 'home' ? 'checked' : null} name="addressType" type="radio" id="inlineCheckbox1" value="home" />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox1">Home (All day delivery)</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" onChange={changeHandler} checked={userAddress.addressType == 'work' ? 'checked' : null} name="addressType" type="radio" id="inlineCheckbox2" value="work" />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox2">Work (Delivery between 10 AM - 5 PM)</label>
                                                </div>
                                            </div>
                                            <button onClick={saveAddress} className="btn btn_man  ttu lh32">Save and Deliver Here</button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="card">
                                <div className="card-header card-collapse" id="headingTwo">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        {fromLocation == 'service-check-out' ? 'Service Detail' : 'My Cart'} ({mycarts.length || 0})
                                    </button>
                                </div>
                                <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div className="card-body">
                                        {mycarts && mycarts.map((product, index) => {
                                            return (
                                                <div className="row v-c-items" key={'card_' + index}>
                                                    <div className="col-md-2">
                                                        <img className="img-fluid mx-auto d-block image" src={product.thumbnail} />
                                                    </div>
                                                    <div className="col-md-10">
                                                        <div className="info">
                                                            <div className="row">
                                                                <div className="col-md-5 product-name">
                                                                    <div className="product-name">
                                                                        <Link className="text-link" to={`/product/${(product.brandData ? product.brandData.slug : 'ict')}/${product._id}`}>{product.title || ""}</Link>
                                                                        <div className="product-info">
                                                                            <div>Seller: <span className="value">{product.seller ? product.seller.firstName + ' ' + product.seller.lastName : "'"}</span></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-md-3 quantity">
                                                                    <label className="text-center" htmlFor="quantity">Quantity:</label>
                                                                    <div className="product-quantity d-flex">
                                                                        <button onClick={() => minusQuantity(product, index)} className="btn btn-primary btn-circle btn-sm"><i className="fa fa-minus white"></i></button>
                                                                        <input readOnly type="text" value={product.quantity} className="form-control number text-center white" />
                                                                        <button onClick={() => addQuantity(product, index)} className="btn btn-primary btn-circle btn-sm"><i className="fa fa-plus white"></i></button>
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-md-3">
                                                                    <label htmlFor="quantity">Price:</label>
                                                                    <div>
                                                                        <span className="price">{product.currency || ""}
                                                                            <strong> {product.sellingPrice || ""}</strong>
                                                                            <del>{product.currency || ""} {product.originalPrice || ""}</del>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-md-1">
                                                                    <label htmlFor="quantity"></label>
                                                                    <button onClick={(e) => removeFromCart(product, index)} className="btn btn-secondary btn-circle btn-sm"><i className="fa fa-trash white"></i></button>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header card-collapse">
                                <h4 className="ti-tle">PRICE DETAILS</h4>
                            </div>
                            <div className="card-body">
                                <div className="view-cart-items">
                                    <label>Price ({priceDetails.items} items)</label>
                                    <span>AED {priceDetails.totalPrice}</span>
                                </div>
                                <div className="view-cart-items">
                                    <label>Discount</label>
                                    <span>AED {priceDetails.totalDiscount}</span>
                                </div>
                                <div className="view-cart-items">
                                    <label>Delivery Charges</label>
                                    <span>AED {priceDetails.deliveryCharges}</span>
                                </div>
                                <span className="doted-line"></span>
                                <div className="view-cart-items total">
                                    <label>Total Amount</label>
                                    <span>AED {priceDetails.totalAmount - priceDetails.totalDiscount}</span>
                                </div>
                                {fromLocation == 'service-check-out' ?
                                    <StripeCheckout
                                        // functions defined above can be used to add more information while making the API call.
                                        description={`Order of ${mycarts.length} items!`}// the pop-in header subtitle
                                        image='LINKTOIMAGE'
                                        stripeKey={PUBLISHABLE_KEY}
                                        currency="AED"
                                        name="ICTKart" // the pop-in header title
                                        image="https://picsum.photos/id/237/200/300"
                                        ComponentClass="div"
                                        label='Pay Now'
                                        panelLabel="Pay Now" // prepended to the amount in the bottom pay button
                                        amount={(priceDetails.totalAmount - priceDetails.totalDiscount) * 100}
                                        locale="en"
                                        email="USER_EMAIL"
                                        // Note: enabling both zipCode checks and billing or shipping address will
                                        // cause zipCheck to be pulled from billing address (set to shipping if none provided).
                                        allowRememberMe // "Remember Me" option (default true)
                                        token={onToken} // submit callback
                                        opened={onOpened} // called when the checkout popin is opened (no IE6/7)
                                        closed={onClosed} // called when the checkout popin is closed (no IE6/7)
                                    // Note: `reconfigureOnUpdate` should be set to true IFF, for some reason
                                    // you are using multiple stripe keys
                                    // reconfigureOnUpdate={false}
                                    >
                                        {/* onClick={buyAllProducts}  */}
                                        <button className="btn btn_man w100 ttu lh32">
                                            BUY NOW
                                        </button>
                                    </StripeCheckout>
                                    :
                                    <Link to={'/service-check-out/bycart'} className="btn btn_man w100 ttu lh32">
                                        PLACE ORDER
                                    </Link>
                                }
                            </div>

                        </div>
                    </div>
                }
            </div>
        </>
    )
}
export default ViewCartCheckOutService;