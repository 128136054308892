import { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import SigninComponent from "./SigninComponent";
import secureStorage from "../../config/encrypt";
import { API_URL } from "../../config/constant";
import { toast } from "react-toastify";
import ProductSerach from "../product/ProductSerach";
import { useSelector } from "react-redux";
import store from "../../store/store"
import { useRef } from "react";
import ServiceFilter from "../Service/ServiceFilter";
import ProductNav from "./ProductNav";
import HeaderSearch from "./HeaderSearch";
function Header(props) {
    const [isUserLogin, setIsUserLogin] = useState(false);
    const [isFakeUserLogin, setIsFakeUserLogin] = useState(false);
    const [inCrat, setInCart] = useState(0);
    const [vCurrency, setVCurrency] = useState('AED');
    const [userData, setUserData] = useState({
        fullName: "",
        avatar: "",
        role: "user"
    });
    let inputElement = useRef();
    useEffect(() => {
        fakeLoaginStatus(props.fakeLogin || false);
        setInCart((inCrat + props.cart));
        getUserLogin();
    }, [props]);
    let { user } = store.getState();
    let inState = useSelector(state => {
        return state;
    });
    useEffect(() => {
        if (user.loginPopup) {
            inputElement.click();
        }
    }, [user])
    const fakeLoaginStatus = (status) => {
        setIsFakeUserLogin(status);
    }
    let history = useHistory();
    const getUserLogin = () => {
        let user = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY);
        user = (user && "user" in user) ? user.user : user;
        let carts = secureStorage.getItem(API_URL.COMMON_CART_STORE_KEY);
        if (user) {
            setVCurrency((user && user.currency) || 'AED')
            setUserData({
                fullName: user ? user.firstName + ' ' + user.lastName : "",
                avatar: user ? user.avatar : "",
                role: user ? user.role : "user",
            })
            setInCart(carts ? carts.length : 0);
            setIsUserLogin(true);
        }
    };
    const logoutUser = (e) => {
        e.stopPropagation();
        let user = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY);
        if (user) {
            secureStorage.removeItem(API_URL.COMMON_LOGIN_STORE_KEY);
            secureStorage.removeItem(API_URL.COMMON_TOKEN_STORE_KEY);
            secureStorage.removeItem(API_URL.COMMON_CART_STORE_KEY);
            toast.success("Logout successfully")
            setIsUserLogin(false);
        }
        if (history) history.push('/');
    }
    const filterForms = (param) => {
        switch (param) {
            case 'prolist':
            case 'prodetail':
            case 'vprofile':
                return <ProductSerach type={param} />;
            case 'servicelist':
            case 'servicedetail':
                return <ProductSerach type={param} />;
        }
    }

    // let inState = useSelector(state => {
    //     return state.product;
    // })
    // console.log(inState);
    // if ('cart' in inState) {
    //     setInCart(inState.cart);
    // }

    return (
        <>
            <>
                <meta charSet="utf-8" />
                <title>{props.metaTitle || ''}</title>
                <meta name="description" content={props.metaKewords || ''} />
                <meta name="keywords" content={props.metaDescription || ''} />
            </>
            <div className={(isUserLogin && props.header == 'header2') || isFakeUserLogin ? 'product_header' : ''} id={(isUserLogin && props.header == 'header2') || isFakeUserLogin ? 'top_menu' : ''}>
                <header id={(isUserLogin && props.header == 'header2') || isFakeUserLogin ? '' : 'top_menu'} className={(isUserLogin && props.header == 'header2') || isFakeUserLogin ? 'header2' : ''}>{
                    (props.showMenu == 1) ?
                        <div className="container" >
                            <nav className="navbar navbar2 navbar-expand-lg navbar-light">
                                <Link className="navbar-brand logo_shadow" to={"/"}>
                                    <img src={require("../../webroot/images/logo.png").default} alt="Logo" />
                                </Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav ml-auto">
                                        <li className="nav-item">
                                            <Link className="nav-link text-black" to={"/aboutus"}>About Us</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link text-black" to={'/faqs'}>FAQ</Link>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        :
                        <div className="container">
                            <ul className="header-top">
                                <li>
                                    <a href="#0"><img src={require("../../webroot/images/flag.png").default} alt="Flag" className="mr-2" />Country</a>
                                </li>
                                <li>
                                    <a href="#0">
                                        <img className="icon1 mr-2" src={require("../../webroot/images/globe.png").default} alt="Globe" />
                                        <img className="icon2 mr-2" src={require("../../webroot/images/globe-black.png").default} alt="Globe" />
                                        English</a>
                                </li>
                                <li>
                                    <a href="#0">
                                        {/* <img className="icon1 mr-2" src={require("../../webroot/images/rupee.png").default} alt="Rupee" /> */}
                                        {/* <img className="icon2 mr-2" src={require("../../webroot/images/rupee-black.png").default} alt="Rupee" /> */}
                                        {vCurrency}</a>
                                </li>
                                <li>
                                    <Link to={'/view-cart'} className="cart">
                                        <img className="icon1 mr-2" src={require("../../webroot/images/cart.png").default} alt="Cart" />
                                        <img className="icon2 mr-2" src={require("../../webroot/images/cart-black.png").default} alt="Cart" />
                                        <sup>{inCrat || 0}</sup></Link>
                                </li>
                                {isUserLogin ? null :
                                    <li>
                                        <Link to={"/become-partner"} className="btn btn-sm btn-primary">Become Partner</Link>
                                    </li>
                                }
                            </ul>
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <Link className="navbar-brand" to={"/"}><img src={require("../../webroot/images/logo.png").default} alt="Logo" /></Link>
                                {/* <form className="search-box">
                                    <select>
                                        <option>Category</option>
                                    </select>
                                    <div className="search-input">
                                        <input type="text" placeholder="Search by keyword" />
                                        <button type="button" className="search-btn"><img src={require("../../webroot/images/search.png").default} alt="Search" /></button>
                                    </div>
                                </form> */}
                                {props.enableSearch ? <HeaderSearch title={props.enableSearch} /> : null}
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav ml-auto">
                                        {/* {props.forNAV == 'product' ?
                                            <ProductNav /> : <> */}
                                        <li className="nav-item">
                                            <Link className="nav-link" to={'/products'}>Products</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/services"}>Services</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/solutions"}>Solutions</Link>
                                            {/* solutions */}
                                        </li>
                                        {/* </>
                                        } */}
                                        {/* <li className="nav-item">
                                            <Link className="nav-link" to="#">Support</Link>
                                        </li> */}
                                        {isUserLogin ? null :
                                            <>
                                                <li className="nav-item">
                                                    <a className="nav-link" ref={input => inputElement = input} data-toggle="modal" data-target="#SignIn">Sign In</a>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link join-btn" to={"/signup"}>Join</Link>
                                                </li>
                                            </>
                                        }
                                    </ul>
                                </div>
                                {isUserLogin ?
                                    <span className="nav-item user_drop">
                                        <a className="nav-link pr-0" data-toggle="dropdown" href="#">
                                            <img src={userData.avatar ? userData.avatar : require("../../webroot/img/profile.png").default} /> HI {userData.fullName}
                                            <i className="fa fa-angle-down" aria-hidden="true"></i>
                                        </a>
                                        <div className="dropdown-menu" style={{ "left": "auto" }}>
                                            {userData && userData.role == 'vendor' ? <>
                                                <div className="premiu_box d_flex mt-3 mr-3 ml-3">
                                                    <img src={require("../../webroot/images/star-r.png").default} alt="vender" />
                                                    <div className="">
                                                        <h5 className="color1 pb-0">Premium Plan</h5>
                                                        <small className=""> Renew on after 60 Days</small>
                                                    </div>
                                                </div>
                                                <NavLink className="dropdown-item" to={'/my/profile?tab=cp'}>Change Password</NavLink>
                                                {/* <NavLink className="dropdown-item" to={'/my/notification'}>Notifications</NavLink> */}
                                                <NavLink className="dropdown-item" to={'/dashboard'}>Settings </NavLink>
                                            </> : <>
                                                <NavLink className="dropdown-item" to={'/my/orders'}>My Orders</NavLink>
                                                <NavLink className="dropdown-item" to={'/my/services'}>Order Service</NavLink>
                                                <NavLink className="dropdown-item" to={'/my/profile'}>My Profile</NavLink>
                                                <NavLink className="dropdown-item" to={'/my/favourites'}>Favorites</NavLink>
                                                <NavLink className="dropdown-item" to={'/my/recentaly-view'}>Recently Viewed</NavLink>
                                                {/* <NavLink className="dropdown-item" to={'/dashboard'}>Suppoets</NavLink> */}
                                                {/* <NavLink className="dropdown-item" to={'/my/chat'}>Chat</NavLink> */}
                                                {/* <NavLink className="dropdown-item" to={'/my/notifications'}>Notifications</NavLink> */}
                                            </>}
                                            <a className="dropdown-item" onClick={logoutUser} href="#">Signout</a>
                                        </div>
                                    </span>
                                    : null
                                }
                            </nav>
                        </div>
                }
                </header>
                <SigninComponent show={isUserLogin} sendToHeader={setIsUserLogin} />
                {filterForms(props.showFilterProduct)}
            </div>
            {(isUserLogin && props.header == 'header2') || isFakeUserLogin ? <div className={props.spaceType == 'dashbord' ? 'space_dashbord' : 'space_header'}></div> : null}
        </>
    );
}

export default Header;