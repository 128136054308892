import { Link } from "react-router-dom";
import { Image, Shimmer } from 'react-shimmer'

const ServiceCard = ({ service }) => {
    return (
        <div className="shop-item item-style-two mb-3 ">
            <div>
                <Link className="card-img-wrapper" to={'services-detail/' + (service.category || "ss") + '/' + service._id}>
                    {/* <img src={service.thumbnail} alt="Service" className="mx-auto rounded-11" /> */}
                    <Image className="mx-auto rounded" src={service.thumbnail}
                        fallback={<Shimmer width={'100%'} height={177} />}
                    />
                </Link>
            </div>
            <div className="item-body">
                <div className="item-badge">
                    <div className="badge-left">
                        <span className="badge-img"><img src={service.vendor && service.vendor.avatar || require("../../webroot/img/profile.png").default} alt="" /></span>
                        <span>{service.vendor ? service.vendor.firstName + ' ' + service.vendor.lastName : ""}</span>
                    </div>
                    <span className="sheld"><img src={require("../../webroot/images/checked.png").default} alt="" /></span>

                </div>
                <div className="content_font">
                    <Link to={'services-detail/sss/' + service._id}>{service.title || ""}</Link>
                </div>
                <div className="shop-footer">
                    <span className="rating-box ml-0"><span><img src={require("../../webroot/images/star2.png").default} alt="Star" /></span> {service.averageRating || 0}</span>
                    <span className="stating"><span>Starting At</span> {service.basic ? service.basic.currency : ""} {service.basic ? service.basic.sellingPrice : ""}</span>
                </div>
            </div>
        </div>
    )
}
export default ServiceCard;