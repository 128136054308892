import secureStorage from "../config/encrypt";
import dateFormat from "dateformat";
import { API_URL, DATE_FORMAT_DMY } from "../config/constant";
export const isLogin = () => {
    let user = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY);
    user = (user && "user" in user) ? user.user : user;
    if (user) {
        return true;
    }
    return false;
}
export const userRole = () => {
    let user = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY);
    user = (user && "user" in user) ? user.user : user;
    if (user) {
        return user.role;
    }
    return;
}
export const logout = () => {
    secureStorage.removeItem(API_URL.COMMON_LOGIN_STORE_KEY)
    return true;
}
export const loginUserId = () => {
    let user = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY);
    user = (user && "user" in user) ? user.user : user;
    if (user) {
        return user._id;
    }
    return null;
}
export const parentId = () => {
    let user = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY);
    user = (user && "user" in user) ? user.user : user;
    if (user) {
        return user.vendor;
    }
    return null;
}
export const twodArr = (arr) => {
    let newArr = [];
    let copyArr = [...arr]
    for (let index = 0; index < Math.ceil(arr.length / 2); index++) {
        const elmArr = copyArr.splice(0, 2);
        newArr.push(elmArr)
    }
    return newArr;
}
export const displayDate = (d, formate = DATE_FORMAT_DMY) => {
    return dateFormat(d, formate);
}
export const displayCHAR = (str, l = 20) => {
    return str && str.length > l ? str.slice(0, l) + '...' : str;
}
export const userPermission = (page, action) => {
    let user = secureStorage.getItem(API_URL.COMMON_ROLE_KEY)
    if (user) {
        let permissions = JSON.parse(user.permissions) || {};
        // console.log(permissions);
        return permissions && permissions[page] && permissions[page][action];
    }
    return false;
}
export const verifyVendorAccount = () => {
    let user = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY);
    user = (user && "user" in user) ? user.user : user;
    if (user) {
        return user.verifyVendorAccount || false;
    }
    return false;
}