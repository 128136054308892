import { displayDate, loginUserId } from "../../utils"
import secureStorage from "../../config/encrypt";
import { UserServices } from "../../services";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link } from "react-router-dom";
import { API_URL } from "../../config/constant";
const MySwal = withReactContent(Swal);
const filePATH = API_URL.COMMON_GATEWAY_API + 'invoice/download/';
function UserMyOrders({ userData, formDashboard = false, title }) {
    const [myOrders, setMyOrders] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        getAllMyOrders({ title });
    }, [title])
    const getAllMyOrders = (queryObj) => {
        setIsLoading(true);
        queryObj = { ...queryObj, user: loginUserId() }
        UserServices.buyProductList(queryObj).then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setMyOrders(data.list)
            }
            else {
                toast.error(message);
            }
        })
    }
    const removeItem = (product) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: "Are you sure, you want to cancel this item!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, cancel it!',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Submit your reasion',
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Submit',
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (result.value && result.value.trim()) {
                            let obj = {
                                userId: loginUserId(),
                                buyProduct: product,
                                orderStatus: 'cancel',
                                reason: result.value.trim()
                            }
                            UserServices.changeStatus(obj).then((responce) => {
                                let { message, status } = responce;
                                if (status == 200) {
                                    toast.success(message);
                                }
                                else {
                                    toast.error(message);
                                }
                            })
                        }
                        else {
                            MySwal.fire('Reason required', '', 'info')
                        }
                    }
                })
            }
        })
    }
    const redirectURL = (item) => {
        if (item.type == 'product') {
            return "/product/ict/" + (item.product && item.product._id);
        }
        return "/services-detail/ict/" + (item.product && item.product._id);
    }
    return (
        <>
            {formDashboard && formDashboard == true ?
                <div className="table_responsive_scrool">
                    <table className="table_vender text-center">
                        <thead>
                            <tr>
                                <th>Seller</th>
                                <th>Product</th>
                                <th>Quantity</th>
                                <th>Order At</th>
                                <th>Total Amount</th>
                                <th>Current Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {myOrders && myOrders.length ? myOrders.map((item, indx) => {
                                return (
                                    <tr>
                                        <td>
                                            <img className="venter_img" src={item.thumbnailImage} />
                                            <span className="venter_name d_block">{item.product && item.product.vender ? item.product.vender.firstName + ' ' + item.product.vender.lastName : ''}</span>
                                        </td>
                                        <td>
                                            <img className="venter_product" src={item.product && item.product.thumbnailImage} />
                                            <small className="d_block">{item.product && item.product.title}</small>
                                        </td>
                                        <td>{item.items}</td>
                                        <td>{displayDate(item.createdAt)}</td>
                                        <td>{item.currency + ' ' + item.sellingPrice}</td>
                                        {/* <td><small className="d_block">RICHSIGN Leather Accessories Laptop Office Messenger</small></td> */}
                                        <td>{item.orderStatus}</td>
                                    </tr>
                                )
                            }) : <tr>
                                <td colSpan="5">NOT FOUND</td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                :
                <div className="col-lg-12">
                    <div className="d_boxs  mb-2 mb-md-4">
                        <div className=" pt-lg-0 dashbord-heading pb-2 hadding_border">
                            <h3 className="co8 pb-md-0">My {title}
                            </h3>
                            <div className="d_flex search_input mb-3 mb-md-4 mla">
                                <span className="pt-2 w90"> Sort by: </span>
                                <select className="ml_auto form-control" type="text">
                                    <option>Most Recent</option>
                                </select>
                            </div>
                        </div>
                        <div className="table_responsive_scrool">
                            <table className="table_vender text-center">
                                <thead>
                                    <tr>
                                        <th>Seller</th>
                                        <th>Product</th>
                                        <th>Quantity</th>
                                        <th>Order At</th>
                                        <th>Total Amount</th>
                                        <th>Current Status</th>
                                        <th>Type</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {myOrders && myOrders.length ? myOrders.map((item, indx) => {
                                        return (
                                            <tr key={'000_' + indx}>
                                                <td>
                                                    <div className="prof-container">
                                                        <img className="prof-img" src={item.vendor && item.vendor.profileImage} />
                                                        <div className="prof-content">
                                                            <h5 className="title">{item.vendor ? item.vendor.firstName + ' ' + item.vendor.lastName : ''}</h5>
                                                            <a className="sub-title">{item.vendor ? item.vendor.mobile : ''}</a>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="prof-container">
                                                        <img className="prof-img" src={item.product && item.product.thumbnailImage} />
                                                        <div className="prof-content">
                                                            <h5 className="title">{item.product && item.product.title}</h5>
                                                            <Link className="sub-title" to={redirectURL(item)}>
                                                                {item.type == 'product' ? (item.product && item.product.modelNumber) : "---"}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{item.items}</td>
                                                <td>{displayDate(item.createdAt)}</td>
                                                <td>{item.currency + ' ' + item.sellingPrice}</td>
                                                {/* <td><small className="d_block">RICHSIGN Leather Accessories Laptop Office Messenger</small></td> */}
                                                <td>{item.orderStatus}</td>
                                                <td>{item.type}</td>
                                                <td>
                                                    {['ordered', 'shipped'].includes(item.orderStatus) ?
                                                        <button className="btn btn-success btn-small" onClick={(e) => removeItem(item._id)}>Cancel</button> :
                                                        <button className="btn btn-danger btn-small" disabled>Cancel</button>
                                                    }
                                                    <a className="btn btn-primary btn-small" href={filePATH + item._id} download><i class="mdi mdi-cloud-download"></i>Invoice</a>
                                                    <Link to={'/my/orders-detail?productId=' + item._id + "&type=" + item.type}>View</Link>
                                                </td>
                                            </tr>
                                        )
                                    }) : <tr>
                                        <td colSpan="6">NOT FOUND</td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default UserMyOrders;