import Header from "../common/Header";
import Footer from "../common/Footer";
import NewsletterFormController from "../common/NewsletterFormController";
// import ServiceFilter from "./ServiceFilter";
import Banner from "../common/Banner";
import { Link } from "react-router-dom";
// import QuestionAnswer from "./QuestionAnswer";
import RatingReviews from "../product/RatingReviews";
import AboutSeller from "../vender/AboutSeller";
import ServiceCompaire from "../Service/ServiceCompaire";
import OwlCarousel from 'react-owl-carousel';
import { useState, useEffect } from "react";
import { UserServices } from "../../services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import store from "../../store/store"
import Loader from "../common/Loader";
import { useParams } from 'react-router-dom';

const ProductVendorProfile = () => {
    const [userProfile, setUserProfile] = useState({});
    const [isLoading, setIsLoading] = useState(true)
    let { vendorId } = useParams();
    let productData = {};
    useEffect(() => {
        if (vendorId) getUserProfile();
    }, [])
    const getUserProfile = async () => {
        UserServices.sellerProfile({ userId: vendorId }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setUserProfile(data.user);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsLoading(false);
        })
    }
    return (<>
        <Header showFilterProduct={'vprofile'} fakeLogin={true} showMenu={2} header={'header2'} spaceType={'product'} />
        <section className="pb-65">
            <div className="container">
                <div className="seller_part pt-md-5" id="VendorProfile" data-scroll-index="1">
                    <h5 className="mt-3 mb-md-3">About The Seller</h5>
                    <AboutSeller userProf={userProfile} showAbout={true} />
                </div>
                {/* <div id="Overview" data-scroll-index="1"></div> */}
                <div id="RatingReviews" className="reviews_part pt-3" data-scroll-index="3">
                    <RatingReviews rating={userProfile} fromReview={'profile'} itemId={vendorId} />
                </div>
            </div>
        </section>
        <NewsletterFormController />
        <Footer />
    </>)
}
export default ProductVendorProfile;