import HeaderSearch from "../common/HeaderSearch";
import { Image, Shimmer } from 'react-shimmer'

function HomeCarouselComponent({ slider, isLoading, firstTXT = "", secondTXT = "", fromAction = "", title = "" }) {
    return (
        <>
            <div id="carouselExampleCaptions" className="carousel banenr-section slide" data-ride="carousel">
                <ol className="carousel-indicators">
                    {
                        !isLoading && slider.length ? slider.map((slid, index) => {
                            return (
                                <li key={index} data-target="#carouselExampleCaptions" data-slide-to={index}></li>
                            )
                        }) : <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
                    }
                </ol>
                <div className="carousel-inner">
                    {
                        !isLoading && slider.length ? slider.map((slid, index) => {
                            return (
                                <div key={slid._id} className={"carousel-item" + (index == 0 ? ' active' : '')}>
                                    {/* <img src={slid.bannerImage} className="w-100" alt="Banner" /> */}
                                    <div className="w-100">
                                        <Image className="w-100" src={slid.bannerImage}
                                            fallback={<Shimmer width={'100%'} height={500} />}
                                        />
                                        {fromAction == "product" ? null :
                                            <span className="carousel-item-txt">
                                                <h1>
                                                    {slid.title}
                                                    <small>{slid.description}</small>
                                                </h1>
                                            </span>
                                        }
                                    </div>
                                </div>
                            )
                        }) : <div className="carousel-item active">
                            <Image className="w-100" src={require("../../webroot/images/slide01.jpg").default}
                                fallback={<Shimmer width={'100%'} height={500} />}
                            />
                        </div>
                    }
                    {fromAction == "product" ? null :
                        <div className="carousel-caption">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-7">
                                        {/* <h1><small>Find the perfect market place for </small>Products, Service and Solutions</h1> */}
                                        <HeaderSearch title={title ? title : 'Product'} />
                                        {/* <button type="button" className="btn btn_man mt-3"
                                            data-toggle="modal" data-target="#aboutus">AboutUs</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
export default HomeCarouselComponent;