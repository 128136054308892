function VenderTopRebben({ analysis, currency = "" }) {
    return (<div className="row row2 mt-md-3 mt-2">
        <div className="col-lg-3 col-md-3 col-sm-2">
            <div className="dashbord_box dashbord_box1 text-center">
                <div className="hadding_name">
                    MySales <img src={require("../../webroot/images/line-graph.png").default} className="mla" alt="graph" />
                </div>
                <h3 className="fw400 ">{currency} <b>{analysis.totalSales || 0}</b></h3>
            </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-2">
            <div className="dashbord_box dashbord_box2 text-center">
                <div className="hadding_name">
                    Orders Completed <img src={require("../../webroot/images/file-text2.png").default} className="mla" alt="graph" />
                </div>
                <h3 className="fw400 "> <b>{analysis.totalDelivered || 0}</b></h3>
            </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-2">
            <div className="dashbord_box dashbord_box3 text-center">
                <div className="hadding_name">
                    Services Completed <img src={require("../../webroot/images/files-empty.png").default} className="mla" alt="graph" />
                </div>
                <h3 className="fw400 "> <b>{analysis.totalServiceDelivered || 0}</b></h3>
            </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-2">
            <div className="dashbord_box dashbord_box4 text-center">
                <div className="hadding_name">
                    Solutions <img src={require("../../webroot/images/light-bulb.png").default} className="mla" alt="graph" />
                </div>
                <h3 className="fw400 "> <b>{analysis.totalSolutionDelivered || 0}</b></h3>
            </div>
        </div>
    </div>
    )
}
export default VenderTopRebben;