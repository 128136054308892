const Rating = ({ averageRating = 0 }) => {
    return (
        <>
            <i className={(averageRating > 0 ? 'act' : '') + " fa fa-star"}></i>
            <i className={(averageRating > 1 ? 'act' : '') + " fa fa-star"}></i>
            <i className={(averageRating > 2 ? 'act' : '') + " fa fa-star"}></i>
            <i className={(averageRating > 3 ? 'act' : '') + " fa fa-star"}></i>
            <i className={(averageRating > 4 ? 'act' : '') + " fa fa-star"}></i>
        </>
    )
}
export default Rating;