import { Link } from "react-router-dom";
import MyOrders from "../vender/VendorMyOrders";
import MyServices from "../vender/VendorMyServices";
import AccountSetting from "./AccountSetting";
import LeftMenu from "./LeftMenu";
import Notification from "./Notification";
import TopRiben from "./TopRiben";
import { verifyVendorAccount } from "../../utils";
function VenderDashboardComponent({ userData }) {

    return (
        <>
            <div className="col-lg-9">
                <div className="d_boxs pb-1 mb-2 mb-md-4">
                    {verifyVendorAccount() ?
                        <div className=" pt-lg-0 dashbord-heading pb-2">
                            <h5 className="box_hadding">Recently Added Items </h5>
                            <Link to={"/my/manage-product"} className="btn btn-primary mla btn-small">Add New Items</Link>
                        </div> : null
                    }
                    <MyOrders />
                </div>
                <div className="d_boxs pb-1 mb-2 mb-md-4">
                    <div className=" pt-lg-0 dashbord-heading pb-2">
                        <h5 className="box_hadding">My Services
                        </h5>
                        <a href="#" className="btn-link ">See all your products</a>
                    </div>
                    <MyServices />
                </div>

            </div>
        </>
    )
}
export default VenderDashboardComponent;