function ProfileCard({ profile, onChange }) {
    const uploadProfileImage = (e) => {
        onChange(e);
    }
    return (
        <>
            <div className="vender_user_box text-center">
                <div className="profile-pic-wrapper">
                    <div className="pic-holder">
                        <img id="profilePic" className="pic" src={profile.avatar ? profile.avatar :
                            require("../../webroot/img/profile.png").default
                        } />
                        <label htmlFor="newProfilePhoto" className="upload-file-block">
                            <div className="text-center">
                                <div className="mb-2">
                                    <i className="fa fa-camera fa-2x"></i>
                                </div>
                                <div className="text-uppercase">
                                    Update <br /> Profile Photo
                                </div>
                            </div>
                        </label>
                        <input onChange={uploadProfileImage} className="uploadProfileInput" type="file" name="profile_pic" id="newProfilePhoto" accept="image/*" />
                    </div>
                </div>

                <div className="vender_user_name ">
                    <h5 className="color1 pb-0"> {profile.firstName + ' ' + profile.lastName}</h5>
                    <span className=""> {profile.email}</span>
                    <img src={require("../../webroot/images/checked.png").default} alt="vender" />
                </div>

                {/* <div className="premiu_box d_flex">
                    <img src={require("../../webroot/images/star-r.png").default} alt="vender" />
                    <div className="">
                        <h5 className="color1 pb-0">Premium Plan</h5>
                        <small className=""> Renew on after 60 Days</small>
                    </div>
                </div> */}
            </div>
            {/* <div className="quick_links">
                <ul>
                    <li><a href="#" className="action">Company Overview</a></li>
                    <li><a href="#" className="">Team</a></li>
                    <li><a href="#" className=""> Roles</a></li>
                    <li><a href="#" className=""> Products</a></li>
                    <li><a href="#" className="">  Gallery</a></li>
                    <li><a href="#" className="">  Terms &amp; Conditions</a></li>
                </ul>
            </div> */}
        </>
    )
}
export default ProfileCard;