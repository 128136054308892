import axios from "axios";
import { API_URL, COMMON_TOKEN_STORE_KEY } from "../config/constant";
import secureStorage from "../config/encrypt";
// import { toast } from 'react-toastify';
axios.interceptors.response.use(
    response => {
        if (response && response.data && response.data.status !== 200) {
            // console.log('response.data', response.data)
            return response.data;
        }
        return response.data;
    },
    error => {
        if (error.response) {
            if (error.response.status === 401) {
                window.location.href = "/logout";
            }
            else {
                return error.response.data;
            }
        }
        return error && error.response.data;
    }
);

export default {
    getToken() {
        return secureStorage.getItem(API_URL.COMMON_TOKEN_STORE_KEY) || null;
    },
    post(URL, body, headers) {
        headers = headers || {};
        headers["Authorization"] = this.getToken();//'Basic ' + btoa('ictkartvender' + ':' + 'RAO@8HV!e*ZO6~x5a3y');
        // headers["Accept-Language"] = secureStorage.getItem("lang");
        URL = URL[0] === "/" ? URL.substr(1) : URL;
        return axios.post(`${API_URL.COMMON_GATEWAY_API}${URL}`, body, { headers });
    },
    put(URL, body, headers) {
        headers = headers || {};
        headers["Authorization"] = this.getToken();//'Basic ' + btoa('ictkartvender' + ':' + 'RAO@8HV!e*ZO6~x5a3y');
        // headers["Accept-Language"] = secureStorage.getItem("lang");
        // URL = URL[0] === "/" ? URL.substr(1) : URL;
        return axios.put(`${API_URL.COMMON_GATEWAY_API}${URL}`, body, { headers });
    },
    get(URL, query, headers) {
        headers = headers || {};
        headers["Authorization"] = this.getToken();
        // headers["Accept-Language"] = secureStorage.getItem("lang");
        URL = URL[0] === "/" ? URL.substr(1) : URL;
        return axios.get(`${API_URL.COMMON_GATEWAY_API}${URL}`, {
            params: query,
            headers
        });
    },
    externalGet(URL, query, headers) {
        headers = headers || {};
        headers["Authorization"] = this.getToken();
        return axios.get(`${URL}`, {
            params: query,
            headers
        });
    }
};
