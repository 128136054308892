import Footer from "../common/Footer"
import Header from "../common/Header";
import Loader from "../common/Loader";
import OwlCarousel from 'react-owl-carousel';
import NewsletterFormController from "../common/NewsletterFormController";
import HeaderSearch from "../common/HeaderSearch";
import LatestViewProduct from "./LatestViewProduct";
import BestSellingProducts from "./BestSellingProducts";
import ProductCard from "./ProductCard";
import { useState } from "react";
import { useEffect } from "react";
import { UserServices } from "../../services";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { twodArr } from "../../utils/index"
import ProductFilterCategory from "./ProductFilterCategory";
import { Image, Shimmer } from 'react-shimmer';
import HomeCarouselComponent from "../Home/HomeCarouselComponent";
import { useSelector } from "react-redux";
import store from "../../store/store"
import Banner from "../common/Banner";
import { options, wnoptions, options6 } from "../../config/constant";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { ShimmerPostItem } from "react-shimmer-effects";

function ProductListComponent() {
    const [productSlider, setProductSlider] = useState([]);
    const [productsArr, setProductsArr] = useState([{}, {}]);
    const [featuredProducts, setFeaturedProducts] = useState([{}, {}]);
    const [isLoading, setIsLoading] = useState(true);
    const [isProductLoading, setIsProductLoading] = useState(true);
    const [categoriesArr, setCategoriesArr] = useState([]);
    const [subCategoriesArr, setSubCategoriesArr] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [pramotionBanner, setPramotionBanner] = useState({});
    const history = useHistory();
    useEffect(() => {
        getAllCategories();
        getFeaturedProducts();
        getSilder();
        getAllPramotionBanner();
        getProducts();
    }, []);
    const getAllPramotionBanner = async () => {
        UserServices.pramotionalBanner({ displayAt: 'product' }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setPramotionBanner(data);
            }
            else {
                toast.error(message);
            }
        }).catch((error) => {
            toast.error(error);
        })
    }
    let inState = useSelector(state => {
        return state.product;
    });
    let { product } = store.getState();
    // useEffect(() => {
    //     if (product && product.search) {
    //         getProducts(product.search);
    //     }
    // }, [product]);
    const getAllCategories = () => {
        UserServices.categoriesDropDownList('product').then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setCategoriesArr((data.list));
            }
        })
    }
    const getSilder = async () => {
        UserServices.bannerList('product').then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setProductSlider(data.banners);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsLoading(false);
        })
    }
    const getProducts = async (search = '') => {
        UserServices.productAllList({ sort: 'new', limit: 10 }).then((responce) => {
            let { data, message, statusCode } = responce;
            setProductsArr([...(data.list)]);
            // console.log(productsArr, data.list);
            setIsProductLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsProductLoading(false);
        })
    }
    const getFeaturedProducts = async (search = '') => {
        UserServices.feturesProductAllList({}).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setFeaturedProducts((data.list));
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsLoading(false);
        })
    }
    const showSubCategory = (subCate) => {
        setSubCategoriesArr(subCate.subcategories);
        setSelectedCategory(subCate._id);
    }
    const gotoProductList = (c) => {
        if (history) history.push('/products/list?cate=' + c._id);
    }
    return (
        <>
            <Header forNAV={'product'} enableSearch={'Product'} />
            {/* <Loader isLoading={isLoading} /> */}
            <div className="product_banenr banenr-section slide" >
                <div className="container">
                    <div className="row align-items-center pt-35">
                        <div className="col-lg-5 col-md-6">
                            <h1><small>{!isLoading && productSlider.length ? productSlider[0]['title'] : ''}</small>  </h1>
                            <p className="text-white banner_p">
                                {!isLoading && productSlider.length ? productSlider[0]['description'] : ''}
                            </p>
                            <HeaderSearch title={'Product'} />
                        </div>
                        <div className="col-lg-2 col-md-1 pt-3">
                        </div>
                        <div className="col-md-5 col-lg-5">
                            <HomeCarouselComponent
                                slider={productSlider}
                                isLoading={isLoading}
                                firstTXT={'Increase your productivity'}
                                secondTXT={'Work and play on the go with the new google Pixelbook'}
                                fromAction={"product"}
                                title={'Product'}
                            />
                            {/* {
                                !isLoading && productSlider.length ? <img src={productSlider[0]['bannerImage']} alt="Search" />
                                    : <img src={require("../../webroot/images/aed.png").default} alt="Search" />
                            }
                            <Link className="banner_shopnow" to={"/" + (productSlider.length && productSlider[0]['targetUrl'])}>Shop Now</Link> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light bg-lightt">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav nav-color-blck">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-bars" aria-hidden="true"></i> All <i className="fa fa-angle-down" aria-hidden="true"></i>
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <ul>
                                        {categoriesArr && categoriesArr.length && categoriesArr.map((c, i) => {
                                            return (
                                                <li className="submegamenu dropdown-item" key={i}>
                                                    <Link to={'/products/list?cate=' + c._id}>
                                                        {c.label}
                                                        {c.subcategories && c.subcategories.length ? <i className="fa fa-angle-right" aria-hidden="true"></i> : null}
                                                    </Link>
                                                    {c.subcategories && c.subcategories.length ?
                                                        <ul className="mcsc row" id="sub_cat">

                                                            {/* <ul className="mcsc-list"> */}
                                                            {c.subcategories.map((sc, si) => {
                                                                return (
                                                                    <li className="mcsc-iteam col-md-4">
                                                                        <Link key={i + si} className="dropdown-item" to={'/products/list?cate=' + sc._id}>{sc.label}</Link>
                                                                    </li>
                                                                )
                                                            })}
                                                            {/* </ul> */}

                                                        </ul> : null
                                                    }
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </li>
                            {categoriesArr && categoriesArr.length && categoriesArr.slice(0, 6).map((c, i) => {
                                return (
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle image-dd" to={!c.subcategories.length ? '/products/list?cate=' + c._id : '#'} id={"navbarDropdownMenuLink_" + i} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <Image className="mx-auto rounded-11" src={c.icon}
                                                fallback={<Shimmer width={30} height={30} />}
                                            />
                                            {c.label} &nbsp;
                                            {c.subcategories && c.subcategories.length ? <i className="fa fa-angle-down" aria-hidden="true"></i> : null}
                                        </Link>
                                        {c.subcategories && c.subcategories.length ?
                                            <div className="dropdown-menu" aria-labelledby={"navbarDropdownMenuLink_" + i}>
                                                {c.subcategories.map((sc, si) => {
                                                    return (
                                                        <Link key={i + si} className="dropdown-item" to={'/products/list?cate=' + sc._id}>{sc.label}</Link>
                                                    )
                                                })}
                                            </div> : null
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </nav>
            </div>
            <div className="middle-section">
                <section className=" pb-50">
                    <div className="container">
                        <div className="heading-title">
                            <h2>Today’s top picks</h2>
                            {/* <ProductFilterCategory categoriesArr={categoriesArr} /> */}
                            <p>in list of what’s moving</p>
                        </div>
                        <div className="row align-items-center ">
                            <div className="col-lg-6 mb-3 mb-md-0">
                                <img src={require("../../webroot/images/increase.png").default} alt="Shop" />
                            </div>
                            <div className="col-lg-6">
                                <div className="pt-lg-0 shop-heading pb-2">
                                    <Link to={"/products/list"} className="btn-link">View All</Link>
                                </div>
                                {isProductLoading ?
                                    <OwlCarousel className="owl-carousel items-carousel pt-xl-3" {...wnoptions}>
                                        {
                                            [1, 1, 1, 1].map((mObj, index) => {
                                                return (
                                                    <div key={'parent111' + index} className="item">
                                                        <ShimmerPostItem card title cta imageHeight={80} contentCenter />
                                                    </div>
                                                )
                                            })
                                        }
                                    </OwlCarousel> : <>
                                        <OwlCarousel className="owl-carousel items-carousel pt-xl-3" {...wnoptions}>
                                            {
                                                productsArr && productsArr.map((mObj, index) => {
                                                    return (
                                                        <div key={'parent' + index} className="item">{
                                                            ('_id' in mObj) ? <ProductCard productData={mObj} key={'child' + index} /> :
                                                                <ShimmerPostItem card title cta imageHeight={80} contentCenter />
                                                        }</div>
                                                    )
                                                })
                                            }
                                        </OwlCarousel>
                                        <OwlCarousel className="owl-carousel items-carousel pt-xl-3 remove-prev-next" {...wnoptions}>
                                            {
                                                !isProductLoading && productsArr && productsArr.reverse().map((mObj, index) => {
                                                    return (
                                                        <div key={'parent' + index} className="item">{
                                                            ('_id' in mObj) ? <ProductCard productData={mObj} key={'child' + index} /> :
                                                                <ShimmerPostItem card title cta imageHeight={80} contentCenter />
                                                        }</div>
                                                    )
                                                })
                                            }
                                        </OwlCarousel>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {pramotionBanner && pramotionBanner.first && pramotionBanner.first.image ?
                    <section>
                        <Banner img={pramotionBanner.first.image} />
                    </section> : null
                }
                <section className="pt-65 pb-50">
                    <div className="container">
                        <div className="heading-title">
                            <h2>Featured Products</h2>
                            <p>Investigate the best in class items, across the board place.</p>
                        </div>
                        <div className="row pt-md-3 pt-0">

                            <div className="col-lg-6">
                                <div className=" pt-lg-0 shop-heading pb-2">
                                    <Link to={"/products/list"} className="btn-link">View All</Link>
                                </div>
                                <OwlCarousel className="owl-carousel items-carousel pt-xl-3" {...wnoptions}>
                                    {
                                        featuredProducts && featuredProducts.map((mObj, index) => {
                                            return (
                                                <div key={'parent' + index} className="item">{
                                                    ('_id' in mObj) ? <ProductCard productData={mObj} key={'child' + index} /> :
                                                        <ShimmerPostItem card title cta imageHeight={80} contentCenter />
                                                }</div>
                                            )
                                        })
                                    }
                                </OwlCarousel>
                                <OwlCarousel className="owl-carousel items-carousel pt-xl-3" {...wnoptions}>
                                    {
                                        featuredProducts && featuredProducts.reverse().map((mObj, index) => {
                                            return (
                                                <div key={'parent' + index} className="item">{
                                                    ('_id' in mObj) ? <ProductCard productData={mObj} key={'child' + index} /> :
                                                        <ShimmerPostItem card title cta imageHeight={80} contentCenter />
                                                }</div>
                                            )
                                        })
                                    }
                                </OwlCarousel>
                            </div>
                            <div className="col-lg-6 pt-5">
                                <img src={require("../../webroot/images/save.jpg").default} alt="Services" />
                            </div>
                        </div>
                    </div>
                </section>
                {pramotionBanner && pramotionBanner.first && pramotionBanner.first.image ?
                    <section className="">
                        <Banner img={pramotionBanner.first.image} />
                    </section> : null
                }
                <section className="">
                    <div className="container">
                        <div className="product_service">
                            <div className="row ">
                                <div className="col-md-4">
                                    <div className="product_service_box">
                                        <span className="icon_img"><img src={require("../../webroot/images/deliver.png").default} alt="deliver" /></span>
                                        <h4>Ready in one hour with<br />Curbside Packup. </h4>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="product_service_box">
                                        <span className="icon_img"><img src={require("../../webroot/images/shipped.png").default} alt="shipped" /></span>
                                        <h4>Free next-day delivery<span> on thousands of items.</span> </h4>

                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="product_service_box">
                                        <span className="icon_img"><img src={require("../../webroot/images/delivery.png").default} alt="delivery" /></span>
                                        <h4>Same-day delivery. <span>Order by 3 p.m. get it by 9 p.m.</span> </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <LatestViewProduct /> */}
                <BestSellingProducts />
                <section className="">
                    <div className="container">
                        <img src={require("../../webroot/images/banner3.png").default} alt="Banner" />
                    </div>
                </section>
                <section className=" pt-50 pb-50 std">
                    <div className="container">
                        <div className="row ">
                            <div className="col-md-4">
                                <div className="product_service_box">
                                    <span className="icon_img"><img src={require("../../webroot/images/verify.png").default} alt="verify" /></span>
                                    <h4>Shop safely and confidently. <span>See our safety procedures</span></h4>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="product_service_box">
                                    <span className="icon_img"><img src={require("../../webroot/images/deals.png").default} alt="deals" /></span>
                                    <h4>Top Deals.<span> Explore the latest and greatest, all in one place.</span> </h4>

                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="product_service_box">
                                    <span className="icon_img"><img src={require("../../webroot/images/handshake.png").default} alt="handshake" /></span>
                                    <h4>Deal of the Day. <span>Freat deals. Every Day.</span> </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {pramotionBanner && pramotionBanner.first && pramotionBanner.first.image ?
                    <section className="">
                        <Banner img={pramotionBanner.first.image} />
                    </section> : null
                }
            </div>
            <NewsletterFormController />
            <Footer />
        </>
    );
}

export default ProductListComponent;