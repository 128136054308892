import { useEffect, useState } from "react";
import Select from 'react-select';
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Loader from "../common/Loader";
import { Calendra } from "../elements/DatePicker";
import { useParams, useHistory } from 'react-router-dom';
import productSample from "../../webroot/sample-file/productexcel.xls";
import { SPECIFICATIONS } from "../../config/constant";
import { LOGICAL_OPERATORS } from "@babel/types";
import { userPermission, parentId, verifyVendorAccount } from "../../utils";
import RowLoader from "../common/RowLoader";
let formArr = {
    key: "", label: "", value: ""
};
const prodObj = {
    title: "",
    modelNumber: "",
    partNumber: "",
    manufacturer: "",
    brand: "",
    totalUnits: 0,
    countries: [],
    releaseDate: "",
    expiryDate: "",
    sellingPrice: "",
    originalPrice: "",
    conditions: "",
    description: "",
    categories: "",
    images: [],
    casingMaterial: "",
    colorCategory: ""
}
const setProductImagesArr = [
    { img: "", file: "" },
    { img: "", file: "" },
    { img: "", file: "" },
    { img: "", file: "" },
    { img: "", file: "" }
]
function ManageProduct({ userId, userData, productType }) {
    const [productForm, setProductForm] = useState(prodObj);
    const [manufacturerArr, setManufacturerArr] = useState([]);
    const [brandsArr, setBrandsArr] = useState([]);
    const [categoriesArr, setCategoriesArr] = useState([]);
    const [subcategoriesArr, setSubcategoriesArr] = useState([]);
    const [subcategoriesArr3, setSubcategoriesArr3] = useState([]);
    const [countriesArr, setCountriesArr] = useState([]);
    const [productImages, setProductImages] = useState(setProductImagesArr);
    const [isLoading, setIsLoading] = useState(false);
    const [actionType, setActionType] = useState("Add");
    const { productId } = useParams();
    const history = useHistory();
    const [specificationForm, setSpecificationForm] = useState([formArr]);
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isSearchModel, setIsSearchModel] = useState(false);
    const [isUpdated, setIsUpdated] = useState({});
    const [removeProduct, setRemoveProduct] = useState([]);
    const [existingImages, setExistingImages] = useState([]);
    // console.log(productId);
    useEffect(() => {
        getAllBrands();
        getAllCategories();
        getCountryList();
        getManufacturerList();
        if (productId) {
            getProductDetail();
            setActionType("Update")
        }
    }, []);
    const getProductDetail = () => {
        UserServices.editProduct({ productId }).then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                if (data && data.categoryObj) {
                    setSubcategoriesArr(data.categoryObj.subcategories);
                    data.categoryObj.subcategories.map(obj => {
                        if (data.categories2 && data.categories2.toString() == obj.value.toString()) {
                            setSubcategoriesArr3(obj.subcategories3);
                        }
                        return obj;
                    })
                }
                delete data.categoryObj;
                setProductForm(data);
                let imageArr = [];
                data && data.images.map((m, i) => {
                    setProductImagesArr[i] = { 'file': '', 'img': m };
                    if (m && m.length) {
                        let imgURL = m.split("/").pop();
                        imageArr.push(imgURL);
                    }
                })
                setExistingImages([...imageArr]);
                setProductImages(setProductImagesArr);
            }
            else {
                toast.error(message);
            }
        })
    }
    const getManufacturerList = () => {
        UserServices.brandDropdownList('manufactur').then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setManufacturerArr(data);
            }
        })
    }
    const getAllBrands = () => {
        UserServices.brandDropdownList('brand').then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setBrandsArr(data);
            }
        })
    }
    const getAllCategories = () => {
        UserServices.categoriesDropDownList().then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setCategoriesArr(data.list);
            }
        })
    }
    const getCountryList = () => {
        UserServices.countryList().then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setCountriesArr(data);
            }
        })
    }
    const changeHandler = (e, name = "") => {
        if (name) {
            switch (name) {
                case 'categories':
                    setProductForm((prev) => ({
                        ...prev,
                        categories: e.value
                    }))
                    setSubcategoriesArr(e.subcategories);
                    setSubcategoriesArr3([]);
                    break;
                case 'categories2':
                    setProductForm((prev) => ({
                        ...prev,
                        categories2: e.value
                    }));
                    setSubcategoriesArr3(e.subcategories3);
                    break;
                case 'categories3':
                    setProductForm((prev) => ({
                        ...prev,
                        categories3: e.value
                    }))
                    break;
                case 'countries':
                    let countries = e.map(obj => obj.name);
                    setProductForm((prev) => ({
                        ...prev,
                        countries: countries
                    }))
                    break;
                case 'manufacturer':
                    setProductForm((prev) => ({
                        ...prev,
                        manufacturer: e.value
                    }))
                    break;
                case 'brand':
                    setProductForm((prev) => ({
                        ...prev,
                        brand: e.value
                    }))
                    break;
                case 'releaseDate':
                    setProductForm((prev) => ({
                        ...prev,
                        releaseDate: e
                    }))
                    break;
                case 'expiryDate':
                    setProductForm((prev) => ({
                        ...prev,
                        expiryDate: e
                    }))
                    break;
            }
        }
        else {
            setProductForm((prev) => ({
                ...prev,
                [e.target.name]: e.target.value
            }))
        }
    }
    const submitProductForm = (e) => {
        setIsLoading(true);
        setIsUpdated((prev) => ({ ...prev, 'product': true }))
        if (productId) {
            delete productForm.vender;
            productForm.productId = productId;
            UserServices.updateProduct(productForm).then((responce) => {
                let { data, message, statusCode } = responce;
                if (statusCode == 200) {
                    toast.success(message);
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
                setIsUpdated((prev) => ({ ...prev, 'product': false }))
            })
        }
        else {
            productForm.vender = parentId();
            UserServices.addProduct(productForm).then((responce) => {
                let { data, message, statusCode } = responce;
                if (statusCode == 200) {
                    toast.success(message);
                    if (history) history.push('/my/update-product/' + data._id);
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
                setIsUpdated((prev) => ({ ...prev, 'product': false }))
            })
        }
    }
    const changeFile = (e, i) => {
        // var proIMAGE = [...productImages];
        // const reader = new FileReader();
        // reader.readAsDataURL(e.target.files[0]);
        // reader.onloadend = () => {
        // setProductImagesArr
        let existingImagesArr = [...removeProduct, existingImages[i]];
        setRemoveProduct([...existingImagesArr]);
        productImages[i] = {
            img: URL.createObjectURL(e.target.files[0]),
            file: e.target.files[0]
        }
        // }
        setProductImages([...productImages])
    }
    const submitProductImage = (e) => {
        setIsUpdated((prev) => ({ ...prev, 'productimage': true }));
        const formData = new FormData();
        productImages.filter(obj => {
            if (obj.file != "") {
                formData.append('images', obj.file);
                return 1;
            }
        })
        formData.append('productId', productId);
        formData.append('removeimage', JSON.stringify(removeProduct));
        UserServices.updateProductImages(formData).then((responce) => {
            let { message, statusCode } = responce;
            if (statusCode == 200) {
                toast.success(message);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
            setIsUpdated((prev) => ({ ...prev, 'productimage': false }));
        })
    }

    const addMoreRow = (e) => {
        setSpecificationForm([...specificationForm, ...[formArr]]);
    }
    const formArrayChange = (i, name, e) => {
        let vl = {};
        if (name == 'label') {
            vl = e;
        }
        else {
            vl = { value: e.target.value };
        }
        let specificationFormArr = specificationForm.map((obj, index) => {
            if (i == index) {
                obj = { ...obj, ...vl }
            }
            return obj;
        });
        setSpecificationForm([...specificationFormArr]);
    }
    const submitSpecification = () => {
        let speciObj = {
            productId: productId,
            specifications: specificationForm
        }
        UserServices.updateProduct(speciObj).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                toast.success(message);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    const onBlurHandler = (e) => {
        if (productType == 'add') {
            if (!productForm.modelNumber) return;
            setIsSearchModel(true);
            UserServices.editProduct({ modelNumber: productForm.modelNumber }).then((responce) => {
                let { data, message, status } = responce;
                if (status == 200 && 'modelNumber' in data) {
                    delete data._id;
                    setProductForm(data);
                    data && data.images.map((m, i) => {
                        setProductImagesArr[i] = { 'file': '', 'img': m }
                    })
                    setProductImages(setProductImagesArr);
                }
                else {
                    // toast.error(message);
                }
                setIsSearchModel(false);
            })
        }
    }
    const inputHandler = (e) => {
        if (e.target.files) {
            setSelectedFile(e.target.files[0]);
            setIsFilePicked(true);
        }
    }
    const importExcel = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', selectedFile);
        if (isFilePicked) {
            setIsUpdated((prev) => ({ ...prev, 'excel': true }));
            UserServices.importExcelFile(formData, 'product').then((responce) => {
                let { message, statusCode } = responce;
                window.location.reload();
                if (statusCode == 200) {
                    toast.success(message);
                }
                else {
                    toast.error(message);
                }
                setIsUpdated((prev) => ({ ...prev, 'excel': false }));
            })
        }
    }
    return (
        <div className="col-lg-12">
            <Loader isLoading={isLoading} />
            <div className="d_boxs  mb-2 mb-md-4">
                <div className=" pt-lg-0 dashbord-heading pb-2 hadding_border">
                    <h3 className="co8 pb-md-0">{actionType} product</h3>
                </div>
                <ul className="nav my_profile_tab mb-2 mb-md-5">
                    <li className="nav-item">
                        <a className=" active" data-toggle="tab" href="#product">Product</a>
                    </li>
                    <li className="nav-item">
                        <a className="" data-toggle="tab" href="#productImage">Product Images </a>
                    </li>
                    <li className="nav-item">
                        <a className="" data-toggle="tab" href="#productSpecification">Product Features/Specifications </a>
                    </li>
                    <li className="nav-item">
                        <a className="" data-toggle="tab" href="#uploadProducts">Upload Product</a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane active " id="product">
                        <h6 className="color_man">COMPLETED ORDERS</h6>
                        <div className="row">
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Model Number</label>
                                <div className="inp-group">
                                    <input
                                        type="text"
                                        onChange={changeHandler}
                                        name="modelNumber"
                                        value={productForm.modelNumber}
                                        className="form-control"
                                    />
                                    <div disabled className="input-group-append inp-group-append" onClick={onBlurHandler}>
                                        <span className="input-group-text">{isSearchModel ? <i className="fa fa-spinner fa-spin"></i> : null} Map</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Part Number/ Product ID </label>
                                <input type="text" onChange={changeHandler} name="partNumber" value={productForm.partNumber} className="form-control" />
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Product Name</label>
                                <input type="text" onChange={changeHandler} name="title" value={productForm.title} className="form-control" />
                            </div>
                            {/* <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Manufacturer</label>
                                <Select
                                    onChange={(e) => changeHandler(e, 'manufacturer')} name="manufacturer"
                                    value={manufacturerArr.filter(obj => productForm.manufacturer == (obj.value))}
                                    options={manufacturerArr}
                                    isClearable
                                />
                            </div> */}
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Brand</label>
                                <Select
                                    onChange={(e) => changeHandler(e, 'brand')} name="brand"
                                    value={brandsArr.filter(obj => productForm.brand == (obj.value))}
                                    options={brandsArr}
                                    isClearable
                                />
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="required"> Category</label>
                                <Select
                                    onChange={(e) => changeHandler(e, 'categories')} name="categories"
                                    value={categoriesArr.filter(obj => productForm.categories == (obj.value))}
                                    options={categoriesArr}
                                    isClearable
                                />
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label> Category2</label>
                                <Select
                                    onChange={(e) => changeHandler(e, 'categories2')} name="categories2"
                                    value={subcategoriesArr.filter(obj => productForm.categories2 == (obj.value))}
                                    options={subcategoriesArr}
                                    isClearable
                                />
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label> Category3</label>
                                <Select
                                    onChange={(e) => changeHandler(e, 'categories3')} name="categories3"
                                    value={subcategoriesArr3.filter(obj => productForm.categories3 == (obj.value))}
                                    options={subcategoriesArr3}
                                    isClearable
                                />
                            </div>


                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Country of origin </label>
                                <Select
                                    onChange={(e) => changeHandler(e, 'countries')} name="countries"
                                    value={countriesArr.filter(obj => productForm.countries.includes(obj.name))}
                                    options={countriesArr}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.name}
                                    isClearable
                                    isMulti
                                />
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Item Quantity </label>
                                <input type="text" onChange={changeHandler} name="totalUnits" value={productForm.totalUnits} className="form-control number" />
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Casing Material</label>
                                <input type="text" onChange={changeHandler} name="casingMaterial" value={productForm.casingMaterial} className="form-control" />
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Color Category</label>
                                <input type="text" onChange={changeHandler} name="colorCategory" value={productForm.colorCategory} className="form-control" />
                            </div>
                        </div>
                        <h6 className="color_man">Price Informations</h6>
                        <div className="row">
                            <div className="form-group col-lg-3 col-md-3 col-sm-3 mb-md-3">
                                <label className="">Release Date</label>
                                <Calendra
                                    className="form-control"
                                    value={productForm.releaseDate}
                                    name="releaseDate"
                                    onChange={changeHandler}
                                />
                            </div>
                            <div className="form-group col-lg-3 col-md-3 col-sm-3 mb-md-3">
                                <label className="">Expiry Date</label>
                                <Calendra
                                    className="form-control"
                                    value={productForm.expiryDate}
                                    name="expiryDate"
                                    onChange={changeHandler}
                                />
                            </div>

                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Listed (Original) Price </label>
                                <div className="position_relative_inr">
                                    <input type="text" onChange={changeHandler} name="originalPrice" value={productForm.originalPrice} className="form-control decimal" />
                                    <span className="inr_a">{userData && userData.companyInfo ? userData.companyInfo.currency : ""}</span>
                                </div>
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Promotional (Selling) Price </label>
                                <div className="position_relative_inr">
                                    <input type="text" onChange={changeHandler} name="sellingPrice" value={productForm.sellingPrice} className="form-control decimal" />
                                    <span className="inr_a">{userData && userData.companyInfo ? userData.companyInfo.currency : ""}</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-md-4">
                            <label className="">Condition</label>
                            <textarea className="form-control" onChange={changeHandler} name="conditions" value={productForm.conditions}></textarea>
                        </div>
                        <div className="form-group mb-md-4">
                            <label className="">Descriptions</label>
                            <textarea className="form-control" onChange={changeHandler} name="description" value={productForm.description}></textarea>
                        </div>
                        {userPermission('product', 'add') && verifyVendorAccount() ?
                            <>
                                <button type="button" onClick={submitProductForm} className="btn btn-primary pr-4 pl-4">SUBMIT</button>
                                {isUpdated && isUpdated.product ? <div className="form-group mb-md-12"><RowLoader /></div> : null}
                            </>
                            : null
                        }
                    </div>
                    <div className="tab-pane" id="productImage">
                        <div className="row row2 col5 mb-md-4">
                            {
                                productImages.map((obj, index) => {
                                    return (
                                        <div key={index} className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="add_pravu_item">
                                                {/* <a className="add_delite"><i className="fa fa-trash-o"></i>
                                                </a> */}
                                                <div className="add_pravu_img">
                                                    <img src={obj.img} />
                                                </div>
                                                <label className="rao-file-label">
                                                    <i className="fa fa-upload"></i>&nbsp;&nbsp;
                                                    <span>Choose a file…</span>
                                                    <input type="file" onChange={(e) => changeFile(e, index)}
                                                        name="profile_pic"
                                                        className="rao-file-input inputfile"
                                                        accept="image/*"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {userPermission('product', 'add') && verifyVendorAccount() ?
                            <>
                                <button type="button" onClick={submitProductImage} className="btn btn-primary pr-4 pl-4">Upload Image </button>
                                {isUpdated && isUpdated.productimage ? <div className="form-group mb-md-12"><RowLoader /></div> : null}
                            </>
                            : null
                        }
                    </div>
                    <div className="tab-pane" id="productSpecification">
                        <button className="btn btn-gradient-success btn-sm" onClick={addMoreRow.bind(this)}> <i className="mdi mdi-plus-box"></i> Add new row</button>
                        {
                            specificationForm && specificationForm.map((br, index) => {
                                return (<div className="row" key={index}>
                                    <div className="form-group col-sm-5">
                                        <label htmlFor="brand1">Label</label>
                                        <Select
                                            name="brand"
                                            value={SPECIFICATIONS.filter(obj => specificationForm[index]['key'] == (obj.key))}
                                            onChange={formArrayChange.bind(this, index, 'label')}
                                            options={SPECIFICATIONS}
                                            isClearable
                                        />
                                    </div>
                                    <div className="form-group col-sm-5">
                                        <label htmlFor="brand1">Value</label>
                                        <input className="form-control"
                                            name="value"
                                            value={specificationForm[index]['value'] || ""}
                                            onChange={formArrayChange.bind(this, index, 'value')}
                                            type="text" />
                                    </div>
                                </div>
                                )
                            })
                        }
                        {userPermission('product', 'add') && verifyVendorAccount() ?
                            <button type="button" onClick={submitSpecification} className="btn btn-primary pr-4 pl-4">Submit Specification</button> : null
                        }
                    </div>
                    <div className="tab-pane" id="uploadProducts">
                        <a className="" href={productSample} download><i className="fa fa-download"></i> Download Sample File</a>
                        <div className="row">
                            <div className="form-group col-lg-4 col-md-6 col-sm-6 mb-md-4">
                                <input type="file" onChange={inputHandler} className="form-control" />
                            </div>
                            <div className="form-group col-lg-4 col-md-6 col-sm-6 mb-md-4">
                                {userPermission('product', 'add') && verifyVendorAccount() ?
                                    <>
                                        <button onClick={importExcel} className="btn btn-primary pr-4 pl-4">Upload</button>
                                        {isUpdated && isUpdated.excel ? <div className="form-group mb-md-12"><RowLoader /></div> : null}
                                    </>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ManageProduct;