import Header from "../common/Header";
import Footer from "../common/Footer";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../common/Loader";
import NewsletterFormController from "../common/NewsletterFormController";
import ServiceFilter from "./ServiceFilter";
import Banner from "../common/Banner";
import { Link, useHistory, useLocation } from "react-router-dom";
import { UserServices } from "../../services";
import ServiceCard from "./ServiceCard";
import store from "../../store/store"
import { useSelector } from "react-redux";
import { SHORT_BY } from "../../config/constant";

function ServiceListingComponent() {
    const [services, setServices] = useState([]);
    const [totalServices, setTotalServices] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [pramotionBanner, setPramotionBanner] = useState({});
    const [shortby, setShortby] = useState('popularity');

    let inState = useSelector(state => {
        return state.product;
    });
    let { product } = store.getState();
    const history = useHistory();
    const search = useLocation().search;
    const cate = new URLSearchParams(search).get('cate');
    useEffect(() => {
        if (product && product.search) {
            if (history) history.push('/services-listing');
            getAllServices(product.search);
        }
    }, [product]);
    useEffect(() => {
        getAllServices({ category: [cate] });
    }, [cate]);
    useEffect(() => {
        getAllPramotionBanner();
    }, [])
    const getAllPramotionBanner = async () => {
        UserServices.pramotionalBanner({ displayAt: 'allservice' }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setPramotionBanner(data);
            }
            else {
                toast.error(message);
            }
        }).catch((error) => {
            toast.error(error);
        })
    }
    const shortProductList = (e) => {
        setShortby(e.target.value);
        getAllServices({ category: [cate] });
    }
    const getAllServices = (searchObj) => {
        setIsLoading(true)
        UserServices.serviceAllList(searchObj).then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setServices(data.list);
                setTotalServices(data.total);
            }
            setIsLoading(false)
        })
    }
    return (
        <>
            <Header showFilterProduct={'servicelist'} header={'header2'} fakeLogin={true} spaceType={'product'} />
            <section className="pb-65">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className="">{totalServices} services available</h4>
                            <a className="co6">Search all categories instead.</a>
                        </div>
                        <div className="col-md-6 filter-pin-tip">
                            <div className="tr_drop">
                                <label> Sort By: &nbsp;&nbsp;</label>
                                <select onChange={shortProductList} className="short_by ml-md-2 mt-md-3">
                                    {SHORT_BY && SHORT_BY.map((s, i) => {
                                        return (<option value={s.value} key={i + '_ik'}>{s.label}</option>)
                                    })}
                                </select>
                            </div>
                            <div className="filter-pin-c">
                                <div className="filter-pin">
                                    <i className="fa fa-filter system-color"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-md-4 row2">
                        {
                            services && services.map((service, indx) => {
                                return (
                                    <div key={indx + '_sss'} className="col-lg-2 col-md-4 col-sm-6">
                                        <ServiceCard service={service} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <Loader isLoading={isLoading} />
            {pramotionBanner && pramotionBanner.first && pramotionBanner.first.image ?
                <section>
                    <Banner img={pramotionBanner.first.image} />
                </section> : null
            }
            <section className=" pt-50 pb-50 std">
                <div className="container">
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="product_service_box">
                                <span className="icon_img"><img src={require("../../webroot/images/verify.png").default} alt="verify" /></span>
                                <h4>Shop safely and confidently. <span>See our safety procedures</span></h4>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="product_service_box">
                                <span className="icon_img"><img src={require("../../webroot/images/deals.png").default} alt="deals" /></span>
                                <h4>Top Deals.<span> Explore the latest and greatest, all in one place.</span> </h4>

                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="product_service_box">
                                <span className="icon_img"><img src={require("../../webroot/images/handshake.png").default} alt="handshake" /></span>
                                <h4>Deal of the Day. <span>Great deals. Every Day.</span> </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {pramotionBanner && pramotionBanner.first && pramotionBanner.first.image ?
                <section>
                    <Banner img={pramotionBanner.first.image} />
                </section> : null
            }
            <NewsletterFormController />
            <Footer />
        </>
    )
}
export default ServiceListingComponent;