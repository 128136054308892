import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { UserServices } from "../../services";
import Loader from "../common/Loader";
import secureStorage from "../../config/encrypt";
import { API_URL } from "../../config/constant";
import { displayDate } from "../../utils"
function VendorMySolutions() {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        let user = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY);
        user = (user && "user" in user) ? user.user : user;
        if (user) {
            getUserProfile(user._id);
        }
    }, [])
    const getUserProfile = async (userId) => {
        UserServices.myProduct({ userId }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setProducts(data.list);
            }
            else {
                // toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            // toast.error(error);
            setIsLoading(false);
        })
    }
    return (
        <div className="col-lg-12">
            <Loader isLoading={isLoading} />
            <div className="d_boxs pb-1 mb-2 mb-md-4">
                <div className="row">
                    <div className=" col-md-5 ">
                        <h5 className="box_hadding">My Added Solutions </h5>
                    </div>
                    <div className=" col-md-7 ">
                        <div className="d_flex search_input mb-3 mb-md-4 mla">
                            <Link to={'/my/manage-product'} className="btn btn-primary btn-small m-auto mr-3">Add New Solution</Link>
                            <span className="pt-2 w90"> Sort by: </span>
                            <select className=" form-control" type="text">
                                <option>Most Recent</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="table_responsive_scrool">
                    <table className="table_vender text-center">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Category</th>
                                <th>Manufacturer</th>
                                <th>Brand</th>
                                <th>Quantity</th>
                                <th>Release Date</th>
                                <th>Expiry Date</th>
                                <th>Original Price</th>
                                <th>Selling Price</th>
                                <th>Created On</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isLoading && [].map((item, indx) => {
                                return (
                                    <tr>
                                        <td>
                                            <div>
                                                <img className="venter_product" src={item.thumbnailImage} />
                                                <small className="d_block">{item.title}</small>
                                            </div>
                                        </td>
                                        <td>{item.items}</td>
                                        <td>{item.manufacturer ? item.manufacturer.title : ""}</td>
                                        <td>{item.brand ? item.brand.title : ""}</td>
                                        <td>{item.totalUnits}</td>
                                        <td>{displayDate(item.releaseDate)}</td>
                                        <td>{displayDate(item.expiryDate)}</td>
                                        <td>{item.currency + ' ' + item.originalPrice}</td>
                                        <td>{item.currency + ' ' + item.sellingPrice}</td>
                                        <td>{displayDate(item.createdAt)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default VendorMySolutions;