import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Loader from "../common/Loader";
import InnerHtml from "../elements/InnerHtml";
import { useLocation } from 'react-router-dom';
function ContentComponent() {
    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState("");
    const location = useLocation();
    let currentPath = (location.pathname).replace('/', '')
    useEffect(() => {
        getContent();
    }, []);
    const getContent = async () => {
        UserServices.contentDetail({ type: currentPath }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setContent(data);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsLoading(false);
        })
    }
    const initCap = (string) => {
        string = string.split('-').join(" ")
        string = string.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
        return string;
    }
    return (
        <>
            <Header metaTitle={content.metaTitle} metaKewords={content.metaKewords} metaDescription={content.metaDescription} />
            <Loader isLoading={isLoading} />
            <div className="in_banenr" >
                <img src={content.banner ? content.Image : require("../../webroot/images/about-banner.png").default} className="w-100" alt="Banner" />
                <div className="banner_content">
                    <div className="container">
                        <h1>{initCap(currentPath)}</h1>
                        <p>{content.title}</p>
                    </div>
                </div>
            </div>
            <div className="container">
                <InnerHtml rawHTML={content.description} />
            </div>
            <Footer />
        </>
    );
}

export default ContentComponent;