// import BreadCrumb from "../common/BreadCrumb";
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { UserServices } from "../../services"
import Loader from '../common/Loader';
import { userRole } from "../../utils";
import { API_URL } from "../../config/constant";
const filePATH = API_URL.COMMON_GATEWAY_API + 'invoice/download/';
const ProductOrderDetails = () => {
    let [isLoading, setIsLoading] = useState(true);
    let [orderDetail, setOrderDetail] = useState({});
    const search = useLocation().search;
    const productId = new URLSearchParams(search).get("productId");
    const type = new URLSearchParams(search).get("type");
    useEffect(() => {
        getAllTransactions(productId, type);
    }, [productId, type])
    const getAllTransactions = (productId, type = 'product') => {
        UserServices.buyProductOrderDetail(productId, type).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode === 200) {
                setOrderDetail(data)
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    const onchangeOrderStatus = (e) => {
        let obj = {
            orderStatus: e.target.value,
            reason: '',
            buyProduct: productId
        }
        UserServices.buyproductChangeStatus(obj).then((responce) => {
            let { message, statusCode } = responce;
            if (statusCode === 200) {
                toast.success(message);
                getAllTransactions(productId);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    return (
        <div className="col-lg-9">
            <p>{orderDetail?.products?.description}</p>
            <div className="row">
                <div className="col-lg-12 grid-margin-sm">
                    <div className="card card-box">
                        <div className="card-body card-body-pd row">
                            <div className="col-lg-6">
                                <label>Invoice Number &nbsp;</label>
                                <span> {orderDetail?.buyproducts?.invoiceNumber}</span>
                            </div>
                            <div className="col-lg-6">
                                <a className="btn btn-primary btn-small" href={filePATH + productId} download><i className="mdi mdi-cloud-download"></i>Download Invoice</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="details-grid">
                <div className="details-grid-content">
                    <h4 className="card-title">Product Details</h4>
                    <div className="details-list-wrapper">
                        <ul className="details-flex-column">
                            <li>
                                <label className="title-label">Name</label><span>{orderDetail?.products?.title}</span>
                            </li>
                            <li>
                                <label className="title-label">Code</label><span>{orderDetail?.products?.code}</span>
                            </li>
                            <li>
                                <label className="title-label">Original Price</label><span>{orderDetail?.products?.currency} {orderDetail?.products?.originalPrice}</span>
                            </li>
                            <li>
                                <label className="title-label">Selling Price</label><span>{orderDetail?.products?.currency} {orderDetail?.products?.sellingPrice}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="details-grid-content">
                    <h4 className="card-title">Purchage Details</h4>
                    <div className="details-list-wrapper">
                        <ul className="details-flex-column">
                            <li>
                                <label className="title-label">Quantity</label><span> {orderDetail?.buyproducts?.totalItems}</span>
                            </li>
                            <li>
                                <label className="title-label">Transaction Id</label> <span> {orderDetail?.buyproducts?.transactionId}</span>
                            </li>
                            <li>
                                <label className="title-label">Order Id</label><span> {orderDetail?.buyproducts?.orderId}</span>
                            </li>
                            <li>
                                <label className="title-label">Price</label><span> {orderDetail?.currency} {orderDetail?.buyproducts?.totalPrice}</span>
                            </li>
                            <li>
                                <label className="title-label">Paid Amount</label><span> {orderDetail?.currency} {orderDetail?.buyproducts?.paidAmount}</span>
                            </li>
                            <li>
                                <label className="title-label">Purchage Date</label><span> {orderDetail?.buyproducts?.createdAt}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="details-grid-content">
                    <h4 className="card-title">Customer Details</h4>
                    <div className="details-list-wrapper">
                        <ul className="details-flex-column">
                            <li>
                                <label className="title-label">Name</label><span>{orderDetail?.user?.firstName} {orderDetail?.user?.lastName}</span>
                            </li>
                            <li>
                                <label className="title-label">Email</label><span>{orderDetail?.user?.email}</span>
                            </li>
                            <li>
                                <label className="title-label">Mobile</label><span>{orderDetail?.user?.mobile}</span>
                            </li>
                            <li>
                                <label className="title-label">Address</label><span>{orderDetail?.buyproducts?.shippingAddr}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="details-grid-content">
                    <h4 className="card-title">Seller Details</h4>
                    <div className="details-list-wrapper">
                        <ul className="details-flex-column">
                            <li>
                                <label className="title-label">Name</label><span>{orderDetail?.products?.vender?.firstName} {orderDetail?.products?.vender?.lastName}</span>
                            </li>
                            <li>
                                <label className="title-label">Email</label><span>{orderDetail?.products?.vender?.email}</span>
                            </li>
                            <li>
                                <label className="title-label">Mobile</label><span>{orderDetail?.products?.vender?.mobile}</span>
                            </li>
                            <li>
                                <label className="title-label">Address</label><span>{orderDetail?.products?.buyproducts?.shippingAddr}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {type !== 'product' ? <></> :
                <div className="tracking-wrapper">
                    <ol className="progtrckr" data-progtrckr-steps="4">
                        <li className={"progtrckr-" + (['ordered', 'shipped', 'outForDelivery', 'delivered'].includes(orderDetail?.orderStatus) ? 'done' : 'todo')}>Received</li>
                        <li className={"progtrckr-" + (['shipped', 'outForDelivery', 'delivered'].includes(orderDetail?.orderStatus) ? 'done' : 'todo')}>Processing</li>
                        {/* <li className="progtrckr-done">Placed</li> */}
                        <li className={"progtrckr-" + (['outForDelivery', 'delivered'].includes(orderDetail?.orderStatus) ? 'done' : 'todo')}>Dispatched</li>
                        <li className={"progtrckr-" + (['delivered'].includes(orderDetail?.orderStatus) ? 'done' : 'todo')}>Delivered</li>
                    </ol>
                </div>
            }
            {userRole() == 'vendor' && type == 'product' ?
                <div className="tracking-wrapper">
                    <div className="row">
                        <div className="col-lg-4">
                            <label>Change Order Status</label>
                            <select onChange={onchangeOrderStatus} value={orderDetail?.orderStatus} className="form-control">
                                <option value="shipped">Shipped</option>
                                <option value="outForDelivery">Out For Delivery</option>
                                <option value="delivered">Delivered</option>
                                <option value="return">Return</option>
                                <option value="cancel">Cancel</option>
                            </select>
                        </div>
                    </div>
                    {/* <div className="history table-responsive">
                        <table className="table table-striped mat-table">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Change By</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Received</td>
                                    <td>10-12-22</td>
                                    <td>10:10</td>
                                    <td>Chandan</td>
                                </tr>
                                <tr>
                                    <td>Received</td>
                                    <td>10-12-22</td>
                                    <td>10:10</td>
                                    <td>Chandan</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                </div> : null}
        </div>
    )
}

export default ProductOrderDetails;