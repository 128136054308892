import React, { useState, useEffect } from 'react';
import { UserServices } from "../../services";
import { toast } from "react-toastify";
import secureStorage from "../../config/encrypt";
import { API_URL } from "../../config/constant";
import { Calendra } from "../elements/DatePicker";
import Select from 'react-select';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { Link, useHistory } from "react-router-dom";
import PersonalProfile from './PersonalProfile';
import RowLoader from "../common/RowLoader";
let formArr = { brand: "", relationship: "", documentFile: "" };
let rpformArr = { brand: "", category: "", country: "", iso2: "" };
function VenderProfile() {
    const [isEdit, setIsEdit] = useState(false);
    const [companyDetails, setCompanyDetails] = useState({});
    const [socialInfo, setSocialInfo] = useState({});
    const [userData, setUserData] = useState({});
    const [editableCurrency, setEditableCurrency] = useState(false);
    const [verificationDocs, setVerificationDocs] = useState([]);
    const [veriFile, setVeriFile] = useState([]);
    const [brandPartenships, setBrandPartenships] = useState([]);
    const [regionalPresences, setRegionalPresences] = useState([]);
    const [brandsArr, setBrandsArr] = useState([]);
    const [categoriesArr, setCategoriesArr] = useState([]);
    const [countriesArr, setCountriesArr] = useState([]);
    const [currencyArr, setCurrencyArr] = useState([]);


    const [isLoading, setIsLoading] = useState(true);
    const [isVerifyDocuments, setIsVerifyDocuments] = useState({});
    const [isBrandDocu, setIsBrandDocu] = useState({});
    const [userId, setUserId] = useState("");
    const [changePassword, setChangePassword] = useState({ oldPassword: "", newPassword: "", confirmNewPassword: "" });
    let history = useHistory();
    useEffect(() => {
        let user = secureStorage.getItem(API_URL.COMMON_LOGIN_STORE_KEY);
        user = (user && "user" in user) ? user.user : user;
        if (user) {
            getUserProfile(user._id, user.role);
            setUserId(user._id)
        }
        getAllBrands();
        getAllCategories();
        getCountryList();
        getCurencyList();
    }, [])
    const getCurencyList = () => {
        UserServices.currencyList().then((responce) => {
            let { data, status } = responce;
            if (status == 200) {
                setCurrencyArr(data);
            }
        })
    }
    const getAllBrands = () => {
        UserServices.brandDropdownList().then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setBrandsArr(data);
            }
        })
    }
    const getAllCategories = () => {
        UserServices.categoriesDropDownList().then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setCategoriesArr(data.list);
            }
        })
    }
    const getCountryList = () => {
        UserServices.countryList().then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setCountriesArr(data);
            }
        })
    }
    const getUserProfile = async (userId, role) => {
        UserServices.userDetail(userId, role).then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                let { user } = data;
                setEditableCurrency(user.editableCurrency);
                setUserData({
                    role: user.role,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    dialCode: user.dialCode,
                    iso2: (user.iso2),
                    mobile: user.mobile,
                    postalAddress: user.address.postalAddress,
                    country: user.address.country,
                    state: user.address.state,
                    city: user.address.city,
                    postcode: user.address.postcode,
                    ciso2: user.address.ciso2,
                    dob: user.dob,
                    vendorDescription: user.vendorDescription
                });
                if (data.role != "user") {
                    console.log(data.user.brandpartenships);
                    setCompanyDetails(data.user.companyInfo);
                    setVerificationDocs(data.user.verificationDocs ? data.user.verificationDocs : []);
                    setBrandPartenships(data.user.brandpartenships ? data.user.brandpartenships : []);
                    setRegionalPresences(data.user.regionalPresences ? data.user.regionalPresences : []);
                }
                setSocialInfo(data.user.socialInfo ? data.user.socialInfo : {});
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsLoading(false);
        })
    }
    const onChangeMobile = (status, value, countryData) => {
        setCompanyDetails((priv) => ({
            ...priv,
            mobile: value,
            dialCode: countryData.dialCode,
            iso2: countryData.iso2
        }))
    }
    const companyInfoHandler = (e, name) => {
        if (name) {
            setCompanyDetails((priv) => ({
                ...priv,
                [name]: e
            }))
        }
        else {
            setCompanyDetails((priv) => ({
                ...priv,
                [e.target.name]: e.target.value
            }))
        }
    }
    const updateCompanyInfo = (event) => {
        companyDetails.vendorId = userId;
        UserServices.updateCompanyinfo(companyDetails).then((responce) => {
            let { status, message } = responce;
            if (status == 200) {
                toast.success(message)
            }
        }).catch((er) => {
            console.log(er);
        })
    }
    const companyProfileHandler = (e) => {
        setSocialInfo((priv) => ({
            ...priv,
            [e.target.name]: e.target.value
        }))
    }
    const updateProfile = (e) => {
        UserServices.updateUser({ socialInfo: socialInfo, id: userId }).then((responce) => {
            let { status, message } = responce;
            if (status == 200) {
                toast.success(message)
            }
        }).catch((er) => {
            console.log(er);
        })
    }
    const verificationDocsFileHandler = (e, name, indx) => {
        let newVeriFile = veriFile;
        newVeriFile[indx] = e.target.files[0]
        setVeriFile(newVeriFile)
    }
    const verificationDocsHandler = (e, name, indx) => {
        let chanVD = verificationDocs.map((vd, i) => {
            if (i == indx) {
                vd.expiryDate = e;
            }
            return vd;
        })
        setVerificationDocs(chanVD);
    }
    const uploadDocuments = (e, doc, index) => {
        if (veriFile[index]) {
            setIsVerifyDocuments((prev) => ({ ...prev, [index]: true }));
            const formData = new FormData();
            formData.append('documentURL', veriFile[index]);
            formData.append('expiryDate', verificationDocs[index]['expiryDate'] || new Date());
            formData.append('slug', verificationDocs[index]['slug']);
            formData.append('title', verificationDocs[index]['title']);
            formData.append('vendorId', userId);
            UserServices.verificationDocsUpdate(formData).then((responce) => {
                let { status, message } = responce;
                if (status == 200) {
                    toast.success(message)
                }
                setIsVerifyDocuments((prev) => ({ ...prev, [index]: false }));
            }).catch((er) => {
                console.log(er);
            })
        }
        else {
            toast.error('Please select date and file')
        }
    }
    /** Brand Parti */
    const addMoreBrandPartner = (e) => {
        let newBP = [...brandPartenships, ...[formArr]];
        setBrandPartenships(newBP);
    }
    const removeBProw = (index) => {
        let newBP = [...brandPartenships];
        newBP.splice(+index, 1);
        setBrandPartenships(newBP);
    }
    const bpFormChange = (e, i, name) => {
        let vl = "";
        if (name == 'brand') {
            vl = e.value;
        }
        else if (name == 'documentFile') {
            vl = e.target.files[0];
        }
        else {
            vl = e.target.value;
        }
        let newBP = [...brandPartenships];
        newBP = newBP.map((brand, index) => {
            if (i == index) {
                brand = { ...brand, ...{ [name]: vl } }
            }
            return brand;
        });
        setBrandPartenships(newBP);
    }
    const submitBrandPartner = (i) => {
        setIsBrandDocu((prev) => ({ ...prev, [i]: true }));
        const formData = new FormData();
        formData.append('vendorId', userId);
        formData.append('brand', brandPartenships[i]['brand']);
        formData.append('relationship', brandPartenships[i]['relationship']);
        formData.append('documentFile', brandPartenships[i]['documentFile']);
        UserServices.brandPartnershipUpdate(formData).then((responce) => {
            let { status, message } = responce;
            if (status == 200) {
                toast.success(message)
            }
            setIsBrandDocu((prev) => ({ ...prev, [i]: false }));
        }).catch((er) => {
            console.log(er);
            setIsBrandDocu((prev) => ({ ...prev, [i]: false }));
        })
    }
    /** Regional Presence */
    const addMoreRegionalPresence = (e) => {
        let newBP = [...regionalPresences, ...[rpformArr]];
        setRegionalPresences(newBP);
    }
    const removeRProw = (index) => {
        let newRP = [...regionalPresences];
        newRP.splice(+index, 1);
        setRegionalPresences(newRP);
    }
    const rpFormChange = (e, i, name) => {
        let newRP = [...regionalPresences];
        newRP = newRP.map((brand, index) => {
            if (i == index) {
                if (name == 'iso2') {
                    brand = { ...brand, ...{ [name]: e.iso_code_2 }, country: e.name }
                }
                else {
                    brand = { ...brand, ...{ [name]: e.value } }
                }
            }
            return brand;
        });
        setRegionalPresences(newRP);
    }
    const submitRegionalPresence = (e) => {
        const formData = new FormData();
        formData.append('vendorId', userId);
        formData.append('rp', JSON.stringify(regionalPresences));
        UserServices.regionalPresenceUpdate(formData).then((responce) => {
            let { status, message } = responce;
            if (status == 200) {
                toast.success(message)
            }
        }).catch((er) => {
            console.log(er);
        })
    }
    /** Change Password */
    const changePasswordHandler = (e) => {
        setChangePassword((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }
    const submitChangePassword = (e) => {
        if (changePassword.oldPassword != "" && changePassword.newPassword != "" && changePassword.confirmNewPassword != "") {
            if (changePassword.newPassword == changePassword.confirmNewPassword) {
                changePassword.userId = userId;
                UserServices.changePassword(changePassword).then((responce) => {
                    let { status, message } = responce;
                    if (status == 200) {
                        toast.success(message)
                        if (history) history.push('/logout');
                    }
                }).catch((er) => {
                    console.log(er);
                })
            }
            else {
                toast.error("Your confirm password not match")
            }
        }
        else {
            toast.error("Please enter all required fields")
        }
    }
    const personalProfileChangeHandler = (n, v) => {
        if (n == 'mobile') {
            setUserData((prev) => ({
                ...prev,
                'mobile': v.mobile,
                'dialCode': v.dialCode,
                'iso2': v.iso2
            }))
        }
        else if (n == 'ciso2') {
            setUserData((prev) => ({
                ...prev,
                [n]: v.iso_code_2,
                'country': v.name
            }))
        }
        else {
            setUserData((prev) => ({
                ...prev,
                [n]: v
            }))
        }
    }
    const updatePersonalInfo = (e) => {
        delete userData.role;
        userData.id = userId;
        UserServices.updateUser(userData).then((responce) => {
            let { message, statusCode } = responce;
            if (statusCode === 200) {
                toast.success(message);
            }
            else {
                toast.error(message);
            }
        })
    }
    return (
        <>
            <div className="col-lg-9">
                <div className="d_boxs  mb-2 mb-md-4">
                    <div className=" pt-lg-0 dashbord-heading pb-2 hadding_border">
                        <h3 className="co8 pb-md-0">My Profile
                        </h3>
                        <div className="d_flex search_input mb-3 mb-md-4 mla">
                            <a onClick={() => setIsEdit(isEdit ? false : true)} className="btn btn-primary btn-small">Edit Profile</a>&nbsp;
                        </div>
                    </div>
                    <ul className="nav my_profile_tab mb-2 mb-md-5">
                        <li className="nav-item">
                            <a className=" active" data-toggle="tab" href="#ChangePassword">Change Password </a>
                        </li>
                        <li className="nav-item">
                            <a className="" data-toggle="tab" href="#PersonalProfile">Personal Profile</a>
                        </li>
                        {userData.role != "user" ? <>
                            <li className="nav-item">
                                <a className="" data-toggle="tab" href="#CompanyInfo">Company Info </a>
                            </li>
                            <li className="nav-item">
                                <a className="" data-toggle="tab" href="#VerificationDocuments"> Verification Documents </a>
                            </li>
                        </> : null
                        }
                        <li className="nav-item">
                            <a className="" data-toggle="tab" href="#SocialInfo"> Social Info</a>
                        </li>
                        {userData.role != "user" ? <>
                            <li className="nav-item">
                                <a className="" data-toggle="tab" href="#BrandPartnerships"> Brand Partnerships</a>
                            </li>
                            <li className="nav-item">
                                <a className="" data-toggle="tab" href="#RegionalPresence">Regional Presence</a>
                            </li>
                        </> : null
                        }
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane active " id="ChangePassword">
                            <ul className="row info_box">
                                <li>
                                    <small className="required"> Old Password</small>
                                    <input type="text"
                                        className="bg-r form-control nospace"
                                        value={changePassword.oldPassword}
                                        name="oldPassword"
                                        onChange={changePasswordHandler}
                                    />
                                </li>
                                <li>
                                    <small className="required"> New Password</small>
                                    <input type="text"
                                        className="bg-r form-control nospace"
                                        value={changePassword.newPassword}
                                        name="newPassword"
                                        onChange={changePasswordHandler}
                                    />
                                </li>
                                <li>
                                    <small className="required"> Confirm New Password</small>
                                    <input type="text"
                                        className="bg-r form-control nospace"
                                        value={changePassword.confirmNewPassword}
                                        name="confirmNewPassword"
                                        onChange={changePasswordHandler}
                                    />
                                </li>
                            </ul>
                            {!isEdit ?
                                <button onClick={submitChangePassword} className="btn btn-success btn-small">Change Password</button>
                                : null
                            }
                        </div>
                        <div className="tab-pane " id="PersonalProfile">
                            <PersonalProfile userData={userData} changeHandler={personalProfileChangeHandler} countriesArr={countriesArr} />
                            {!isEdit ?
                                <button onClick={updatePersonalInfo} className="btn btn-success btn-small">Update Personl Info</button>
                                : null
                            }
                        </div>
                        {userData.role != "user" ? <>
                            <div className="tab-pane  " id="CompanyInfo">
                                <ul className="row info_box">
                                    <li>
                                        <small> Company Name</small>
                                        {isEdit == true ?
                                            companyDetails.companyName :
                                            <input type="text"
                                                className="bg-r form-control"
                                                value={companyDetails.companyName}
                                                name="companyName"
                                                onChange={companyInfoHandler}
                                            />
                                        }
                                    </li>
                                    <li>
                                        <small> Company Email </small>
                                        {isEdit == true ? companyDetails.email :
                                            <input type="text"
                                                className="bg-r form-control"
                                                value={companyDetails.email}
                                                name="email"
                                                onChange={companyInfoHandler}
                                            />
                                        }
                                    </li>
                                    <li>
                                        <small> Official Website </small>
                                        {isEdit == true ? companyDetails.website :
                                            <input type="text"
                                                className="bg-r form-control"
                                                value={companyDetails.website}
                                                name="website"
                                                onChange={companyInfoHandler}
                                            />
                                        }
                                    </li>
                                    <li>
                                        <small> Mobile Number</small>
                                        {isEdit == true ? '+ ' + (companyDetails.dialCode + ' ' + companyDetails.mobile) :
                                            < IntlTelInput
                                                value={companyDetails.mobile}
                                                containerClassName="intl-tel-input"
                                                inputClassName="form-control number"
                                                preferredCountries={['in']}
                                                onSelectFlag={(num, country) => {
                                                    // console.log('onSelectFlag', country);
                                                }}
                                                onPhoneNumberChange={onChangeMobile}
                                            />
                                        }
                                    </li>

                                    <li>
                                        <small>DnB Number </small>
                                        {isEdit == true ? companyDetails.dnb :
                                            <input type="text"
                                                className="bg-r form-control"
                                                value={companyDetails.dnb}
                                                name="dnb"
                                                onChange={companyInfoHandler}
                                            />
                                        }
                                    </li>
                                    <li>
                                        <small> Office Address </small>
                                        {isEdit == true ? companyDetails.address :
                                            <input type="text"
                                                className="bg-r form-control"
                                                value={companyDetails.address}
                                                name="address"
                                                onChange={companyInfoHandler}
                                            />
                                        }
                                    </li>
                                    <li>
                                        <small> Country</small>
                                        {isEdit == true ? companyDetails.country :
                                            <Select
                                                name="country"
                                                value={countriesArr.filter(obj => companyDetails.country == (obj.name))}
                                                onChange={(e) => companyInfoHandler(e.name, 'country')}
                                                options={countriesArr}
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option.name}
                                                isClearable
                                            />
                                        }
                                    </li>
                                    <li>
                                        <small>Currency</small>
                                        {isEdit == true ? companyDetails.currency :
                                            <Select
                                                name="currency"
                                                value={currencyArr.filter(obj => companyDetails['currency'] == (obj.value))}
                                                onChange={(e) => companyInfoHandler(e.value, 'currency')}
                                                options={currencyArr}
                                                getOptionLabel={option => option.value + '-' + option.name + '(' + option.symbol + ')'}
                                                getOptionValue={option => option.value}
                                                isClearable
                                                isDisabled={editableCurrency}
                                            />
                                        }
                                    </li>
                                    <li>
                                        <small>GST Number</small>
                                        {isEdit == true ? companyDetails.gstNumber :
                                            <input type="text"
                                                className="bg-r form-control"
                                                value={companyDetails.gstNumber}
                                                name="gstNumber"
                                                onChange={companyInfoHandler}
                                            />
                                        }
                                    </li>
                                    <li>
                                        <small> State </small>
                                        {isEdit == true ? companyDetails.state :
                                            <input type="text"
                                                className="bg-r form-control"
                                                value={companyDetails.state}
                                                name="state"
                                                onChange={companyInfoHandler}
                                            />
                                        }
                                    </li>
                                    <li>
                                        <small> City </small>
                                        {isEdit == true ? companyDetails.city :
                                            <input type="text"
                                                className="bg-r form-control"
                                                value={companyDetails.city}
                                                name="city"
                                                onChange={companyInfoHandler}
                                            />
                                        }
                                    </li>
                                    <li>
                                        <small>Date of Establishment </small>
                                        {isEdit == true ? companyDetails.dateOfEstablishment :
                                            <Calendra
                                                className="form-control"
                                                value={companyDetails.dateOfEstablishment}
                                                name="dateOfEstablishment"
                                                onChange={companyInfoHandler}
                                            />
                                        }
                                    </li>
                                    <li>
                                        <small>Number of Employees</small>
                                        {isEdit == true ? companyDetails.totalEmployees :
                                            <input type="text"
                                                className="bg-r form-control"
                                                value={companyDetails.totalEmployees}
                                                name="totalEmployees"
                                                onChange={companyInfoHandler}
                                            />
                                        }
                                    </li>
                                    <li>
                                        <small>Company Slogan</small>
                                        {isEdit == true ? companyDetails.slogan :
                                            <input type="text"
                                                className="bg-r form-control"
                                                value={companyDetails.slogan}
                                                name="slogan"
                                                onChange={companyInfoHandler}
                                            />
                                        }
                                    </li>
                                </ul>
                                <div className="646464">Descriptions<br />
                                    <small>
                                        {isEdit == true ? companyDetails.description :
                                            <textarea className="bg-r form-control"
                                                name="description"
                                                value={companyDetails.description}
                                                onChange={companyInfoHandler}
                                            >
                                            </textarea>
                                        }
                                    </small>
                                </div>
                                <br />
                                {!isEdit ?
                                    <button onClick={updateCompanyInfo} className="btn btn-success btn-small">Update Company Info</button>
                                    : null
                                }
                            </div>
                            <div className="tab-pane " id="VerificationDocuments">
                                {
                                    (verificationDocs) && (verificationDocs).map((vd, vdi) => {
                                        return (
                                            <React.Fragment key={vdi + 'cdi'}>
                                                <h5 className="fw400">{vd.title}</h5>
                                                <div className="row mt-3 mb-5">
                                                    <div className="col-12 col-md-5">
                                                        <div className="d_flex date_status">
                                                            {isEdit == true ? <i className="fa fa-file-text" aria-hidden="true"></i> :
                                                                <input type="file"
                                                                    name="documentURL"
                                                                    className="bg-r form-control"
                                                                    onChange={(e) => verificationDocsFileHandler(e, 'documentURL', vdi)}
                                                                />
                                                            }

                                                            <div className="date_status_right">
                                                                {vd.documentURL ? <a target="_blank" download href={vd.documentURL}>View </a> : null}
                                                                {/* {vd.isIdentityType == true ?
                                                                    'Identity Type: ' + vd.identityType :
                                                                    'Explry Date: ' + vd.expiryDate
                                                                }
                                                                <br />
                                                                Status: <span className="color3">{vd.status}</span> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-6 col-md-4">
                                                        Expiry date<br />
                                                        {isEdit == true ? <span>{vd.expiryDate}</span> :
                                                            <Calendra
                                                                className="form-control"
                                                                value={vd.expiryDate}
                                                                name="expiryDate"
                                                                index={vdi}
                                                                onChange={verificationDocsHandler}
                                                            />
                                                        }
                                                    </div> */}
                                                    <div className="col-6 col-md-3 text-right">
                                                        {isEdit == true ? <button className="upload_btn_p ">
                                                            <i className="fa fa-upload"></i> Upload</button> :
                                                            <button onClick={(e) => uploadDocuments(e, vd, vdi)} className="upload_btn_p ">
                                                                <i className="fa fa-upload"></i> Upload</button>
                                                        }
                                                        {isVerifyDocuments[vdi] ? <RowLoader /> : null}
                                                    </div>

                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </> : null
                        }
                        <div className="tab-pane " id="SocialInfo">
                            <div className="row mt-3 mb-5">
                                <div className="col-md-6">
                                    <div className="d_flex social_info">
                                        <img src={require("../../webroot/images/facebook_l.png").default} />
                                        <div className="social_info_right">
                                            <h5 className="fw400">Facebook URL</h5>
                                            {isEdit == true ? <a href="https://www.facebook.com" target="_blank">{socialInfo.facebook}</a> :
                                                <input type="text"
                                                    className="bg-r form-control"
                                                    value={socialInfo.facebook}
                                                    name="facebook"
                                                    onChange={companyProfileHandler}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="d_flex social_info">
                                        <img src={require("../../webroot/images/twitter.png").default} />
                                        <div className="social_info_right">
                                            <h5 className="fw400">Twitter URL</h5>
                                            {isEdit == true ? <a href="https://twitter.com/" target="_blank">{socialInfo.twitter}</a> :
                                                <input type="text"
                                                    className="bg-r form-control"
                                                    value={socialInfo.twitter}
                                                    name="twitter"
                                                    onChange={companyProfileHandler}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-md-6">
                                    <div className="d_flex social_info">
                                        <img src={require("../../webroot/images/lnkdan.png").default} />
                                        <div className="social_info_right">
                                            <h5 className="fw400">Linkedin URL</h5>
                                            {isEdit == true ? <a href="#" target="_blank">{socialInfo.linkedin}</a> :
                                                <input type="text"
                                                    className="bg-r form-control"
                                                    value={socialInfo.linkedin}
                                                    name="linkedin"
                                                    onChange={companyProfileHandler}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-md-6">
                                    <div className="d_flex social_info">
                                        <img src={require("../../webroot/images/whatsapp2.png").default} />
                                        <div className="social_info_right">
                                            <h5 className="fw400">Whatsapp URL</h5>
                                            {isEdit == true ? <a href="https://web.whatsapp.com/"
                                                target="_blank">{socialInfo.whatsapp}</a> :
                                                <input type="text"
                                                    className="bg-r form-control"
                                                    value={socialInfo.whatsapp}
                                                    name="whatsapp"
                                                    onChange={companyProfileHandler}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-md-6">
                                    <div className="d_flex social_info">
                                        <img src={require("../../webroot/images/skype.png").default} />
                                        <div className="social_info_right">
                                            <h5 className="fw400">Skype URL</h5>
                                            {isEdit == true ? <a href="#" target="_blank">{socialInfo.skype}</a> :
                                                <input type="text"
                                                    className="bg-r form-control"
                                                    value={socialInfo.skype}
                                                    name="skype"
                                                    onChange={companyProfileHandler}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-md-6">
                                    <div className="d_flex social_info">
                                        <img src={require("../../webroot/images/youtub.png").default} />
                                        <div className="social_info_right">
                                            <h5 className="fw400">youtube URL</h5>
                                            {isEdit == true ? <a href="https://www.youtube.com/"
                                                target="_blank">{socialInfo.youtube}</a> :
                                                <input type="text"
                                                    className="bg-r form-control"
                                                    value={socialInfo.youtube}
                                                    name="youtube"
                                                    onChange={companyProfileHandler}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="d_flex social_info">
                                        <img src={require("../../webroot/images/instagram2.png").default} />
                                        <div className="social_info_right">
                                            <h5 className="fw400">Instagram URL</h5>
                                            {isEdit == true ? <a href="https://www.instagram.com/"
                                                target="_blank">{socialInfo.instagram}</a> :
                                                <input type="text"
                                                    className="bg-r form-control"
                                                    value={socialInfo.instagram}
                                                    name="instagram"
                                                    onChange={companyProfileHandler}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="d_flex social_info">
                                        <img src={require("../../webroot/images/instagram2.png").default} />
                                        <div className="social_info_right">
                                            <h5 className="fw400">Gmail URL</h5>
                                            {isEdit == true ? <a href="https://www.instagram.com/" target="_blank">{socialInfo.gmail}</a> :
                                                <input type="text"
                                                    className="bg-r form-control"
                                                    value={socialInfo.gmail}
                                                    name="gmail"
                                                    onChange={companyProfileHandler}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {!isEdit ?
                                <button onClick={updateProfile} className="btn btn-success btn-small">Update Social Info</button>
                                : null
                            }
                        </div>
                        {userData.role != "user" ? <>
                            <div className="tab-pane " id="BrandPartnerships">
                                {
                                    brandPartenships.map((bp, bpi) => {
                                        return (
                                            <div className="row" key={'bp' + bpi}>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="brand_items">
                                                        Brand
                                                        <span className="brand_value">
                                                            <Select
                                                                name="brand"
                                                                value={brandsArr.filter(obj => bp['brand'] == (obj.value))}
                                                                onChange={(e) => bpFormChange(e, bpi, 'brand')}
                                                                options={brandsArr}
                                                                isClearable
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="brand_items">
                                                        Relationship
                                                        <span className="brand_value">
                                                            <input type="text"
                                                                className="bg-r form-control"
                                                                value={bp.relationship}
                                                                name="relationship"
                                                                onChange={(e) => bpFormChange(e, bpi, 'relationship')}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="brand_items text-center brand-doc-download">
                                                        Upload Brand Document
                                                        <span className="brand_value">
                                                            <img src={require("../../webroot/images/docoment.jpg").default} />
                                                            <input type="file"
                                                                className="bg-r form-control"
                                                                name="documentFile"
                                                                onChange={(e) => bpFormChange(e, bpi, 'documentFile')}
                                                            />
                                                            &nbsp;&nbsp;&nbsp;{bp.documentFile ? <a target="_blank" download href={bp.documentFile}>View </a> : null}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    {!isEdit ?
                                                        <div className="brand_items text-center">
                                                            <button onClick={(e) => removeBProw(bpi)} className="cancial_btn_brand p-0"><i className="fa fa-times-circle"
                                                                aria-hidden="true"></i></button>&nbsp;&nbsp;
                                                            <button onClick={(e) => submitBrandPartner(bpi)} className="cancial_btn_brand p-0"><i className="fa fa-upload"
                                                                aria-hidden="true"></i></button>
                                                        </div> : null
                                                    }
                                                    {isBrandDocu[bpi] ? <RowLoader /> : null}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {!isEdit ?
                                    <button onClick={addMoreBrandPartner} className="upload_btn_p "><i className="fa fa-plus mr-1"></i> Add More Brands</button>
                                    : null}
                            </div>
                            <div className="tab-pane " id="RegionalPresence">
                                {
                                    regionalPresences.map((rp, rpi) => {
                                        return (<div className="row" key={'rpi' + rpi}>
                                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                <label className="">Category* </label>
                                                <Select
                                                    name="category"
                                                    value={categoriesArr.filter(obj => rp['category'] == (obj.value))}
                                                    onChange={(e) => rpFormChange(e, rpi, 'category')}
                                                    options={categoriesArr}
                                                    isClearable
                                                />
                                            </div>
                                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                <label className="">Brand* </label>
                                                <Select
                                                    name="brand"
                                                    value={brandsArr.filter(obj => rp['brand'] == (obj.value))}
                                                    onChange={(e) => rpFormChange(e, rpi, 'brand')}
                                                    options={brandsArr}
                                                    isClearable
                                                />
                                            </div>
                                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                                <label className="">Countries* </label>
                                                <Select
                                                    name="iso2"
                                                    value={countriesArr.filter(obj => rp['iso2'] == (obj.iso_code_2))}
                                                    onChange={(e) => rpFormChange(e, rpi, 'iso2')}
                                                    options={countriesArr}
                                                    getOptionLabel={option => option.name}
                                                    getOptionValue={option => option.iso_code_2}
                                                    isClearable
                                                />
                                            </div>
                                            <div className="col-md-3 col-sm-6">
                                                <div className="brand_items text-center pt-md-3">
                                                    {/* <button className="cancial_btn_brand p-0 mr-4"><i
                                                    className="fa fa-file"></i></button> */}
                                                    {!isEdit ?
                                                        <button className="cancial_btn_brand p-0" onClick={(e) => removeRProw(rpi)}><i
                                                            className="fa fa-times-circle"></i></button> : null}
                                                </div>
                                            </div>
                                        </div>)
                                    })
                                }
                                {!isEdit ?
                                    <>
                                        <div className="form-group mb-md-4">
                                            <button className="upload_btn_p " onClick={addMoreRegionalPresence}><i className="fa fa-plus mr-1"></i> Add regional presence information</button>
                                        </div>
                                        <button type="button" onClick={submitRegionalPresence} className="btn btn-primary pr-4 pl-4">SUBMIT</button>
                                    </> : null
                                }
                            </div>
                        </> : null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default VenderProfile;