import Footer from "../common/Footer"
import Header from "../common/Header";
import Loader from "../common/Loader";
import HeaderSearch from "../common/HeaderSearch";
import { useState } from "react";
import { useEffect } from "react";
import { UserServices } from "../../services";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Banner from "../common/Banner";
import { Image, Shimmer } from 'react-shimmer'
function ServicesComponent() {
    const [slider, setSlider] = useState([]);
    const [categoriesArr, setCategoriesArr] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pramotionBanner, setPramotionBanner] = useState({});

    useEffect(() => {
        getSilder();
        getAllCategories();
        getAllPramotionBanner();
    }, []);
    const getAllPramotionBanner = async () => {
        UserServices.pramotionalBanner({ displayAt: 'service' }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setPramotionBanner(data);
            }
            else {
                toast.error(message);
            }
        }).catch((error) => {
            toast.error(error);
        })
    }
    const getSilder = async () => {
        UserServices.bannerList('service').then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setSlider(data.banners);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsLoading(false);
        })
    }
    const getAllCategories = () => {
        UserServices.categoriesDropDownList('service').then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setCategoriesArr(data.list);
            }
        })
    }
    return (
        <>
            <Header />
            <Loader isLoading={isLoading} />
            <div id="carouselExampleCaptions" className="carousel banenr-section slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        {
                            !isLoading && slider.length ? <img src={slider[0]['bannerImage']} className="w-100" alt="Banner" />
                                : <img src={require("../../webroot/images/services-banner.png").default} className="w-100" alt="Banner" />
                        }
                    </div>
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <h1><small>Find the Popular professional </small>Services for your business</h1>
                                    {/* <HeaderSearch title={'Service'} /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="pt-65 pb-50 ">
                <div className="container">
                    <div className="heading-title">
                        <h2>Popular Professional Servicese</h2>
                        <p>A Whole word of freelance  talent at your finger tips</p>
                    </div>
                    <div className="row pt-2 pt-md-4">
                        {categoriesArr && categoriesArr.map((cate, indx) => {
                            return (
                                <div key={indx + 'cacac'} className="col-md-3 col-sm-6">
                                    <Link to={"/services-listing?category=" + cate._id} className="text-center popular_servicese">
                                        <div className="img-h-001">
                                            <Image className="mx-auto rounded-11" src={cate.icon}
                                                fallback={<Shimmer width={'100%'} height={80} />}
                                            />
                                        </div>
                                        <h5>{cate.label}</h5>
                                        {/* <p>Build your brand</p> */}
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            {pramotionBanner && pramotionBanner.first && pramotionBanner.first.image ?
                <section>
                    <Banner img={pramotionBanner.first.image} />
                </section> : null
            }
            <section className="pt-65 pb-50">
                <div className="container">
                    <div className="heading-title">
                        <h2>Explore the Experts </h2>
                        <p>A whole world of freelance talent at your fingertips </p>
                    </div>
                    <div className="row align-items-end pt-2 pt-md-4">

                        <div className="col-lg-6">

                            <div className="pt-3 row">
                                <div className="col-md-6">
                                    <div className="solution-card">
                                        <div className="sluton-header">
                                            <span><img src={require("../../webroot/images/price-tag.png").default} alt="price-tag" /></span>
                                            <h4>The best for every budget.</h4>
                                        </div>
                                        <p>Find high-quality services at every price point.<br />
                                            No hourly rates, just project-based pricing.</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="solution-card">
                                        <div className="sluton-header">
                                            <span><img src={require("../../webroot/images/clock.png").default} alt="clock" /></span>
                                            <h4>Quality work done quickly</h4>
                                        </div>
                                        <p>Find the right experts to begin working on your project within minutes.</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="solution-card">
                                        <div className="sluton-header">
                                            <span><img src={require("../../webroot/images/safe-pay.png").default} alt="Pay safely" /></span>
                                            <h4>Protected payments, all time </h4>
                                        </div>
                                        <p>Always know what you'll pay upfront. Your payment isn't released until you approve the work.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="solution-card">
                                        <div className="sluton-header">
                                            <span><img src={require("../../webroot/images/clock.png").default} alt="clock" /></span>
                                            <h4>24/7 support </h4>
                                        </div>
                                        <p>Questions? Our round-the-clock support team is available to help anytime, anywhere.
                                        </p>
                                    </div>
                                </div>



                            </div>
                        </div>

                        <div className="col-lg-6">
                            <img src={require("../../webroot/images/group-1192.png").default} alt="group-1192" />
                        </div>

                    </div>
                </div>
            </section>
            {pramotionBanner && pramotionBanner.second && pramotionBanner.second.image ?
                <section>
                    <Banner img={pramotionBanner.second.image} />
                </section> : null
            }
            <section className="pt-0 pb-50 bg1 mt-3 mt-md-5 a-business-section">
                <div className="container">

                    <div className="row align-items-end pt-2 pt-md-4">
                        <div className="col-md-6">
                            <img src={require("../../webroot/images/business-solution.png").default} alt="business solution" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="mb-md-2 ">A business solution<br />designed for teams </h2>
                            <p className="fs18 mb-md-4">Upgrade to a curated experience packed with tools and benefits,<br />dedicated to businesses </p>
                            <ul className="p-0">
                                <li>Connect to experts with proven business experience </li>
                                <li>Get matched with the perfect talent by a customer success manager
                                </li>
                                <li>Manage teamwork and boost productivity with one powerful workspace
                                </li>
                            </ul>
                            <a href="" className="btn wite_line_btn mt-0 mt-md-4 mb-3">Explore ICT KART Business </a>
                        </div>



                    </div>
                </div>
            </section>

            <section className="p80 bg-blue-light">
                <div className="container">
                    <h2 className="mb-2 text-center">Get inspired with projects made by our experts </h2>
                    <div className=" pt-lg-0 shop-heading pb-2">

                        <a href="#" className="btn-link">View All</a>
                    </div>
                    <div className="owl-carousel items-carousel items6 ">
                        <div className="item">
                            <div className="shop-item item-style-two ">
                                <img src={require("../../webroot/images/service3.jpg").default} alt="Service" className="mx-auto rounded-11" />
                                <div className="item-body">
                                    <div className="item-badge">
                                        <div className="badge-left">
                                            <span className="badge-img"><img src={require("../../webroot/images/badge1.png").default} alt="Denis Thomson" /></span>
                                            <span>Denis Thomson</span>
                                        </div>
                                        <span className="sheld"><img src={require("../../webroot/images/checked.png").default} alt="Denis Thomson" /></span>
                                    </div>
                                    <div className="shop-footer">
                                        <h4><a href="#0">I will upload product in to  your shopify store...</a></h4>
                                        <span className="rating-box"><span><img src={require("../../webroot/images/star2.png").default} alt="Star" /></span> 4.9</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="p80 were_world">
                <div className="container">
                    <h3 className="mb-3 text-center">We’re the world's work marketplace </h3>
                    <ul className="nav text-center">
                        <li className="nav-item">
                            <a className="nav-link active" data-toggle="tab" href="#Topskills">Top skills</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#Trendingskills">Trending skills</a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div id="Topskills" className=" tab-pane active">
                            <ul className="row p-0 pt-4">
                                <li><a href="#0">Data Entry Specialists</a></li>
                                <li><a href="#0">  Copywriter </a></li>
                                <li><a href="#0">JavaScript Developer </a></li>
                                <li><a href="#0">Software Developer </a></li>
                                <li><a href="#0">Video Editors</a></li>
                                <li><a href="#0"> Database Administrator</a></li>
                                <li><a href="#0"> Logo Designer </a></li>
                                <li><a href="#0">Software Engineer</a></li>
                                <li><a href="#0"> Data Analyst</a></li>
                                <li><a href="#0"> Data Scientist</a></li>
                                <li><a href="#0"> Mobile App Developer</a></li>
                                <li><a href="#0"> Technical Writer</a></li>
                                <li><a href="#0"> Shopify Developer</a></li>
                                <li><a href="#0"> Front-End Developer</a></li>
                                <li><a href="#0"> PHP Developer </a></li>
                                <li><a href="#0">UI Designer</a></li>
                                <li><a href="#0"> Ruby on Rails Developer</a></li>
                                <li><a href="#0"> Game Developer </a></li>
                                <li><a href="#0">Python Developer </a></li>
                                <li><a href="#0">UX Designer </a></li>
                                <li><a href="#0">Android Developer </a></li>
                                <li><a href="#0">Graphic Designer </a></li>
                                <li><a href="#0">Resume Writer </a></li>
                                <li><a href="#0">Virtual Assistant </a></li>
                                <li><a href="#0">Bookkeeper </a></li>
                                <li><a href="#0">iOS Developer </a></li>
                                <li><a href="#0">SEO Expert </a></li>
                                <li><a href="#0">Web Designer </a></li>
                                <li><a href="#0">Content Writer </a></li>
                                <li><a href="#0">Java Developer </a></li>
                                <li><a href="#0">Social Media Manager </a></li>
                            </ul>

                        </div>

                        <div id="Trendingskills" className=" tab-pane fade">


                            <ul className="row p-0 pt-4">
                                <li><a href="#0">Data Entry Specialists</a></li>
                                <li><a href="#0">  Copywriter </a></li>
                                <li><a href="#0">JavaScript Developer </a></li>
                                <li><a href="#0">Software Developer </a></li>
                                <li><a href="#0">Video Editors</a></li>
                                <li><a href="#0"> Database Administrator</a></li>
                                <li><a href="#0"> Logo Designer </a></li>
                                <li><a href="#0">Software Engineer</a></li>
                                <li><a href="#0"> Data Analyst</a></li>
                                <li><a href="#0"> Data Scientist</a></li>
                                <li><a href="#0"> Mobile App Developer</a></li>
                                <li><a href="#0"> Technical Writer</a></li>
                                <li><a href="#0"> Shopify Developer</a></li>
                                <li><a href="#0"> Front-End Developer</a></li>
                                <li><a href="#0"> PHP Developer </a></li>
                                <li><a href="#0">UI Designer</a></li>
                                <li><a href="#0"> Ruby on Rails Developer</a></li>
                                <li><a href="#0"> Game Developer </a></li>
                                <li><a href="#0">Python Developer </a></li>
                                <li><a href="#0">UX Designer </a></li>
                                <li><a href="#0">Android Developer </a></li>
                                <li><a href="#0">Graphic Designer </a></li>
                                <li><a href="#0">Resume Writer </a></li>
                                <li><a href="#0">Virtual Assistant </a></li>
                                <li><a href="#0">Bookkeeper </a></li>
                                <li><a href="#0">iOS Developer </a></li>
                                <li><a href="#0">SEO Expert </a></li>
                                <li><a href="#0">Web Designer </a></li>
                                <li><a href="#0">Content Writer </a></li>
                                <li><a href="#0">Java Developer </a></li>
                                <li><a href="#0">Social Media Manager </a></li>
                            </ul>

                        </div>

                    </div>



                </div>
            </section>


            <section className="pt-60 growing_box">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-md-6 mb-2 mb-md-5">
                            <h4>Find the talent needed</h4>
                            <h3>to et your business growing.</h3>
                            <a href="" className="btn wite_line_btn mt-4 mb-md-3 mb-lg-5">Get Started</a>

                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-5">
                            <img src={require("../../webroot/images/growingman.jpg").default} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="newsletter-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h3>Sign up with our Newsletter</h3>
                            <p>Stay updated and get our latest news right into your inbox. No spam.</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="newsletter-box">
                                <input type="text" className="form-control" placeholder="Enter your email address" />
                                <button type="button" className="btn btn-primary">Subscribe</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default ServicesComponent;