import Select from 'react-select';
import { UserServices } from "../../services"
import { toast } from "react-toastify";
import React, { useState, useEffect } from 'react';
let formArr = { brand: "", relationship: "", documentFile: "" };
let rpformArr = { brand: "", category: "", country: "", iso2: "" };
function RegionalPresence({ brandsArr, categoriesArr, userId, countriesArr, onClick }) {
    const [regionalPresences, setRegionalPresences] = useState([]);
    /** Regional Presence */
    const addMoreRegionalPresence = (e) => {
        let newBP = [...regionalPresences, ...[rpformArr]];
        setRegionalPresences(newBP);
    }
    const removeRProw = (index) => {
        let newRP = [...regionalPresences];
        newRP.splice(+index, 1);
        setRegionalPresences(newRP);
    }
    const rpFormChange = (e, i, name) => {
        // console.log(e, i, name);
        let newRP = [...regionalPresences];
        newRP = newRP.map((brand, index) => {
            if (i == index) {
                if (name == 'iso2') {
                    brand = { ...brand, ...{ [name]: e.iso_code_2 }, country: e.name }
                }
                else {
                    brand = { ...brand, ...{ [name]: e.value } }
                }
            }
            return brand;
        });
        setRegionalPresences(newRP);
    }
    const submitRegionalPresence = (e) => {
        const formData = new FormData();
        formData.append('vendorId', userId);
        formData.append('rp', JSON.stringify(regionalPresences));
        UserServices.regionalPresenceUpdate(formData).then((responce) => {
            let { status, message } = responce;
            if (status == 200) {
                toast.success(message)
            }
        }).catch((er) => {
            console.log(er);
        })
    }
    return (
        <div className="row">
            <div className="col-lg-12">
                <h3>Regional Presence</h3>
                {
                    regionalPresences.map((rp, rpi) => {
                        return (<div className="row" key={'rpi' + rpi}>
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Category* </label>
                                <Select
                                    name="category"
                                    value={categoriesArr.filter(obj => rp['category'] == (obj.value))}
                                    onChange={(e) => rpFormChange(e, rpi, 'category')}
                                    options={categoriesArr}
                                    isClearable
                                />
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Brand* </label>
                                <Select
                                    name="brand"
                                    value={brandsArr.filter(obj => rp['brand'] == (obj.value))}
                                    onChange={(e) => rpFormChange(e, rpi, 'brand')}
                                    options={brandsArr}
                                    isClearable
                                />
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Countries* </label>
                                <Select
                                    name="iso2"
                                    value={countriesArr.filter(obj => rp['iso2'] == (obj.iso_code_2))}
                                    onChange={(e) => rpFormChange(e, rpi, 'iso2')}
                                    options={countriesArr}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.iso_code_2}
                                    isClearable
                                />
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="brand_items text-center pt-md-3">
                                    <button className="cancial_btn_brand p-0" onClick={(e) => removeRProw(rpi)}><i
                                        className="fa fa-times-circle"></i></button>
                                </div>
                            </div>
                        </div>)
                    })
                }
                <div className="form-group mb-md-4">
                    <button className="upload_btn_p " onClick={addMoreRegionalPresence}><i className="fa fa-plus mr-1"></i> Add regional presence information</button>
                </div>
                <div className="up-prof-footer">
                    <button type="button" onClick={(e) => onClick(e, 'brandProducts')} className="btn btn-primary btn-sm"><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                    <div></div>
                    <button type="button" onClick={submitRegionalPresence} className="btn btn-primary btn-sm">SUBMIT</button>
                </div>
            </div>
        </div>
    )
}
export default RegionalPresence;