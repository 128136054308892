import { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import secureStorage from "../../config/encrypt";
import { API_URL, GOOGLE_CLIENT_ID, FACEBOOK_APP_ID } from "../../config/constant";
import { UserServices } from "../../services";
import { userDetail } from "../../store/actions/allAction"
import Loader from "./Loader";
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { gapi } from 'gapi-script';
function SigninComponent(props) {
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [typePassword, setTypePassword] = useState('password');
    const [isFP, setIsFP] = useState(false);
    const [inputField, setInputField] = useState({
        email: "",
        password: ""
    })
    const [errField, setErrField] = useState({
        emailErr: "",
        passwordErr: ""
    })
    const crossRef = useRef();
    const disptch = useDispatch();
    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: GOOGLE_CLIENT_ID,
                scope: ""
            })
        }
        gapi.load('client:auth2', start);
    }, [])
    const validForm = () => {
        let isFormValid = true;
        setErrField({
            emailErr: "",
            passwordErr: ""
        })
        if (inputField.email == "") {
            isFormValid = false;
            setErrField((prevState) => ({
                ...prevState,
                emailErr: 'Please enter email'
            }))
        }
        if (inputField.password == "") {
            isFormValid = false;
            setErrField((prevState) => ({
                ...prevState,
                passwordErr: 'Please enter password'
            }))
        }
        return isFormValid;
    }
    const inputHandler = (e) => {
        if (e.target.name == "email") {
            setErrField((prevState) => ({
                ...prevState,
                emailErr: ''
            }))
        }
        if (e.target.name == 'password') {
            setErrField((prevState) => ({
                ...prevState,
                passwordErr: ''
            }))
        }
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    }
    const signinForm = async () => {
        if (validForm()) {
            setIsLoading(true);
            UserServices.loginUser(inputField).then((responce) => {
                let { data, message, statusCode } = responce;
                if (statusCode == 200) {
                    closeModalBox();
                    if (data && !data.isVerifyAccount) {
                        secureStorage.setItem("EMAIL", data.user.email)
                        if (history) history.push('/user/thank-you');
                    }
                    else {
                        disptch(userDetail(data));
                        props.sendToHeader(true)
                        secureStorage.setItem(API_URL.COMMON_LOGIN_STORE_KEY, data.user);
                        secureStorage.setItem(API_URL.COMMON_TOKEN_STORE_KEY, data.token);
                        secureStorage.setItem(API_URL.COMMON_CART_STORE_KEY, data.user.carts);
                        secureStorage.setItem(API_URL.COMMON_ROLE_KEY, data.role);
                        toast.success(message);
                        if (history) history.push('/');
                    }
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            })
        }
    }
    const closeModalBox = () => {
        crossRef.current.click();
        // window.location.reload();
    }
    const forgotPasswordForm = () => {
        setIsLoading(true);
        UserServices.forgotPassword(inputField).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                toast.success(message);
                closeModalBox();
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    const forgotPassword = (status) => {
        setIsFP(status)
    }
    const onFailureGoogle = (gmailFresponce) => {
        console.log(gmailFresponce);
    }
    const responseGoogle = (gmailResponce) => {
        UserServices.loginUser({ ...gmailResponce, login: 'gmail' }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                closeModalBox();
                if (data && !data.isVerifyAccount) {
                    secureStorage.setItem("EMAIL", data.user.email)
                    if (history) history.push('/user/thank-you');
                }
                else {
                    disptch(userDetail(data));
                    props.sendToHeader(true)
                    secureStorage.setItem(API_URL.COMMON_LOGIN_STORE_KEY, data.user);
                    secureStorage.setItem(API_URL.COMMON_TOKEN_STORE_KEY, data.token);
                    secureStorage.setItem(API_URL.COMMON_CART_STORE_KEY, data.user.carts);
                    secureStorage.setItem(API_URL.COMMON_ROLE_KEY, data.role);
                    toast.success(message);
                    if (history) history.push('/');
                }
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    const handleSocialLogin = (fbResponce) => {
        UserServices.loginUser({ ...fbResponce, login: 'fb' }).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                closeModalBox();
                if (data && !data.isVerifyAccount) {
                    secureStorage.setItem("EMAIL", data.user.email)
                    if (history) history.push('/user/thank-you');
                }
                else {
                    disptch(userDetail(data));
                    props.sendToHeader(true)
                    secureStorage.setItem(API_URL.COMMON_LOGIN_STORE_KEY, data.user);
                    secureStorage.setItem(API_URL.COMMON_TOKEN_STORE_KEY, data.token);
                    secureStorage.setItem(API_URL.COMMON_CART_STORE_KEY, data.user.carts);
                    secureStorage.setItem(API_URL.COMMON_ROLE_KEY, data.role);
                    toast.success(message);
                    if (history) history.push('/');
                }
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    const viewPassword = ($event) => {
        setTypePassword(typePassword == 'password' ? 'text' : 'password')
    }
    return (
        <>
            <div className={`modal fade ${props.show ? 'show' : ''}`} id="SignIn" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body p-md-4">
                            <button type="button" onClick={closeModalBox} className="close" ref={crossRef} data-dismiss="modal">×</button>
                            <h3 className="tc mt-md-3 pb-0">Welcome back !</h3>
                            <p className="tc fs18 op08">Please sign in to access</p>
                            <div className="login_social tc">
                                <FacebookLogin
                                    appId={FACEBOOK_APP_ID}
                                    callback={handleSocialLogin}
                                    cssClass="social-icon-login"
                                    // textButton=""
                                    textButton={<span><img src={require("../../webroot/images/facebook_l.png").default} /></span>}
                                />
                                <GoogleLogin
                                    clientId={GOOGLE_CLIENT_ID}
                                    onSuccess={responseGoogle}
                                    onFailure={onFailureGoogle}
                                    cookiePolicy={'single_host_origin'}
                                    className="rounded-circle gmail"
                                    icon={false}
                                >
                                    <span className="social-icon-login"><img src={require("../../webroot/images/google.png").default} /></span>
                                </GoogleLogin>
                            </div>
                            <div className="or tc">
                                <span>OR</span>
                            </div>
                            <form autoComplete="off">
                                <div className="form-group">
                                    <label className="login_lable" htmlFor="emailUSER">Email Address</label>
                                    <div className="input_login">
                                        <input type="email" className="form-control" id="emailUSER"
                                            name="email"
                                            value={inputField.email}
                                            onChange={inputHandler}
                                        />
                                        <span className="login_icon"><img src={require("../../webroot/images/mail.png").default} /></span>
                                    </div>
                                </div>
                                {
                                    isFP == false ?
                                        <div className="form-group">
                                            <label className="login_lable" htmlFor="exampleInputPassword1">Password</label>
                                            <div className="input_login">
                                                <input type={typePassword} className="form-control" id="exampleInputPassword1"
                                                    name="password"
                                                    value={inputField.password}
                                                    onChange={inputHandler}
                                                />
                                                <span className="login_icon"><img src={require("../../webroot/images/unlocked.png").default} /></span>
                                                <span onClick={viewPassword} className="eye_icon"><i className={typePassword == 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'}></i></span>
                                            </div>
                                        </div>
                                        : null
                                }
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                            <label className="form-check-label" htmlFor="exampleCheck1">Remember me</label>
                                        </div>
                                    </div>
                                    <div className="col-6 tr">
                                        {
                                            isFP == false ?
                                                <a onClick={(e) => forgotPassword(true)}>Forgot Password?</a> :
                                                <a onClick={(e) => forgotPassword(false)}>Login</a>
                                        }
                                    </div>
                                </div>
                                {
                                    isFP == false ?
                                        <button onClick={signinForm} type="button" className="btn btn-primary w100">Submit</button> :
                                        <button onClick={forgotPasswordForm} type="button" className="btn btn-primary w100">Submit</button>
                                }
                                <div className="tc pt-3">
                                    Don't have an Account ? <Link to={"/signup"} onClick={closeModalBox}> Sign Up</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Loader isLoading={isLoading} />
        </>
    )
}
export default SigninComponent;