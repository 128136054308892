import { useEffect, useState } from "react";
import Select from 'react-select';
import { toast } from "react-toastify";
import { UserServices } from "../../services";
import Loader from "../common/Loader";
import { Calendra } from "../elements/DatePicker";
import { useParams, useHistory } from 'react-router-dom';
import productSample from "../../webroot/sample-file/productexcel.xls";
import { ESTIMATED_TIME_TYPES } from "../../config/constant";
import { LOGICAL_OPERATORS } from "@babel/types";
import { userPermission } from "../../utils";
let formArr = {
    key: "", label: "", value: ""
};
const prodObj = {
    title: "",
    description: "",
    category: "",
    subcategory: "",
    images: [],
    thumbnail: "",
    basic: {
        originalPrice: "",
        sellingPrice: "",
        currency: "",
        chargesType: "",
        estimate: "",
        estimateType: "hourly"
    }
}
const setProductImagesArr = [
    { img: "", file: "" },
    { img: "", file: "" },
    { img: "", file: "" },
    { img: "", file: "" },
    { img: "", file: "" }
]
function ManageService({ userId, userData, productType }) {
    prodObj['basic']['currency'] = userData && userData.companyInfo ? userData.companyInfo.currency : "";
    const [productForm, setProductForm] = useState(prodObj);
    const [categoriesArr, setCategoriesArr] = useState([]);
    const [subcategoriesArr, setSubcategoriesArr] = useState([]);

    const [productImages, setProductImages] = useState(setProductImagesArr);
    const [isLoading, setIsLoading] = useState(false);
    const [actionType, setActionType] = useState("Add");
    const { productId } = useParams();
    const history = useHistory();
    const [specificationForm, setSpecificationForm] = useState([formArr]);
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [removedImages, setRemovedImages] = useState([]);
    // console.log(productId);
    useEffect(() => {
        getAllCategories();
        if (productId) {
            getProductDetail();
            setActionType("Update")
        }
    }, []);
    const getProductDetail = () => {
        UserServices.editService({ serviceId: productId }).then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setSubcategoriesArr(data.subcategories);
                delete data.subcategories;
                setProductForm(data);
                data && data.images.map((m, i) => {
                    setProductImagesArr[i] = { 'file': '', 'img': m }
                })
                setProductImages(setProductImagesArr);
            }
            else {
                toast.error(message);
            }
        })
    }
    const getAllCategories = () => {
        UserServices.categoriesDropDownList('service').then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setCategoriesArr(data.list);
            }
        })
    }
    const changeHandler = (e, name = "") => {
        name = name ? name : e.target.name;
        switch (name) {
            case 'category':
                setProductForm((prev) => ({
                    ...prev,
                    category: e ? e.value : ""
                }));
                if (e) setSubcategoriesArr(e.subcategories)
                break;
            case 'subcategory':
                setProductForm((prev) => ({
                    ...prev,
                    [name]: e ? e.value : ""
                }))
                break;
            case 'originalPrice':
            case 'sellingPrice':
            case 'estimate':
            case 'estimateType':
                setProductForm((prev) => ({
                    ...prev,
                    basic: {
                        ...prev.basic,
                        [e.target.name]: e.target.value
                    }
                }))
                break;
            case 'chargesType':
                setProductForm((prev) => ({
                    ...prev,
                    basic: {
                        ...prev.basic,
                        'chargesType': e ? e.value : ""
                    }
                }))
                break;
            default:
                setProductForm((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value
                }))
                break;
        }
    }
    const submitProductForm = (e) => {
        setIsLoading(true);
        if (productId) {
            delete productForm.vender;
            productForm.serviceId = productId;
            UserServices.updateService(productForm).then((responce) => {
                let { data, message, statusCode } = responce;
                if (statusCode == 200) {
                    toast.success(message);
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            })
        }
        else {
            productForm.vender = userId;
            UserServices.addService(productForm).then((responce) => {
                let { data, message, statusCode } = responce;
                if (statusCode == 200) {
                    toast.success(message);
                    if (history) history.push('/my/update-service/' + data._id);
                }
                else {
                    toast.error(message);
                }
                setIsLoading(false);
            })
        }
    }
    const removeFile = (i) => {
        productImages[i] = {
            img: "",
            file: ""
        }
        setProductImages([...productImages]);
    }
    const changeFile = (e, i, obj) => {
        if (obj.img != '' && obj.file == '') {
            let imgu = obj.img.split('/').pop();
            // setRemovedImages((prev) => ([...prev, imgu]));
        }
        productImages[i] = {
            img: URL.createObjectURL(e.target.files[0]),
            file: e.target.files[0]
        }
        setProductImages([...productImages]);
    }
    const submitProductImage = (e) => {
        const formData = new FormData();
        productImages.filter(obj => {
            if (obj.file != "") {
                formData.append('images', obj.file);
                return 1;
            }
        })
        formData.append('serviceId', productId);
        UserServices.updateServiceImages(formData).then((responce) => {
            let { message, statusCode } = responce;
            if (statusCode == 200) {
                toast.success(message);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }

    const addMoreRow = (e) => {
        setSpecificationForm([...specificationForm, ...[formArr]]);
    }
    const formArrayChange = (i, name, e) => {
        let vl = {};
        if (name == 'label') {
            vl = e;
        }
        else {
            vl = { value: e.target.value };
        }
        let specificationFormArr = specificationForm.map((obj, index) => {
            if (i == index) {
                obj = { ...obj, ...vl }
            }
            return obj;
        });
        setSpecificationForm([...specificationFormArr]);
    }
    const submitSpecification = () => {
        let speciObj = {
            productId: productId,
            specifications: specificationForm
        }
        UserServices.updateProduct(speciObj).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                toast.success(message);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    }
    const onBlurHandler = (e) => {
        if (productType == 'add') {
            UserServices.editProduct({ modelNumber: e.target.value }).then((responce) => {
                let { data, message, status } = responce;
                if (status == 200 && 'modelNumber' in data) {
                    delete data._id;
                    setProductForm(data);
                    data && data.images.map((m, i) => {
                        setProductImagesArr[i] = { 'file': '', 'img': m }
                    })
                    setProductImages(setProductImagesArr);
                }
                else {
                    // toast.error(message);
                }
            })
        }
    }
    const inputHandler = (e) => {
        if (e.target.files) {
            setSelectedFile(e.target.files[0]);
            setIsFilePicked(true);
        }
    }
    const importExcel = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', selectedFile);
        if (isFilePicked) {
            UserServices.importExcelFile(formData, 'product').then((responce) => {
                let { message, statusCode } = responce.data;
                if (statusCode == 200) {
                    toast.success(message);
                }
                else {
                    toast.error(message);
                }
            })
        }
    }
    return (
        <div className="col-lg-12">
            <Loader isLoading={isLoading} />
            <div className="d_boxs  mb-2 mb-md-4">
                <div className=" pt-lg-0 dashbord-heading pb-2 hadding_border">
                    <h3 className="co8 pb-md-0">{actionType} Service</h3>
                </div>
                <ul className="nav my_profile_tab mb-2 mb-md-5">
                    <li className="nav-item">
                        <a className=" active" data-toggle="tab" href="#product">Service</a>
                    </li>
                    <li className="nav-item">
                        <a className="" data-toggle="tab" href="#productImage">Service Images </a>
                    </li>
                    {/* <li className="nav-item">
                        <a className="" data-toggle="tab" href="#uploadProducts">Upload Product</a>
                    </li> */}
                </ul>
                <div className="tab-content">
                    <div className="tab-pane active " id="product">
                        <h6 className="color_man">COMPLETED ORDERS</h6>
                        <div className="row">
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="required"> Category</label>
                                <Select
                                    onChange={(e) => changeHandler(e, 'category')} name="category"
                                    value={categoriesArr.filter(obj => productForm.category == (obj.value))}
                                    options={categoriesArr}
                                    isClearable
                                />
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="required">Sub Category</label>
                                <Select
                                    onChange={(e) => changeHandler(e, 'subcategory')} name="subcategory"
                                    value={subcategoriesArr.filter(obj => productForm.subcategory == (obj.value))}
                                    options={subcategoriesArr}
                                    isClearable
                                />
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Service Name</label>
                                <input type="text" onChange={changeHandler} name="title" value={productForm.title} className="form-control" />
                            </div>
                        </div>
                        <div className="form-group mb-md-4">
                            <label className="">Condition</label>
                            <textarea className="form-control" onChange={changeHandler} name="conditions" value={productForm.conditions}></textarea>
                        </div>
                        <div className="form-group mb-md-4">
                            <label className="">Descriptions</label>
                            <textarea className="form-control" onChange={changeHandler} name="description" value={productForm.description}></textarea>
                        </div>
                        <h6 className="color_man">Price Informations</h6>
                        <div className="row">
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="required">Charges Type</label>
                                <Select
                                    onChange={(e) => changeHandler(e, 'chargesType')} name="chargesType"
                                    value={ESTIMATED_TIME_TYPES.filter(obj => productForm.basic.chargesType == (obj.value))}
                                    options={ESTIMATED_TIME_TYPES}
                                    isClearable
                                />
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Listed (Original) Price </label>
                                <div className="position_relative_inr">
                                    <input type="text" onChange={changeHandler} name="originalPrice" value={productForm.basic.originalPrice} className="form-control decimal" />
                                    <span className="inr_a">{userData && userData.companyInfo ? userData.companyInfo.currency : ""}</span>
                                </div>
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="">Promotional (Selling) Price </label>
                                <div className="position_relative_inr">
                                    <input type="text" onChange={changeHandler} name="sellingPrice" value={productForm.basic.sellingPrice} className="form-control number" />
                                    <span className="inr_a">{userData && userData.companyInfo ? userData.companyInfo.currency : ""}</span>
                                </div>
                            </div>
                            <div className="form-group col-lg-3 col-md-6 col-sm-6 mb-md-4">
                                <label className="required">Service Estimate Time</label>
                                <div className="form-input-select">
                                    <input type="text" onChange={changeHandler} name="estimate" value={productForm.basic.estimate} className="form-control decimal" />
                                    <select className="form-control" onChange={changeHandler} name="estimateType">
                                        {ESTIMATED_TIME_TYPES && ESTIMATED_TIME_TYPES.map((obj) => {
                                            return (<option selected={obj.value == productForm.basic.estimateType ? 'selected' : null} key={obj.value} value={obj.value}>{obj.label}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {userPermission('service', 'add') || userPermission('service', 'edit') ?
                            <button type="button" onClick={submitProductForm} className="btn btn-primary pr-4 pl-4">SUBMIT</button> : null
                        }
                    </div>
                    <div className="tab-pane" id="productImage">
                        <div className="row row2 col5 mb-md-4">
                            {
                                productImages.map((obj, index) => {
                                    return (
                                        <div key={index} className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="add_pravu_item">
                                                <a className="add_delite" onClick={(e) => removeFile(index)}><i className="fa fa-trash-o"></i>
                                                </a>
                                                <div className="add_pravu_img">
                                                    <img src={obj.img} />
                                                </div>
                                                <label className="rao-file-label">
                                                    <i className="fa fa-upload"></i>&nbsp;&nbsp;
                                                    <span>Choose a file…</span>
                                                    <input type="file" onChange={(e) => changeFile(e, index, obj)}
                                                        name="profile_pic"
                                                        className="rao-file-input inputfile"
                                                        accept="image/*"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {userPermission('service', 'add') || userPermission('service', 'edit') ?
                            <button type="button" onClick={submitProductImage} className="btn btn-primary pr-4 pl-4">Upload Image</button> : null
                        }
                    </div>
                    <div className="tab-pane" id="uploadProducts">
                        <a className="" href={productSample} download><i className="fa fa-download"></i> Download Sample File</a>
                        <div className="row">
                            <div className="form-group col-lg-4 col-md-6 col-sm-6 mb-md-4">
                                <input type="file" onChange={inputHandler} className="form-control" />
                            </div>
                            <div className="form-group col-lg-4 col-md-6 col-sm-6 mb-md-4">
                                {userPermission('service', 'add') || userPermission('service', 'edit') ?
                                    <button onClick={importExcel} className="btn btn-primary pr-4 pl-4">Upload</button> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ManageService;