import Select from 'react-select';
import { UserServices } from "../../services"
import { toast } from "react-toastify";
import React, { useState, useEffect } from 'react';
let formArr = { brand: "", relationship: "", documentFile: "" };
let rpformArr = { brand: "", category: "", country: "", iso2: "" };
function BrandPartner({ brandsArr, userId }) {
    const [brandPartenships, setBrandPartenships] = useState([]);
    const [regionalPresences, setRegionalPresences] = useState([]);
    /** Brand Parti */
    const addMoreBrandPartner = (e) => {
        let newBP = [...brandPartenships, ...[formArr]];
        setBrandPartenships(newBP);
    }
    const removeBProw = (index) => {
        let newBP = [...brandPartenships];
        newBP.splice(+index, 1);
        setBrandPartenships(newBP);
    }
    const bpFormChange = (e, i, name) => {
        let vl = "";
        if (name == 'brand') {
            vl = e.value;
        }
        else if (name == 'documentFile') {
            vl = e.target.files[0];
        }
        else {
            vl = e.target.value;
        }
        let newBP = [...brandPartenships];
        newBP = newBP.map((brand, index) => {
            if (i == index) {
                brand = { ...brand, ...{ [name]: vl } }
            }
            return brand;
        });
        setBrandPartenships(newBP);
    }
    const submitBrandPartner = (i) => {
        const formData = new FormData();
        formData.append('vendorId', userId);
        formData.append('brand', brandPartenships[i]['brand']);
        formData.append('relationship', brandPartenships[i]['relationship']);
        formData.append('documentFile', brandPartenships[i]['documentFile']);
        UserServices.brandPartnershipUpdate(formData).then((responce) => {
            let { status, message } = responce;
            if (status == 200) {
                toast.success(message)
            }
        }).catch((er) => {
            console.log(er);
        })
    }
    return (
        <div className="row">
            <div className="col-lg-12">
                <h3>Brand Partnerships <button onClick={addMoreBrandPartner} className="btn btn-sm btn-primary"><i className="fa fa-plus mr-1"></i> Add More Brands</button></h3>
            </div>
            <div className="col-lg-12">
                {
                    brandPartenships.map((bp, bpi) => {
                        return (
                            <div className="row" key={'bp' + bpi}>
                                <div className="col-md-3 col-sm-6">
                                    <div className="brand_items">
                                        Brand
                                        <span className="brand_value">
                                            <Select
                                                name="brand"
                                                value={brandsArr.filter(obj => bp['brand'] == (obj.value))}
                                                onChange={(e) => bpFormChange(e, bpi, 'brand')}
                                                options={brandsArr}
                                                isClearable
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <div className="brand_items">
                                        Relationship
                                        <span className="brand_value">
                                            <input type="text"
                                                className="bg-r form-control"
                                                value={bp.relationship}
                                                name="relationship"
                                                onChange={(e) => bpFormChange(e, bpi, 'relationship')}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <div className="brand_items text-center">
                                        Upload Brand Document
                                        <span className="brand_value">
                                            <img src={require("../../webroot/images/docoment.jpg").default} />
                                            <input type="file"
                                                className="bg-r form-control"
                                                name="documentFile"
                                                onChange={(e) => bpFormChange(e, bpi, 'documentFile')}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <div className="brand_items text-center">
                                        <button onClick={(e) => removeBProw(bpi)} className="cancial_btn_brand p-0"><i className="fa fa-times-circle"
                                            aria-hidden="true"></i></button>&nbsp;&nbsp;
                                        <button onClick={(e) => submitBrandPartner(bpi)} className="cancial_btn_brand p-0"><i className="fa fa-upload"
                                            aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {/* <button onClick={this.addMoreBrandPartner.bind(this)} className="upload_btn_p "><i className="fa fa-plus mr-1"></i> Add More Brands</button> */}
        </div>
    )
}
export default BrandPartner;