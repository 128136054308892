import { displayDate, displayCHAR } from "../../utils"
import secureStorage from "../../config/encrypt";
import { UserServices } from "../../services";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
function VendorMyOrders({ userData, orderStatus }) {
    const [myOrders, setMyOrders] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    // 'ordered', 'shipped', 'outForDelivery', 'delivered', 'return', 'cancel'
    useEffect(() => {
        getAllMyOrders({ orderStatus, vendor: userData._id });
    }, [orderStatus])
    const getAllMyOrders = (filterObj) => {
        setIsLoading(true);
        UserServices.buyProductList(filterObj).then((responce) => {
            let { data, message, status } = responce;
            if (status == 200) {
                setMyOrders(data.list)
            }
            else {
                toast.error(message);
            }
        })
    }
    return (
        <div className="col-lg-12">
            {/* <Loader isLoading={isLoading} /> */}
            <div className="d_boxs pb-1 mb-2 mb-md-4">
                <div className="row">
                    <div className=" col-md-5 ">
                        <h5 className="box_hadding">My Added Services </h5>
                    </div>
                    <div className=" col-md-7 ">
                        <div className="d_flex search_input mb-3 mb-md-4 mla">
                            {/* <Link to={'/my/manage-product'} className="btn btn-primary btn-small m-auto mr-3">Add New Service</Link> */}
                            <span className="pt-2 w90"> Sort by: </span>
                            <select className=" form-control" type="text">
                                <option>Most Recent</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="table_responsive_scrool">
                    <table className="table_vender text-center">
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>Invoice No</th>
                                <th>Order No</th>
                                <th>Transaction No</th>
                                <th>Buyer</th>
                                <th>Shipping Address</th>
                                <th>Product</th>
                                <th>Quantity</th>
                                <th>Total Amount</th>
                                <th>Order At</th>
                                <th>Type</th>
                                <th>Current Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {myOrders && myOrders.length ? myOrders.map((item, indx) => {
                                return (
                                    <tr key={indx + 'pro'}>
                                        <td>{indx + 1}</td>
                                        <td>{item.productInvoice}</td>
                                        <td>{item.buyproducts ? item.buyproducts.orderId : ""}</td>
                                        <td>{item.buyproducts ? item.buyproducts.transactionId : ""}</td>
                                        <td>
                                            <div className="prof-container">
                                                <img className="prof-img" src={item.thumbnailImage} />
                                                <div className="prof-content">
                                                    <h5 className="title">{item.user ? item.user.firstName + ' ' + item.user.lastName : ''}</h5>
                                                    <a className="sub-title">{item.user ? item.user.mobile : ''}</a>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{item.shippingAddr ? item.shippingAddr.transactionId : ""}</td>
                                        <td>
                                            <div className="prof-container">
                                                <img className="prof-img" src={item.product && item.product.thumbnailImage} />
                                                <div className="prof-content">
                                                    <h5 className="title">{displayCHAR(item.product && item.product.title)}</h5>
                                                    <a className="sub-title">{item.product && item.product.modelNumber}</a>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{item.items}</td>
                                        <td>{item.currency + ' ' + item.sellingPrice}</td>
                                        <td>{displayDate(item.createdAt)}</td>
                                        <td>{item.type}</td>
                                        <td>{item.orderStatus}</td>
                                        <td>
                                            <Link to={'/my/orders-detail?productId=' + item._id + "&type=" + item.type}>View</Link>
                                        </td>
                                    </tr>
                                )
                            }) : <tr>
                                <td colSpan="13">
                                    Not Found
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default VendorMyOrders;