import { Image, Shimmer } from 'react-shimmer';
function Banner({ img }) {
    return (
        <div className="container">
            <Image src={img}
                fallback={<Shimmer width={'100%'} height={60} />}
            />
            {/* <img src={require("../../webroot/images/banner3.png").default} alt="Banner" /> */}
        </div>
    )
}
export default Banner;