import Footer from "../common/Footer"
import Header from "../common/Header";
import NewsletterFormController from "../common/NewsletterFormController";
import { useState, useEffect } from "react";
import { UserServices } from "../../services";
import { toast } from "react-toastify";
import Loader from "../common/Loader";
function SolutionComponent() {
    const [services, setService] = useState([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);
    const [slider, setSlider] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        getSilder();
    }, []);
    const getSilder = async () => {
        UserServices.bannerList('solution').then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setSlider(data.banners);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        }).catch((error) => {
            toast.error(error);
            setIsLoading(false);
        })
    }
    return (
        <>
            <Header />
            <Loader isLoading={isLoading} />
            <div id="carouselExampleCaptions" className="carousel banenr-section slide" data-ride="carousel">
                <div className="carousel-inner">
                    {
                        !isLoading && slider.length ? <img src={slider[0]['bannerImage']} className="w-100" alt="Banner" />
                            : <img src={require("../../webroot/images/solutions-banner.png").default} className="w-100" alt="Banner" />
                    }
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <h1><small>Find great talent. </small>for any job, online.</h1>
                                    <form className="search-box">
                                        <select>
                                            <option>Solutions</option>
                                        </select>
                                        <div className="search-input">
                                            <input type="text" placeholder="Search by keyword" />
                                            <button type="button" className="search-btn"><img src={require("../../webroot/images/search.png").default} alt="Search" /></button>
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <section className="pt-65 pb-50 ">
                <div className="container">
                    <div className="heading-title">
                        <h2>Need something done?</h2>
                        <p>Discover incredible abillity. Fabricate your business. Take your vocation to a higher level.</p>
                    </div>
                    <div className="row align-items-end pt-2 pt-md-4">
                        <div className="col-lg-6">
                            <img src={require("../../webroot/images/need_something.png").default} alt="need_something" />
                        </div>
                        <div className="col-lg-6">
                            <div className="shop-heading pb-4">
                                <h3>Need something done?</h3>
                            </div>
                            <div className="pt-3 row">
                                <div className="col-md-6">
                                    <div className="solution-card">
                                        <div className="sluton-header">
                                            <span><img src={require("../../webroot/images/post_job.png").default} alt="Post a job" /></span>
                                            <h4>Post A Requirment</h4>
                                        </div>
                                        <p>It’s free and easy to post a job. Simply fill in a title, description and budget and competitive bids
                                            come within minutes.</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="solution-card">
                                        <div className="sluton-header">
                                            <span><img src={require("../../webroot/images/providers.png").default} alt="Choose Providres" /></span>
                                            <h4>Choose Solutions</h4>
                                        </div>
                                        <p>No job is too big or too small. We've got Experts for jobs of any size or budget, across 1800+
                                            skills.</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="solution-card">
                                        <div className="sluton-header">
                                            <span><img src={require("../../webroot/images/safe-pay.png").default} alt="Pay safely" /></span>
                                            <h4>Pay safely</h4>
                                        </div>
                                        <p>Only pay for work when it has been completed and you're 100% satisfied with the quality using our
                                            milestone payment system</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="solution-card">
                                        <div className="sluton-header">
                                            <span><img src={require("../../webroot/images/conversation.png").default} alt="We’re here to help" /></span>
                                            <h4>We’re here to help</h4>
                                        </div>
                                        <p>It’s free and easy to post a job. Simply fill in a title, description and budget and competitive bids
                                            come within minutes.</p>
                                    </div>
                                </div>


                                <div className="col-sm-6 mb-3 mb-sm-0">
                                    <a className="find_work" href="#">
                                        Find A Talent
                                    </a>
                                </div>
                                <div className="col-sm-6">
                                    <a className="find_work find_work_bg" href="#">
                                        Find Work
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="">
                <div className="container">
                    <img src={require("../../webroot/images/banner3.png").default} alt="Banner" />
                </div>
            </section>
            <section className="pt-65 pb-50">
                <div className="container">
                    <div className="heading-title">
                        <h2>What's extraordinary with regards to it? </h2>
                        <p>Recruit the best experts for any work, on the web. </p>
                    </div>
                    <div className="row align-items-end pt-2 pt-md-4">

                        <div className="col-lg-6">

                            <div className="pt-3 row">
                                <div className="col-md-6">
                                    <div className="extraordinary-card">
                                        <span><img src={require("../../webroot/images/portfolio.png").default} alt="portfolio" /></span>

                                        <div className="extraordinary_content">
                                            <h5>Browse portfolios </h5>
                                            <p>Find professionals you can trust by browsing their samples of previous work and reading their
                                                profile reviews
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="extraordinary-card">
                                        <span><img src={require("../../webroot/images/select.png").default} alt="select" /></span>
                                        <div className="extraordinary_content">
                                            <h5>Fast bids </h5>
                                            <p>Receive obligation free quotes from our talented is freelancers fast. 80% of projects get bid on
                                                within 60 seconds.
                                            </p>
                                        </div>


                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="extraordinary-card">
                                        <span><img src={require("../../webroot/images/review.png").default} alt="review" /></span>
                                        <div className="extraordinary_content">
                                            <h5>Quality work </h5>
                                            <p> Freelancer.com has by far the largest pool of choose freelancers globally- over 50 million to
                                                choose form.
                                            </p>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="extraordinary-card">
                                        <span><img src={require("../../webroot/images/locations.png").default} alt="locations" /></span>
                                        <div className="extraordinary_content">
                                            <h5>24/7 support </h5>
                                            <p> Track progress Keep up-to-date and on-the-go with our time tracker, and mobile app. Always know
                                                what freelancers are up to.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={require("../../webroot/images/ima.png").default} alt="group-1192" />
                        </div>
                    </div>
                </div>
            </section>
            {/* Banner */}
            <section className="">
                <div className="container">
                    <img src={require("../../webroot/images/banner3.png").default} alt="Banner" />
                </div>
            </section>
            <section className="p80 ">
                <div className="container">
                    <div className="heading-title">
                        <h2>Make it Real with Experts</h2>
                        <p>Get some Inspiration form 1800+ skils </p>
                    </div>
                    <div className="row pt-2 pt-md-4">
                        {
                            services && services.map((btype, idx) => {
                                return (
                                    <div key={idx} className="col-md-4 col-sm-6 col-lg-2 col-6">
                                        <div className="make_it_real mb-3">
                                            <div className="make_img_box">
                                                <img src={require("../../webroot/images/logo-design1.png").default} alt="Service" className="img-fluid" />
                                            </div>
                                            <div className="make-body p-md-3 p-2">
                                                Logo Design.
                                                <span>$30 USD in 1 day</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <NewsletterFormController />
            <Footer />
        </>
    );
}

export default SolutionComponent;