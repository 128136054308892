import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { UserServices } from "../../../services";
import { loginUserId, displayDate, userPermission } from "../../../utils/index";

import ManageRole from "./ManageRole";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

let formObj = {
    roleId: null,
    name: "",
    description: "",
    user: {
        list: false,
        add: false,
        update: false,
        delete: false,
    },
    order: {
        list: false,
        update: false,
        reject: false,
    },
    product: {
        list: false,
        add: false,
        update: false,
        delete: false,
    },
    service: {
        list: false,
        add: false,
        update: false,
        delete: false,
    },
}
const VenderRole = () => {
    const [roles, setRoles] = useState([]);
    const [total, setTotal] = useState(0);
    const [formData, setFormData] = useState(formObj);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        getAllRoles();
    }, [])
    const getAllRoles = (data) => {
        setIsLoading(true);
        let dataObj = {
            vendorId: loginUserId()
        }
        UserServices.listUserRole(dataObj).then((responce) => {
            let { data, message, statusCode } = responce;
            if (statusCode == 200) {
                setRoles(data.list);
                setTotal(data.count);
            }
            else {
                toast.error(message);
            }
            setIsLoading(false);
        })
    };
    let inputElement = useRef();
    const onSuccess = (status) => {
        getAllRoles();
        setFormData(formObj)
    }
    const changeStatus = (e, i, role) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: "You want to remove this role!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    roleId: role._id,
                    status: role.status ? false : true
                }
                UserServices.updateUserRole(obj).then((responce) => {
                    let { message, data, status } = responce;
                    if (status == 200) {
                        getAllRoles();
                        toast.success(message);
                    }
                    else {
                        toast.error(message);
                    }
                    setIsLoading(false);
                })
            }
        })
    }
    const editRole = (role) => {
        let per = JSON.parse(role.permissions);
        delete role.permissions;
        setFormData({ roleId: role._id, ...role, ...per })
    }
    return (
        <div className="col-lg-9">
            <div className="d_boxs  mb-2 mb-md-4">
                <div className=" pt-lg-0 dashbord-heading pb-2 hadding_border">
                    <h3 className="co8 pb-md-0">Roles ({total})</h3>
                    {userPermission('role', 'add') || userPermission('role', 'edit') ? <>
                        <div className="d_flex search_input mb-2 mb-md-2 mla">
                            <a ref={input => inputElement = input} data-toggle="modal" data-target="#roleMODAL" className="btn btn-primary btn-small"><i className="fa fa-plus-circle mr-2"></i> Add Roles</a>
                        </div>
                        <ManageRole formObj={formData} onSuccess={onSuccess} show={true} />
                    </> : null
                    }
                </div>
                {!isLoading && roles.length ?
                    roles.map((role, i) => {
                        return (
                            <div className="role_items_man" key={i + '_ii_0'}>
                                <div className="row">
                                    <div className="col-lg-1">
                                        <div className="role_items">
                                            S.No
                                            <span className="brand_value">{i + 1}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="role_items">
                                            Role
                                            <span className="brand_value">{role.name}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="row">
                                            <div className="col-md-4 col-sm-6">
                                                <div className="role_items">
                                                    Date Created
                                                    <span className="brand_value">{displayDate(role.createdAt, 'dd mmm yyyy HH:MM:ss')}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 ">
                                                <div className="role_items">
                                                    Last Modified
                                                    <span className="brand_value">{displayDate(role.updatedAt, 'dd mmm yyyy HH:MM:ss')}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 ">
                                                <div className="role_items text-center">Action
                                                    <span className="brand_value">
                                                        <button onClick={(e) => editRole(role)} ref={input => inputElement = input} data-toggle="modal" data-target="#roleMODAL" className=" p-0 mr-2"><i className="fa fa-pencil-square-o"></i></button>
                                                        <button onClick={(e) => changeStatus(e, i, role)} className=" p-0"><i className="fa fa-trash-o"></i></button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    : null
                }
            </div>
        </div>
    )
}
export default VenderRole;